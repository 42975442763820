import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import ChurchIcon from '@mui/icons-material/Church';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';
import EmailIcon from '@mui/icons-material/Email';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FemaleRoundedIcon from '@mui/icons-material/FemaleRounded';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import KeyIcon from '@mui/icons-material/Key';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ManIcon from '@mui/icons-material/Man';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
import OpacityRoundedIcon from '@mui/icons-material/OpacityRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PinIcon from '@mui/icons-material/Pin';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SickIcon from '@mui/icons-material/Sick';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetPacienteByNroDoc } from '../hooks/usePacientes';
import { useGetTriajeByNroDoc } from '../hooks/useTriaje';
import { DatosPaciente } from './DatosPaciente';
import { DatosTriaje } from './DatosTriaje';

let personalDataDefault = {
  idPaciente: {
    nombre: 'Id Paciente',
    valor: '--',
    unidad: '',
    Icon: KeyIcon,
    color: '#'
  },
  nombres: {
    nombre: 'Nombres',
    valor: '--',
    unidad: '',
    Icon: PersonRoundedIcon,
    color: '#9871ff'
  },
  apellidos: {
    nombre: 'Apellidos',
    valor: '--',
    unidad: '',
    Icon: PersonRoundedIcon,
    color: '#adadad'
  },
  dni: {
    nombre: 'Nro Doc',
    valor: '--',
    unidad: '',
    Icon: FeaturedVideoIcon,
    color: '#009688'
  },
  tipoPaciente: {
    nombre: 'Tipo de paciente',
    valor: '--',
    unidad: '',
    Icon: AccountBoxRoundedIcon,
    color: '#4a9bc4'
  },
  fechaNacimiento: {
    nombre: 'Fecha de nacimiento',
    valor: '--',
    unidad: '',
    Icon: CakeRoundedIcon,
    color: '#dd9787'
  },
  direccion: {
    nombre: 'Dirección',
    valor: '--',
    unidad: '',
    Icon: HomeRoundedIcon,
    color: '#9fae81'
  },
  celular: {
    nombre: 'Celular',
    valor: '--',
    unidad: '',
    Icon: LocalPhoneRoundedIcon,
    color: '#d89d48'
  },
  alergias: {
    nombre: 'Alergias',
    valor: '--',
    unidad: '',
    Icon: SickIcon,
    color: '#ff4081'
  },
  vacunas: {
    nombre: 'Vacunas',
    valor: '--',
    unidad: '',
    Icon: VaccinesIcon,
    color: '#9500ae'
  },
  cirugias: {
    nombre: 'Cirugías',
    valor: '--',
    unidad: '',
    Icon: AirlineSeatFlatIcon,
    color: '#'
  },
  personaResponsable: {
    nombre: 'Persona Responsable',
    valor: '--',
    unidad: '',
    Icon: EscalatorWarningIcon,
    color: '#d89d48'
  },
  celularResponsable: {
    nombre: 'CelularResponsable',
    valor: '--',
    unidad: '',
    Icon: LocalPhoneRoundedIcon,
    color: '#3d5afe'
  },
  sexo: {
    nombre: 'Sexo',
    valor: '--',
    unidad: '',
    Icon: FemaleRoundedIcon, // || MaleRoundedIcon,
    color: '#db5461'
  },
  edad: {
    nombre: 'Edad',
    valor: '--',
    unidad: 'años',
    Icon: EventRoundedIcon,
    color: '#64a0c2'
  },
  procedencia: {
    nombre: 'Procedencia',
    valor: '--',
    unidad: '',
    Icon: PublicRoundedIcon,
    color: '#c68aac'
  },
  carreraProfesional: {
    nombre: 'Carrera profesional',
    valor: '--',
    unidad: '',
    Icon: SchoolRoundedIcon,
    color: '#424242'
  },
  numeroHermanos: {
    nombre: 'Numero Hermanos',
    valor: '--',
    unidad: '',
    Icon: GroupAddIcon,
    color: '#00695c'
  },
  accidentesGraves: {
    nombre: 'Accidentes Graves',
    valor: '--',
    unidad: '',
    Icon: DangerousIcon,
    color: '#d81b60'
  },
  codigoEstudiante: {
    nombre: 'Codigo Estudiante',
    valor: '--',
    unidad: '',
    Icon: PinIcon,
    color: '#00acc1'
  },
  semestre: {
    nombre: 'Semestre',
    valor: '--',
    unidad: '',
    Icon: SchoolRoundedIcon,
    color: '#37474f'
  },
  email: {
    nombre: 'Email',
    valor: '--',
    unidad: '',
    Icon: EmailIcon,
    color: '#ff8a65'
  },
  tutor: {
    nombre: 'Tutor',
    valor: '--',
    unidad: '',
    Icon: ManIcon,
    color: '#ffd600'
  },
  viveResidenciaUniv: {
    nombre: 'Vive Residencia Univ',
    valor: '--',
    unidad: '',
    Icon: MapsHomeWorkIcon,
    color: '#795548'
  },
  idioma: {
    nombre: 'Idioma',
    valor: '--',
    unidad: '',
    Icon: GTranslateIcon,
    color: '#7a7a7a'
  },
  religion: {
    nombre: 'Religión',
    valor: '--',
    unidad: '',
    Icon: ChurchIcon,
    color: '#1de9b6'
  },
  LugarOcupaHermano: {
    nombre: 'Lugar Ocupa Hermano',
    valor: '--',
    unidad: '',
    Icon: FamilyRestroomIcon,
    color: '#00695c'
  },
  tipoSangre: {
    nombre: 'Tipo Sangre',
    valor: '--',
    unidad: '',
    Icon: BloodtypeIcon,
    color: '#00695c'
  },

}
let triajeDataDefault = {
  fechaHora: {
    nombre: 'Fecha de triaje',
    valor: '--',
    unidad: '',
    Icon: EventRoundedIcon,
    color: '#4a9bc4'
  },
  presionArterial: {
    nombre: 'Presión arterial',
    valor: '--',
    unidad: 'mmHg',
    Icon: FavoriteRoundedIcon,
    color: '#df4976'
  },
  temperatura: {
    nombre: 'Temperatura',
    valor: '--',
    unidad: '°C',
    Icon: DeviceThermostatRoundedIcon,
    color: '#c68aac'
  },
  saturacion: {
    nombre: 'Saturación O2',
    valor: '--',
    unidad: '',
    Icon: OpacityRoundedIcon,
    color: '#b73a61'
  },
  frecuenciaRespiratoria: {
    nombre: 'Frecuencia respiratoria',
    valor: ' --',
    unidad: '',
    Icon: MonitorHeartRoundedIcon,
    color: '#adadad'
  },
  frecuenciaCardiaca: {
    nombre: 'Frecuencia Cardiaca',
    valor: '--',
    unidad: '',
    Icon: MonitorHeartRoundedIcon,
    color: '#db5461'
  },
  peso: {
    nombre: 'Peso',
    valor: '--',
    unidad: 'kg',
    Icon: ScaleRoundedIcon,
    color: '#64a0c2'
  },
  talla: {
    nombre: 'Talla',
    valor: '--',
    unidad: 'cm',
    Icon: HeightRoundedIcon,
    color: '#9fae81'
  },
  imc: {
    nombre: 'IMC',
    valor: '--',
    unidad: '',
    Icon: AccessibilityNewRoundedIcon,
    color: '#d89d48'
  },
}
export const DatosGenerales = ({ DNI, CamposPaciente, triaje }) => {
  const [datosPaciente] = useState({})
  const { data: dataPaciente } = useGetPacienteByNroDoc(DNI)
  const [triajeData] = useState({})
  const { data: dataTriaje } = useGetTriajeByNroDoc(DNI) || []

  const [error, setError] = useState({
    isError: false,
    message: ''
  })

  if (dataPaciente) {
    if (dataTriaje !== undefined && dataTriaje.length !== 0) {
      Object.keys(triajeDataDefault).map((campoTriaje) => {
        triajeDataDefault[`${campoTriaje}`].valor = dataTriaje[0][`${campoTriaje}`]
        triajeData[`${campoTriaje}`] = triajeDataDefault[`${campoTriaje}`]
      })
    }

    CamposPaciente.map((CampoPaciente) => {
      const now = new Date()
      if (CampoPaciente === 'apellidos') {
        personalDataDefault[`apellidos`].valor = `${dataPaciente['persona'].apPaterno} ${dataPaciente['persona'].apMaterno}`
      }
      else {
        if (CampoPaciente === 'nombres') {
          personalDataDefault[`nombres`].valor = `${dataPaciente['persona'].nombres}`
        }
        else {
          if (CampoPaciente === 'fechaNacimiento') {
            personalDataDefault.fechaNacimiento.valor = dataPaciente['fechaNacimiento']?.split('T')[0]
          }
          else {
            if (CampoPaciente === 'edad') {
              personalDataDefault.edad.valor = now.getFullYear() - dataPaciente['fechaNacimiento']?.split('-')[0]
            }
            else {
              if (personalDataDefault[`${CampoPaciente}`] && dataPaciente[`${CampoPaciente}`]) {
                personalDataDefault[`${CampoPaciente}`].valor = dataPaciente[`${CampoPaciente}`]
              }
            }
          }
        }
      }
      datosPaciente[`${CampoPaciente}`] = personalDataDefault[`${CampoPaciente}`]
    })
  }

  useEffect(() => {
    if (dataTriaje)
      if (Object.keys(dataTriaje).length === 0) {
        setError({
          isError: true,
          message: 'El paciente no tiene datos de triaje'
        })
      }
  }, [dataTriaje])

  return (
    <Grid container>
      {
        datosPaciente && <DatosPaciente DNI={DNI} datosPaciente={datosPaciente} error={error} />
      }
      {
        (triaje === 1) && <DatosTriaje DNI={DNI} error={error} triajeData={triajeData} />
      }
    </Grid>

  )
}

import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

export default function Loading({ isLoading, error, title = 'Cargando...', children }) {
    if (error) {
        return <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Typography variant="h5" color="error">
                Ocurrio un error
                <ErrorOutlineRoundedIcon />
            </Typography>
        </Box>
    }

    if (!isLoading) {
        return children
    }

    return (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Typography>{title}</Typography>
            <CircularProgress />
        </Box>
    )
}
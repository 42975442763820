import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, Update, UpdateEstado, GetById } from '../services/accesos'

const key = 'Accesos'

export const useGetAllAccesos = (enabled = true) =>
    useQuery(key, GetAll, {
        enabled
    })

export const useCreateAcceso = () => {
    const queryClient = useQueryClient()

    return useMutation(Create, {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useGetByIdAcceso = (idAcceso, enabled = true) =>
    useQuery([key, idAcceso], GetById, {
        enabled
    })

export const useUpdateAcceso = idAcceso => {
    const queryClient = useQueryClient()

    return useMutation(body => Update(idAcceso, body), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useUpdateEstadoAcceso = idAcceso => {
    const queryClient = useQueryClient()

    return useMutation(body => UpdateEstado(idAcceso, body), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useDeleteAcceso = idAcceso => {
    const queryClient = useQueryClient()

    return useMutation(() => DeleteElement(idAcceso), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}
import { Autocomplete, Button, ButtonGroup, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, useAutocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGetAllEspecialidad } from '../hooks/useEspecialidades';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useFilterPacientes } from '../hooks/usePacientes';
const dayjs = require('dayjs')
dayjs().format('DD/MM/YYYY')

const SearchInput = ({ data, value, setValue, keyWord = 'paciente', labelWord = 'paciente' }) => {
  return (
    <Autocomplete
      value={value}
      isOptionEqualToValue={(option, value) => option[keyWord] === value}
      disableClearable={false}
      options={data.map((option) => option[keyWord])}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label={`Buscar ${labelWord}`}
        />
      )}
    />
  )
}
const FilterAutoCompleteCampo = ({ data, value, setValue, label, campo }) => {
  return (
    <Autocomplete
      value={value}
      isOptionEqualToValue={(option, value) => option[campo] === value}
      disableClearable={false}
      options={data.map((option) => option[campo])}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label={label}
        />
      )}
    />
  )
}

const AutoCompletePaciente = ({ value, setValue }) => {
  const { data } = useFilterPacientes(value)

  return (
    <Autocomplete
      value={value ?? {}}
      isOptionEqualToValue={(option, value) => option["nombre"] === value}
      disableClearable={false}
      options={data?.map((option) => option["nombre"]) ?? []}
      inputValue={value ?? {}}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Buscar paciente (DNI)"
          type="number"
          helperText="Busqueda requerida: 5 caracteres como mínimo."
        />
      )}
    />
  )
}


const FilterEstado = ({ label, data, filterKey, setValue, value }) => {
  const [selectedValue, setSelectedValue] = useState('')
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='select-especialidad'>{label}</InputLabel>
      <Select
        inputlabelpros={{ shrink: true }}
        labelId='select-especialidad'
        size="small"
        value={value}
        label={label}
        onChange={e => setValue(e.target.value)}
        fullWidth
        sx={{
          "& .MuiSelect-iconOutlined": { display: selectedValue !== '' ? 'none' : '' },
          "&.Mui-focused .MuiIconButton-root": { color: "primary.main" }
        }}
        { // Agregar boton para limpiar seleccion
        ...(selectedValue !== '' && {
          endAdornment: (
            <IconButton
              size='small'
              sx={{ mr: -1.3 }}
            // onClick={onClearText}
            >
              {/* <ClearRoundedIcon /> */}
            </IconButton>
          )
        })
        }
      >
        {
          data?.map((item, index) => (
            <MenuItem key={index} value={item[filterKey]}> {item[[filterKey]]} </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
const Filter = ({ label, data, filterKey, setValue, value }) => {
  const [selectedValue, setSelectedValue] = useState('')
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='select-especialidad'>{label}</InputLabel>
      <Select
        inputlabelpros={{ shrink: true }}
        labelId='select-especialidad'
        size="small"
        value={value}
        label={label}
        onChange={e => setValue(e.target.value)}
        fullWidth
        sx={{
          "& .MuiSelect-iconOutlined": { display: selectedValue !== '' ? 'none' : '' },
          "&.Mui-focused .MuiIconButton-root": { color: "primary.main" }
        }}
        { // Agregar boton para limpiar seleccion
        ...(selectedValue !== '' && {
          endAdornment: (
            <IconButton
              size='small'
              sx={{ mr: -1.3 }}
            // onClick={onClearText}
            >
              {/* <ClearRoundedIcon /> */}
            </IconButton>
          )
        })
        }
      >
        {
          data?.map((item, index) => (
            <MenuItem key={index} value={item[filterKey]}> {item[[filterKey]]} </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
const FilterTipoPaciente = ({ label, data, filterKey, setValue, value }) => {
  const [selectedValue, setSelectedValue] = useState('')
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='select-tipopaciente'>{label}</InputLabel>
      <Select
        inputlabelpros={{ shrink: true }}
        labelId='select-tipopaciente'
        size="small"
        value={value}
        label={label}
        onChange={e => setValue(e.target.value)}
        fullWidth
        sx={{
          "& .MuiSelect-iconOutlined": { display: selectedValue !== '' ? 'none' : '' },
          "&.Mui-focused .MuiIconButton-root": { color: "primary.main" }
        }}
        { // Agregar boton para limpiar seleccion
        ...(selectedValue !== '' && {
          endAdornment: (
            <IconButton
              size='small'
              sx={{ mr: -1.3 }}
            // onClick={onClearText}
            >
              {/* <ClearRoundedIcon /> */}
            </IconButton>
          )
        })
        }
      >
        {
          data?.map((item, index) => (
            <MenuItem key={index} value={item[filterKey]}> {item[[filterKey]]} </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}


const FilterState = ({ label = 'Estado', setValue, value }) => {
  //Filtrar datos del datagrid de acuerdo a su estado
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='select-estado'>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        size="small"
        fullWidth
        onChange={e => setValue(e.target.value)}
      >
        <MenuItem value={-1}>Todos</MenuItem>
        <MenuItem value={1}>Activos</MenuItem>
        <MenuItem value={0}>Inactivos</MenuItem>
      </Select>
    </FormControl>
  )
}

const FilterDataByState = ({ label, data, setData }) => {
  //Filtrar datos del datagrid de acuerdo a su estado
  return (
    <TextField
      fullWidth
      select
      label={label}
      size='small'
      onChange={e => setData(e.target.value)}
      value={data}
    >
      <MenuItem value={-1}>Todos</MenuItem>
      <MenuItem value={1}>Activos</MenuItem>
      <MenuItem value={0}>Inactivos</MenuItem>
    </TextField>
  )
}

const FilterDates = ({ label, value, setValue }) => {
  return (
    <FormControl fullWidth>
      <TextField
        InputLabelProps={{ shrink: true }}
        FormHelperTextProps={{ hidden: true }}
        inputProps={{
          // max: "2025-01-01",
          sx: {
            "&::placeholder": {
              color: "green"
            }
          }
        }}
        variant="outlined"
        size="small"
        type="date"
        label={label}
        placeholder="Placeholder Text"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </FormControl>
  )
}

function compareString(value, filter) {
  filter = filter?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
  value = value?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
  return value?.includes(filter)
}

const useFilterByPaciente = () => {
  const [paciente, setPaciente] = useState('')

  const filterByPaciente = (data, filterValue) => {
    return data?.filter(item => compareString(item.paciente, filterValue))
  }

  return { filterByPaciente, paciente, setPaciente }
}
const useFilterByPacienteNroDoc = () => {
  const [nroDoc, setPacienteNroDoc] = useState('')

  const filterByPacienteNroDoc = (data, filterValue) => {
    return data?.filter(item => compareString(item.nroDoc, filterValue))
  }

  return { filterByPacienteNroDoc, nroDoc, setPacienteNroDoc }
}
const useFilterByPacienteEscuelaProfesional = () => {
  const [escuelaProfesional, setPacienteEscuelaProfesional] = useState('')

  const filterByPacienteEscuelaProfesional = (data, filterValue) => {
    return data?.filter(item => compareString(item.escuelaProfesional, filterValue))
  }

  return { filterByPacienteEscuelaProfesional, escuelaProfesional, setPacienteEscuelaProfesional }
}
const useFilterByTipoPaciente = () => {
  const [tipoPaciente, setTipoPaciente] = useState('')

  const filterByTipoPaciente = (data, filterValue) => {
    return data?.filter(item => compareString(item.tipoPaciente, filterValue))
  }

  return { filterByTipoPaciente, tipoPaciente, setTipoPaciente }
}
const useFilterByPacienteEstado = () => {
  const [estado, setEstado] = useState('')

  const filterByPacienteEstado = (data, filterValue) => {
    return data?.filter(item => compareString(item.estado, filterValue))
  }

  return { filterByPacienteEstado, estado, setEstado }
}

const useFilterByState = (keyWord = 'estado') => {
  //Filtrar datos del datagrid de acuerdo a su estado
  const [estado, setEstado] = useState(-1)

  const filterByState = (data, filterValue) => {
    // return data?.filter(item => compareString(item[keyWord].toString(), filterValue.toString()) )
    return data?.filter(item => {
      if (filterValue !== 0 && filterValue !== 1) return true
      else return item[keyWord] === filterValue
    })
  }

  return { filterByState, estado, setEstado }
}

const useFilterDataByState = (data, keyWord = 'estado', defaultState = -1) => {
  //Filtrar datos en general de acuerdo a su estado
  const [stateData, setStateData] = useState(defaultState)//0:inactivo, 1:activo, -1:todos

  const dataByState = data?.filter((item) => {
    if (stateData !== 0 && stateData !== 1) return true
    else return item[keyWord] === stateData
  })

  return { dataByState, stateData, setStateData }
}

const useFilterByEspecialista = () => {
  const [especialista, setEspecialista] = useState('')

  const filterByEspecialista = (data, filterValue) => {
    return data?.filter(item => compareString(item.nombres, filterValue))
  }

  return { filterByEspecialista, especialista, setEspecialista }
}

const useFilterByEspecialidad = (filterKey = 'especialidad') => {
  const [especialidad, setEspecialidad] = useState('')
  const { data: dataEspecialidades } = useGetAllEspecialidad()
  const especialidades = dataEspecialidades ? dataEspecialidades : []

  const filterByEspecialidad = (data, filterValue) => {
    // return data?.filter(item => compareString(item.especialidad, filterValue))
    return data?.filter(item => compareString(item[filterKey], filterValue))
  }

  return { filterByEspecialidad, especialidad, setEspecialidad, especialidades }
}

const useFilterByMinDate = (today) => {
  const fechaInicial = today || (new Date()).toJSON().slice(0, 8) + '01'
  const [minDate, setMinDate] = useState(fechaInicial)

  const filterMinDate = (data, filterValue) => {
    if (!filterValue) return data

    const filterDate = dayjs(filterValue)

    return data?.filter(item => {
      const itemDate = dayjs(item.fecha)
      return itemDate >= filterDate
    })
  }
  return { filterMinDate, minDate, setMinDate, fechaInicial }
}

const useFilterByMaxDate = () => {
  // var date = new Date();
  // date.setUTCHours(-5, 0, 0, 0);
  // const fechaActual = date.toJSON().slice(0, 10)
  const fechaActual = new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' })
  const [maxDate, setMaxDate] = useState(fechaActual)

  const filterMaxDate = (data, filterValue) => {
    if (!filterValue) return data

    const filterDate = dayjs(filterValue)

    return data?.filter(item => {
      const itemDate = dayjs(item.fecha)
      return itemDate <= filterDate
    })
  }

  return { filterMaxDate, maxDate, setMaxDate, fechaActual }
}

const ApplyFilters = ({ data, filters, children, renderItem }) => {
  filters.map(({ filter, filterValue }) => {
    data = filter(data, filterValue)
  })

  return (
    <Grid container spacing={2}>
      {
        children
      }
      <Grid item xs={12}>
        {
          renderItem && renderItem()
        }
      </Grid>
    </Grid >
  )
}

const FilterButtons = ({ applyFilters, clearFilters }) => {
  return (
    <>
      <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
        <Button
          sx={{ width: '80%' }}
          variant='contained'
          startIcon={<FilterAltRoundedIcon />}
          onClick={applyFilters}
        >
          Filtrar
        </Button>
        <Button
          sx={{ width: '20%' }}
          color='primary'
          onClick={clearFilters}
        >
          <FilterAltOffRoundedIcon />
        </Button>
      </ButtonGroup>
    </>
  )
}

export {
  ApplyFilters,
  SearchInput,
  Filter,
  FilterEstado,
  FilterTipoPaciente,
  FilterDates,
  FilterState,
  FilterDataByState,
  FilterButtons,
  FilterAutoCompleteCampo,
  useFilterByPaciente,
  useFilterByTipoPaciente,
  useFilterByPacienteEstado,
  useFilterByPacienteNroDoc,
  useFilterByEspecialidad,
  useFilterByPacienteEscuelaProfesional,
  useFilterDataByState,
  useFilterByState,
  useFilterByEspecialista,
  useFilterByMinDate,
  useFilterByMaxDate,
  AutoCompletePaciente
}
import { useUpdatePerfil } from '../../../../hooks/usePerfiles'
import FormPerfiles from './FormPerfiles'

const UpdatePerfiles = ({ id }) => {
  const { mutate, isLoading } = useUpdatePerfil(id)
  return (
    <>
      <FormPerfiles edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdatePerfiles

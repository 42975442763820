import EspecialistaForm from './EspecialistaForm'
import { useUpdateEspecialista } from '../../../../hooks/useEspecialistas'

const UpdateEspecialista = ({ data }) => {
  const { mutate } = useUpdateEspecialista(data.idEspecialista)
  return (
    <>
      <EspecialistaForm edit={true} data={data} mutate={mutate} />
    </>
  )
}

export default UpdateEspecialista

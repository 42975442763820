import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Divider, Grid,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetByIdAcceso } from '../../../../hooks/useAccesos'
import AccesoSchema from '../schema/acceso.schema'

const DialogFormAccesos = ({ edit, id, mutate, isLoading, open, handleClose }) => {
  const { data } = useGetByIdAcceso(id, edit) || []
  const [acceso] = data || []
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()

  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(AccesoSchema),
    defaultValues: {
      rol: acceso?.rol || '',
      nombreEtiqueta: acceso?.nombreEtiqueta || '',
      descripcion: acceso?.descripcion || '',
      ruta: acceso?.ruta || '',
      estadoRegistro: acceso?.estadoRegistro || '',
      iconoAcceso: acceso?.iconoAcceso || '',
      menu: acceso?.menu || 1,
    },
  })

  const onSubmit = data => {
    const formData = {
      ...data,
      estadoRegistro: 1,
    }
    const successMessage = edit ? 'Acceso editado con exito' : 'Acceso creado con exito'
    const errorMessage = edit ? 'No se pudo editar acceso' : 'No se pudo crear acceso'

    mutate(formData, {
      onError: () => {
        handleClose()
        showError(errorMessage)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(successMessage)
        reset()
      }
    })
  }

  useEffect(() => {
    if (edit)
      reset({
        rol: acceso?.rol || '',
        nombreEtiqueta: acceso?.nombreEtiqueta || '',
        descripcion: acceso?.descripcion || '',
        ruta: acceso?.ruta || '',
        estadoRegistro: acceso?.estadoRegistro || '',
        iconoAcceso: acceso?.iconoAcceso || '',
        menu: acceso?.menu || 1,
      })
  }, [acceso])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR ACCESO' : 'AÑADIR ACCESO'}</DialogTitle>
        <Grid container >
          <Grid item xs={12} >
            <Divider variant='middle'></Divider>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información de acceso
            </DialogContentText>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              sx={{ padding: '10px 40px' }}
            >
              {/* <Grid item xs={12}>
                <Input
                  control={control}
                  id='idAcceso'
                  label='Código de acceso'
                  placeholder='Ingrese código de acceso'
                  type='number'
                  disabled={edit}
                  error={errors.idAcceso}
                  helperText={errors.idAcceso?.message}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='rol'
                  label='Rol'
                  placeholder='Ingrese categoria'
                  type='select'
                  data={[
                    { value: 'administrador', denominacion: 'Administrador' },
                    { value: 'especialista', denominacion: 'Especialista' },
                    { value: 'paciente', denominacion: 'Paciente' },
                  ]}
                  error={errors.rol}
                  helperText={errors.rol?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='nombreEtiqueta'
                  label='Etiqueta'
                  placeholder='Ingrese nombre de la etiqueta'
                  error={errors.nombreEtiqueta}
                  helperText={errors.nombreEtiqueta?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='descripcion'
                  label='Descripción'
                  placeholder='Ingrese descripción'
                  error={errors.descripcion}
                  helperText={errors.descripcion?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='ruta'
                  label='Ruta'
                  placeholder='Ingrese ruta'
                  error={errors.ruta}
                  helperText={errors.ruta?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  control={control}
                  id='iconoAcceso'
                  label='Icono'
                  placeholder='Ingrese icono'
                  error={errors.iconoAcceso}
                  helperText={errors.iconoAcceso?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='menu'
                  label='Mostrar en menú'
                  type='select'
                  data={[
                    { value: 1, denominacion: 'Si' },
                    { value: 0, denominacion: 'No' },
                  ]}
                  error={errors.menu}
                  helperText={errors.menu?.message}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()
              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog >
    </>
  )

}

export default DialogFormAccesos
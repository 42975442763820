import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SaveIcon from '@mui/icons-material/Save'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import ButtonAdd from '../../../../components/ButtonAdd'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
import { UserContext } from '../../../../context/userContext'
import { useUpdateHorario } from '../../../../hooks/useHorario'
import { generarHorario } from '../utils/horario'
import { Schedule } from './Schedule'
import { useGetByDniEspecialista } from '../../../../hooks/useEspecialistas'

const EspecialidadSchema = yup.object().shape({
  fechaIni: yup
    .date()
    .max(
      yup.ref('fechaFin'),
      'La fecha de inicial debe ser menor a la fecha final'
    )
    .typeError('* Campo requerido')
    .required('* Campo requerido'),
  fechaFin: yup
    .date()
    .min(
      yup.ref('fechaIni'),
      'La fecha de final debe ser mayor a la fecha inicial'
    )
    .typeError('* Campo requerido')
    .required('* Campo requerido'),
  // estado: yup.number(),
  horaInicio: yup
    .string()
    .test({
      name: 'hora-inicio-valido',
      message: 'La hora de inicio debe ser menor a la hora final',
      test: function (value) {
        const { horaFin } = this.parent
        return value < horaFin
      }
    })
    .required('* Campo requerido'),
  horaFin: yup
    .string()
    .test({
      name: 'hora-fin-valido',
      message: 'La hora de final debe ser mayor a la hora final',
      test: function (value) {
        const { horaInicio } = this.parent
        return value > horaInicio
      }
    })
    .required('* Campo requerido'),
  periodoAtencion: yup
    .number()
    .min(10, 'El periodo de atención debe ser mayor a 10 minutos')
    .typeError('* Campo requerido')
    .required('* Campo requerido')
})

const FormHorario = ({ edit, data, mutate, isLoading }) => {
  const { user, especialidad } = useContext(UserContext)
  const [idEspecialista] = useState(especialidad?.idEspecialista)
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    getValues,
    register
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EspecialidadSchema),
    defaultValues: {
      fechaIni: new Date().toLocaleDateString('sv', {
        timeZone: 'America/Lima'
      }),
      fechaFin: new Date().toLocaleDateString('sv', {
        timeZone: 'America/Lima'
      }),
      estado: 0,
      horaInicio: '00:00',
      horaFin: '23:00',
      periodoAtencion: 10 //requiere periodoAtencion de especialidad
    }
  })

  const [open, setOpen] = useState(false)
  const [showHorario, setShowHorario] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')

  const { showError, showSuccess } = useAlertMessage()
  const [horarioDetalle, setHorarioDetalle] = useState({
    fechaFin: '',
    fechaIni: '',
    horaInicio: '',
    horaFin: '',
    periodoAtencion: 10
  })
  const [horario, setHorario] = useState([])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    reset()
    setHorarioDetalle({
      fechaFin: '',
      fechaIni: '',
      horaInicio: '',
      horaFin: '',
      periodoAtencion: 10
    })
    setOpen(false)
    setHorario([])
    setShowHorario(false)
  }

  const onSubmit = data => {
    const formData = {
      idEspecialista: idEspecialista,
      fechaIni: new Date(data.fechaIni).toJSON().slice(0, 10),
      fechaFin: new Date(data.fechaFin).toJSON().slice(0, 10),
      horaIni: data.horaInicio,
      horaFin: data.horaFin,
      periodoAtencion: Number(horarioDetalle.periodoAtencion),
      estado: 0,
      horarioDetalle: horario
        .filter(item => item.estado === 1)
        .map(({ diaHorario, horaInicio, horaFin, estado }) => ({
          dia: diaHorario,
          horaIni: horaInicio,
          horaFin,
          estado,
        }))
    }
    console.log(formData)
    const msg = edit
      ? 'No se pudo editar su Horario'
      : 'No se pudo crear el Horario'
    const msg2 = edit
      ? 'Horario editado correctamente'
      : 'Horario creado correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        handleClose()
        reset()
      }
    })
  }

  const handlehorarioDetalle = e => {
    const { name, value } = e.target
    if (name === 'periodoAtencion' && value < 10) return
    setHorarioDetalle(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  const optimizedFn = useCallback(debounce(handlehorarioDetalle), [])

  useEffect(() => {
    const { horaInicio, horaFin, periodoAtencion } = horarioDetalle
    if (!horaInicio || !horaFin || !periodoAtencion) return

    const newHorario = generarHorario({
      horaInicio: horaInicio,
      horaFin: horaFin,
      intervalo: periodoAtencion,
      fechaInicio: getValues('fechaIni'),
      fechaFin: getValues('fechaFin')
    })
    setHorario(newHorario)
  }, [horarioDetalle])

  return (
    <>
      {edit ? (
        <Tooltip title='Editar Horario'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <ScheduleRoundedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          {showHorario ? 'MI HORARIO' : 'AÑADIR HORARIO'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
              reset()
            }}
          />
        </DialogTitle>
        {
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText sx={{ marginBottom: '1em' }}>
                Información básica de la Horario
              </DialogContentText>
              <Grid container spacing={3} alignContent='center'>
                <Grid item xs={12} sm={6}>
                  <Input
                    control={control}
                    id='fechaIni'
                    label='Fecha Inicial'
                    type='date'
                    placeholder='Ingrese Fecha de inicio'
                    {...register('fechaIni', {
                      required: 'Campo requerido',
                      onChange: handlehorarioDetalle
                    })}
                  />
                  <Typography variant='subtitle2' align='left' color='red'>
                    {errors.fechaIni?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    control={control}
                    id='fechaFin'
                    label='Fecha Final'
                    type='date'
                    placeholder='Ingrese Fecha de fin'
                    {...register('fechaFin', {
                      onChange: handlehorarioDetalle
                    })}
                  />
                  <Typography variant='subtitle2' align='left' color='red'>
                    {errors.fechaFin?.message}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Input
                    control={control}
                    id='horaInicio'
                    name='horaInicio'
                    label='Hora de entrada'
                    type='time'
                    placeholder='Ingrese la hora de entrada'
                    {...register('horaInicio', {
                      onChange: handlehorarioDetalle
                    })}
                  />
                  <Typography variant='subtitle2' align='left' color='red'>
                    {errors.horaInicio?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    control={control}
                    id='horaFin'
                    name='horaFin'
                    label='Hora de salida'
                    type='time'
                    placeholder='Ingrese la hora de salida'
                    {...register('horaFin', {
                      required: 'Campo requerido',
                      onChange: handlehorarioDetalle
                    })}
                  />
                  <Typography variant='subtitle2' align='left' color='red'>
                    {errors.horaFin?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    control={control}
                    id='periodoAtencion'
                    name='periodoAtencion'
                    label='Periodo de atención'
                    type='number'
                    placeholder='Ingrese el periodo de atención'
                    {...register('periodoAtencion', {
                      required: 'Campo requerido',
                      min: 10,
                      onChange: optimizedFn
                    })}
                  />
                  <Typography variant='subtitle2' align='left' color='red'>
                    {errors.periodoAtencion?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {horarioDetalle.horaInicio &&
                  horarioDetalle.horaFin &&
                  horarioDetalle.periodoAtencion ? (
                    <Schedule
                      horaInicio={horarioDetalle.horaInicio}
                      horaFin={horarioDetalle.horaFin}
                      intervalos={horarioDetalle.periodoAtencion}
                      horario={horario}
                      setHorario={setHorario}
                      citas={[]}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'dashed 1px #ccc',
                        borderRadius: '5px',
                        padding: '1em'
                      }}
                    >
                      <Typography variant='body' align='center'>
                        Contruyendo horario
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='error'
                startIcon={<CancelRoundedIcon />}
                onClick={() => {
                  handleClose()
                  reset()
                }}
              >
                CANCELAR
              </Button>
              <LoadingButton
                loading={isLoading}
                type='submit'
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='contained'
              >
                GUARDAR
              </LoadingButton>
            </DialogActions>
          </form>
        }
      </Dialog>
    </>
  )
}

export default FormHorario

import {
  Button,
  Dialog, DialogActions,
  // DialogTitle,
  DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Switch, Tooltip
} from '@mui/material'
import { useState } from 'react'
import { useAlertMessage } from '../../../../context/notifications'
import { useUpdateEspecialidad } from '../../../../hooks/useEspecialidades'

const ConfirmationModal = ({ data, property, title, description, tooltip }) => {
  const { mutate } = useUpdateEspecialidad(data.idEspecialidad)
  const checked = Boolean(data[property])
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const confirmState = () => {
    const formData = {
      ...data,
      [property]: Number(!checked)
    }
    const msg = 'No se pudo cambiar el estado'
    const msg2 = 'Se cambió el estado correctamente'

    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
      }
    })
    handleClose()
  }
  return (
    <>
      <Tooltip title={tooltip}>
        <FormGroup
          onClick={handleClickOpen}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                name='checkedB'
                color='primary'
              />
            }
          />
        </FormGroup>
      </Tooltip>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <b>{description}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            CANCELAR
          </Button>
          <Button variant='contained' onClick={confirmState}>
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmationModal


import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Divider, Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import ButtonAdd from '../../../../components/ButtonAdd'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
import md5 from 'md5'

const PacienteSchema = yup.object({
  nombres: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[a-zA-ZÁÉÍÓÚÜÑáéíóúüñ\s]+$/, 'Debe contener solo letras'),
  apPaterno: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[a-zA-ZÁÉÍÓÚÜÑáéíóúüñ\s]+$/, 'Debe contener solo letras'),
  apMaterno: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[a-zA-ZÁÉÍÓÚÜÑáéíóúüñ\s]+$/, 'Debe contener solo letras'),
  dni: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[0-9]+$/, 'Debe contener solo digitos numéricos')
    .length(8, 'Debe contener 8 dígitos'),
  tipoPaciente: yup
    .string()
    .required('* Campo requerido'),
  fechaNacimiento: yup
    .string()
    .required('* Campo requerido'),
  alergias: yup
    .string()
    .nullable(),
  vacunas: yup
    .string()
    .nullable(),
  cirugias: yup
    .string()
    .nullable(),
  direccion: yup
    .string()
    .required('* Campo requerido'),
  celular: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[0-9]+$/, 'Debe contener solo digitos numéricos')
    .length(9, 'Debe contener 9 dígitos'),
  personaResponsable: yup
    .string()
    .matches(/^[a-zA-ZÁÉÍÓÚÜÑáéíóúüñ\s]+$/, 'Debe contener solo letras')
    .required('* Campo requerido'),
  celularResponsable: yup
    .string()
    .required('* Campo requerido')
    .matches(/^[0-9]+$/, 'Debe contener solo digitos numéricos')
    .length(9, 'Debe contener 9 dígitos'),
  tipoSangre: yup
    .string()
    .required('* Campo requerido'),
})

const FormPaciente = ({ edit, data, mutate, isLoading }) => {

  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(PacienteSchema),
    defaultValues: {
      nombres: '',
      apPaterno: '',
      apMaterno: '',
      dni: '',
      tipoPaciente: '',
      fechaNacimiento: '',
      alergias: '',
      vacunas: '',
      cirugias: '',
      direccion: '',
      celular: '',
      personaResponsable: '',
      celularResponsable: '',
      tipoSangre: '',
    }
  })

  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { showError, showSuccess } = useAlertMessage()
  const { showSuccess:showSuccessContraseña } = useAlertMessage()

  const CambiarContraseña = () => {
  //  console.log(md5(data.nroDoc))
  //  console.log(data.nroDoc)
    showSuccessContraseña('La contraseña fue restablecida')
  }
  const handleOpen = () => {
    if (edit) {
      // console.log(data)
      reset({
        nombres: data.nombres,
        apPaterno: data.apPaterno,
        apMaterno: data.apMaterno,
        dni: data.nroDoc,
        tipoPaciente: data.tipoPaciente,
        fechaNacimiento: new Date(data.fechaNacimiento).toISOString().split('T')[0],
        alergias: data.alergias,
        vacunas: data.vacunas,
        cirugias: data.cirugias,
        direccion: data.direccion,
        celular: data.celular,
        personaResponsable: data.personaResponsable,
        celularResponsable: data.celularResponsable?.trim(),
        tipoSangre: data.tipoSangre,
      })
    }
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = data => {
    // console.log('submit')
    const formData = {
      nombres: data.nombres,
      apPaterno: data.apPaterno,
      apMaterno: data.apMaterno,
      dni: data.dni,
      tipoPaciente: data.tipoPaciente,
      fechaNacimiento: data.fechaNacimiento,
      alergias: data.alergias,
      vacunas: data.vacunas,
      cirugias: data.cirugias,
      direccion: data.direccion,
      celular: data.celular,
      personaResponsable: data.personaResponsable,
      celularResponsable: data.celularResponsable,
      tipoSangre: data.tipoSangre,
    }

    const msg = edit ? 'No se pudo editar Paciente' : 'No se pudo crear Paciente'
    const msg2 = edit
      ? 'Paciente editado correctamente'
      : 'Paciente creado correctamente'
    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }
  return (
    <>
      {edit ? (
        <Tooltip title='Editar Paciente'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR PACIENTE' : 'AÑADIR PACIENTE'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información básica del paciente
            </DialogContentText>
            <Grid container spacing={3} alignContent='center'>
               <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='dni'
                  label='DNI'
                  type='number'
                  placeholder='Ingrese DNI'
                  disabled={edit}
                  helperText={errors.dni?.message}
                  error={errors?.dni}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='nombres'
                  label='Nombre(s)'
                  placeholder='Ingrese nombre(s)'
                  helperText={errors.nombres?.message}
                  error={errors.nombres}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='apPaterno'
                  label='Apellido paterno'
                  placeholder='Ingrese apellido paterno'
                  helperText={errors.apPaterno?.message}
                  error={errors.apPaterno}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='apMaterno'
                  label='Apellido materno'
                  placeholder='Ingrese apellido materno'
                  helperText={errors.apMaterno?.message}
                  error={errors.apMaterno}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='tipoPaciente'
                  label='Tipo de paciente'
                  type='select'
                  data={[
                    { value: 'Estudiante', denominacion: 'Estudiante' }, 
                    { value: 'Docente', denominacion: 'Docente'}, 
                    { value: 'Administrativo', denominacion: 'Administrativo'}]}
                  helperText={errors.tipoPaciente?.message}
                  error={errors.tipoPaciente}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='fechaNacimiento'
                  label='Fecha de nacimiento'
                  type='date'
                  helperText={errors.fechaNacimiento?.message}
                  error={errors.fechaNacimiento}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='celular'
                  label='Celular'
                  type='number'
                  placeholder='Ingrese nro. de celular'
                  helperText={errors.celular?.message}
                  error={errors.celular}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <Input
                  control={control}
                  id='direccion'
                  label='Dirección'
                  placeholder='Ingrese dirrección'
                  helperText={errors.direccion?.message}
                  error={errors.direccion}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Input
                  control={control}
                  id='tipoSangre'
                  label='Tipo de sangre'
                  type='select'
                  data={[
                    { value: 'A+', denominacion: 'A+' },
                    { value: 'A-', denominacion: 'A-' },
                    { value: 'B+', denominacion: 'B+' },
                    { value: 'B-', denominacion: 'B-' },
                    { value: 'AB+', denominacion: 'AB+' },
                    { value: 'AB-', denominacion: 'AB-' },
                    { value: 'O+', denominacion: 'O+' },
                    { value: 'O-', denominacion: 'O-' },
                  ]}
                  helperText={errors.tipoSangre?.message}
                  error={errors.tipoSangre}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='alergias'
                  label='Alergias'
                  rows={2}
                  multiline
                  placeholder='Ingrese las alergias del paciente'
                  helperText={errors.alergias?.message}
                  error={errors.alergias}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='vacunas'
                  label='Vacunas'
                  rows={2}
                  multiline
                  placeholder='Ingrese las vacunas del paciente'
                  helperText={errors.vacunas?.message}
                  error={errors.vacunas}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  control={control}
                  id='cirugias'
                  label='Cirugías'
                  rows={2}
                  multiline
                  placeholder='Ingrese las vacunas del paciente'
                  helperText={errors.cirugias?.message}
                  error={errors.cirugias}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <Input
                  control={control}
                  id='personaResponsable'
                  label='Persona responsable'
                  placeholder='Ingrese nombres y apellidos'
                  helperText={errors.personaResponsable?.message}
                  error={errors.personaResponsable}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <Input
                  control={control}
                  id='celularResponsable'
                  label='Celular del responsable'
                  type='number'
                  placeholder='Ingrese nro. de celular'
                  helperText={errors.celularResponsable?.message}
                  error={errors.celularResponsable}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>Restablecer Contraseña </Divider>
              </Grid>
              <Grid item xs={12} sm={8} alignItems={'center'} justifyContent={'center'} display={'flex'} >
                <p>Si el paciente olvidó su contraseña se restablecerá con el DNI del paciente </p>
              </Grid>
              <Grid item xs={12} sm={4} alignItems={'center'} justifyContent={'center'} display={'flex'} >
              <Button variant="contained" color="success" 
              onClick={CambiarContraseña}
              >
                  Restablecer
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()
              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormPaciente
import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
export const CardPerfilClinico = ({ titulo, Icon, descripcion }) => {

    return (
        <Card className='rounded-xl '>
            <CardHeader
                className='bg-[#217E93] text-white'
                title={titulo}
            />
            <CardContent className='flex items-center justify-center'>
                <Icon sx={{ fontSize: 80 }} />
                <Typography variant='h4'>{descripcion}</Typography>
            </CardContent>
        </Card>
    )
}

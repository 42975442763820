import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  Create,
  GetAdministrador,
  GetByIdEnfermeraTriajeAtendido,
  GetByIdEnfermeraTriajePendiente,
  GetByIdEspecialista,
  GetByIdPaciete,
  GetCitaPasada,
  GetCitaPendiente,
  Update,
} from '../services/citaMedica'

const key = 'citaMedica'

export const useGetCitasByIdEspecialista = (idEspecialista, tipoConsulta, enabled = true) =>
  useQuery([key, idEspecialista, tipoConsulta], GetByIdEspecialista, {
    enabled
  })

export const useGetCitaPendiente = (idEspecialista, idPaciente, enabled = true) =>
  useQuery([key, idEspecialista, idPaciente], GetCitaPendiente, {
    enabled
  })

export const useGetCitaPasada = (idEspecialista, Dni, fechaInicial, fechaFinal, nEstado, enabled = true) =>
  useQuery([key, idEspecialista, Dni, fechaInicial, fechaFinal, nEstado], GetCitaPasada, {
    enabled
  })

export const useGetCitasByByIdEnfermeraTriajeAtendido = (idEnfermera, idEspecialidad, fechaFinal, fechaInicial, enabled = true) =>
  useQuery([key, idEnfermera, idEspecialidad, fechaFinal, fechaInicial], GetByIdEnfermeraTriajeAtendido, {
    enabled
  })

export const useGetCitasByByIdEnfermeraTriajePendiente = (idEnfermera, idEspecialidad, enabled = true) =>
  useQuery([key, idEnfermera, idEspecialidad], GetByIdEnfermeraTriajePendiente, {
    enabled
  })

export const useGetCitasAdministrador = (idEspecialista, idEspecialidad, fechaFinal, fechaInicial, enabled = true) =>
  useQuery([key, idEspecialista, idEspecialidad, fechaFinal, fechaInicial], GetAdministrador, {
    enabled
  })

export const useGetCitasByIdPaciente = (idPaciente, enabled = true) =>
  useQuery([key, idPaciente], GetByIdPaciete, {
    enabled
  })

export const useCreateCitaMedica = () => {
  const queryClient = useQueryClient()
  return useMutation(body => Create(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
export const useUpdateCitaMedica = (idCitaMedica) => {
  const queryClient = useQueryClient()
  return useMutation(body => Update(idCitaMedica, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
const TOOTH_FORMAT = ['Pieza_Odontograma', 'Posicion_Pieza', 'Estado_Pieza', 'Abreviación']
import Store, { FaceColors } from "../components/data";

const groupBy = (arr, key) => {
  return arr.reduce((result, obj) => {
    const keyValue = obj[key];
    (result[keyValue] = result[keyValue] || []).push(obj)
    return result
  }, {})
}

const arrayToObject = (arr, campoClave, campoValor) => {
  return arr.reduce((resultado, elemento) => {
    const clave = elemento[campoClave];
    const valor = elemento[campoValor];
    resultado[clave] = valor;
    return resultado;
  }, {});
};
export const clearOdontograma = () => {
  const odontograma = Store.arcada.adulto.map(tooth => {
    const faces = tooth.faces.map(face => {
      return {
        ...face,
        estado: 'Normal',
        color: 'white'
      }
    })

    return {
      ...tooth,
      faces
    }
  })
  return odontograma
}

export const formatBodyOndontograma = (toothData, formatos) => {
  let consultaDatos = []
  for (let i = 0; i < toothData.length; i++) {
    const id = toothData[i].id
    const faces = toothData[i].faces
    const abreviacion = toothData[i]?.annotation ?? ''

    let posicionePieza = ''
    let estadoPieza = ''
    for (let j = 0; j < faces.length; j++) {
      if (faces[j].estado === "Normal") continue
      posicionePieza += faces[j].name + ','
      estadoPieza += faces[j].estado + ','
    }

    if (posicionePieza === '') continue

    const DATA = {
      'Pieza_Odontograma': id,
      'Posicion_Pieza': posicionePieza,
      'Estado_Pieza': estadoPieza,
      'Abreviación': abreviacion
    }

    TOOTH_FORMAT.map(key => {
      const { idItem, nombreCampo } = formatos.find(formato => formato.nombreCampo === key)
      consultaDatos.push({
        idItem,
        nombreCampo,
        idSubItem: id,
        valor: String(DATA[key]) === '' ? null : String(DATA[key])
      })
    })
  }
  return consultaDatos
}

export const formatToothData = (formatoDetalle) => {
  const agrupadoPorIdSubItem = groupBy(formatoDetalle, 'idSubItem')
  const odontogramaFormat = [...clearOdontograma()]
  for (let i = 0; i < odontogramaFormat.length; i++) {
    const tooth = odontogramaFormat[i]
    const toothFinded = agrupadoPorIdSubItem[tooth.id]
    if (!toothFinded) continue

    const datosTransformados = arrayToObject(toothFinded, 'nombreCampo', 'valor')
    const estados = datosTransformados["Estado_Pieza"].split(',').filter(Boolean)
    const posiciones = datosTransformados["Posicion_Pieza"].split(',').filter(Boolean)
    const abreviacion = datosTransformados["Abreviación"]

    for (let j = 0; j < tooth.faces.length; j++) {
      const face = tooth.faces[j]
      const index = posiciones.indexOf(face.name)
      if (index > -1) {
        const estado = estados[index]
        face.estado = estado
        face.color = FaceColors[estado]
      }
    }

    tooth.annotation = abreviacion
  }

  return odontogramaFormat
}

import { Box, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import { useState } from 'react';

export const DatosPaciente = ({datosPaciente,error}) => {
  const [showTriaje, setShowTriaje] = useState(false)
  return (
    <Grid container >
      <Grid item xs={12}>
      <Grid item xs={12}>
        <Box className="flex items-center mb-1">
          <Typography className='font-bold'> Datos Paciente</Typography>
          <Tooltip title={`${showTriaje ? 'Ocultar' : 'Mostrar'} datos de paciente`}>
            <IconButton
              onClick={() => setShowTriaje(!showTriaje)}
            >
              {showTriaje ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Typography
            variant='subtitle2'
            align='left'
            color='red'
          >
            {error.isError && error.messagePaciente}
          </Typography>
        </Box>
      </Grid>
        <Collapse in={showTriaje}>
          <Grid container justifyContent="space-around" >
            <Grid item xs={12} sm={12} p={2} sx={{display:{sm:'inline',md:'none'}}}>
              <List>
                  {
                    datosPaciente && Object.keys(datosPaciente).map((key, index) => {
                      const { nombre, valor, unidad, Icon, color } = datosPaciente[key]
                      return (
                        <div key={index}>
                          <Divider />
                          <ListItem className='my-[2px]'>
                          <ListItemIcon> <Icon sx={{ color }} /> </ListItemIcon>
                          <ListItemText>
                              <span className='font-bold text-[#404040]'>{nombre}</span>
                          </ListItemText>
                          <span>{valor ?? '--'}</span>
                          <span className={`text-[#7e7f97] ${unidad !== '' ? 'ml-1' : ''}`}>
                              {unidad}
                          </span>
                          </ListItem>
                        </div>
                      )
                    })
                  }
                </List>
            </Grid>
            <Grid item md={6}  p={2} sx={{display:{sm:'none',xs:'none',md:'inline'}}}>
              <List>
                {
                  datosPaciente && Object.keys(datosPaciente).map((key, index) => {
                    const { nombre, valor, unidad, Icon, color } = datosPaciente[key]
                    return (
                      <div key={index}>
                        {
                            index %2===0 &&
                            <>
                            <Divider />
                            <ListItem className='my-[2px]'>
                            <ListItemIcon> <Icon sx={{ color }} /> </ListItemIcon>
                            <ListItemText>
                                <span className='font-bold text-[#404040]'>{nombre}</span>
                            </ListItemText>
                            <span>{valor ?? '--'}</span>
                            <span className={`text-[#7e7f97] ${unidad !== '' ? 'ml-1' : ''}`}>
                                {unidad}
                            </span>
                            </ListItem>
                            </>
                        }
                      </div>
                    )
                  })
                }
              </List>
            </Grid>
            <Grid item md={6}  p={2} sx={{display:{sm:'none',xs:'none' ,md:'inline'}}}>
              <List>
                {
                  datosPaciente && Object.keys(datosPaciente).map((key, index) => {
                    const { nombre, valor, unidad, Icon, color } = datosPaciente[key]
                    return (
                      <div key={index}>
                        {
                            (index %2!=0) &&
                            <>
                            <Divider />
                            <ListItem className='my-[2px]'>
                            <ListItemIcon> <Icon sx={{ color }} /> </ListItemIcon>
                            <ListItemText>
                                <span className='font-bold text-[#404040]'>{nombre}</span>
                            </ListItemText>
                            <span>{valor ?? '--'}</span>
                            </ListItem>
                            </>
                        }
                      </div>
                    )
                  })
                }
              </List>
            </Grid>
         </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
import { UserContext } from '../../../../context/userContext'
import { useGetCitasEspecialista } from '../../../../hooks/back-clinica/useCitas'

const TriajeSchema = yup.object({
    // nroDocPaciente: yup
    //     .string()
    //     .required('Campo requerido')
    //     .typeError('Campo requerido'),
    presionArterial: yup
        .string()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    temperatura: yup
        .number()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    frecuenciaRespiratoria: yup
        .number()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    frecuenciaCardiaca: yup
        .number()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    saturacion: yup
        .number()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    peso: yup
        .number()
        .positive()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    talla: yup
        .number()
        .positive()
        .required('Campo requerido')
        .typeError('Campo requerido'),
})

export const FormTriaje = ({ mutate, isLoading, handleClose, open, handleOpen }) => {
    const { showError, showSuccess } = useAlertMessage()
    const { especialidad } = useContext(UserContext)

    const { data: citas } = useGetCitasEspecialista({
        idEspecialista: especialidad?.idEspecialista,
        estado: 'pendiente'
    })

    const comboCitas = citas?.data.map((cita, index) => {
        const { nombres, apPaterno, apMaterno } = cita.paciente.persona
        const denominacion = `${nombres} ${apPaterno} ${apMaterno}`.toUpperCase()
        return {
            value: cita.idCita,
            denominacion,
        }
    })

    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(TriajeSchema),
        defaultValues: {
            presionArterial: '',
            temperatura: '',
            frecuenciaRespiratoria: '',
            // nroDocPaciente: '',
            frecuenciaCardiaca: '',
            saturacion: '',
            peso: '',
            talla: '',
        }
    })
    const [fullWidth] = useState(true)
    const [maxWidth] = useState('md')
    const onSubmit = data => {
        const { idCita } = data
        const cita = citas.data.find(cita => cita.idCita === idCita)
        const { dni } = cita.paciente.persona

        const formData = {
            idCita: idCita,
            presionArterial: data.presionArterial,
            temperatura: data.temperatura,
            frecuenciaRespiratoria: data.frecuenciaRespiratoria,
            nroDocPaciente: dni,
            frecuenciaCardiaca: data.frecuenciaCardiaca,
            saturacion: data.saturacion,
            peso: data.peso,
            talla: data.talla,
            imc: (data.peso) / ((data.talla / 100) ** 2),
            fechaHora: new Date().toISOString(),
        }
        const msg = 'No se pudo guardar datos del triaje'
        const msg2 = 'Datos guardados correctamente'
        mutate(formData, {
            onError: () => {
                showError(msg)
            },
            onSuccess: () => {
                handleClose()
                showSuccess(msg2)
                reset()
            }
        })
    }

    return (
        <>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer'
                    }}
                >
                    {'AÑADIR TRIAJE'}
                    <CloseRoundedIcon
                        onClick={() => {
                            handleClose()
                            reset()
                        }}
                    />
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={3} alignContent='center'>
                            <Grid item xs={12} md={6}>
                                <Input
                                    control={control}
                                    id='idCita'
                                    label='Cita'
                                    placeholder='Seleccione cita'
                                    select
                                    data={comboCitas || []}
                                    type="select"
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>

                                <Input
                                    control={control}
                                    id='nroDocPaciente'
                                    label='NroDocPaciente'
                                    placeholder='Ingrese número de documento paciente '
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.nroDocPaciente?.message}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} md={3}>
                                <Input
                                    control={control}
                                    id='presionArterial'
                                    label='Presión arterial'
                                    placeholder='Ingrese presión arterial'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.presionArterial?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Input
                                    control={control}
                                    id='temperatura'
                                    label='Temperatura'
                                    placeholder='Ingrese temperatura del paciente'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.temperatura?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    control={control}
                                    id='frecuenciaRespiratoria'
                                    label='Frecuencia Respiratoria x minuto'
                                    placeholder='Ingrese la frecuencia respiratoria'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.frecuenciaRespiratoria?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    control={control}
                                    id='frecuenciaCardiaca'
                                    label='Frecuencia Cardiaca x minuto'
                                    placeholder='Ingrese frecuencia cardiaca'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.frecuenciaCardiaca?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Input
                                    control={control}
                                    id='saturacion'
                                    label='Saturación O2'
                                    placeholder='Ingrese saturación'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.saturacion?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Input
                                    control={control}
                                    id='peso'
                                    label='Peso Kg'
                                    placeholder='Ingrese peso del paciente'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.peso?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Input
                                    control={control}
                                    id='talla'
                                    label='Talla cm'
                                    placeholder='Ingrese talla del paciente'
                                    type='number'
                                />
                                <Typography
                                    variant='subtitle2'
                                    align='left'
                                    color='red'
                                >
                                    {errors.talla?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                >
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
                            Cancelar
                        </Button>
                        <LoadingButton
                            loading={isLoading}
                            type='submit'
                            loadingPosition='start'
                            startIcon={<SaveIcon />}
                            variant='contained'
                        >
                            GUARDAR
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MedicalInformationRoundedIcon from '@mui/icons-material/MedicalInformationRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import { useContext } from 'react';
import { UserContext } from '../../context/userContext';
import EventRoundedIcon from '@mui/icons-material/EventRounded';

const ROLES = {
  'medico': [
    {
      path: '.',
      name: 'Inicio',
      icon: HomeRoundedIcon,
      toolTip: 'Inicio',
    },
    {
      path: 'citasatendidas',
      name: 'Citas Atendidas',
      icon: EventAvailableRoundedIcon,
      toolTip: 'Citas Atendidas',
    },
    {
      path: 'nuevasconsultas',
      name: 'Nuevas Consultas',
      icon: TodayRoundedIcon,
      toolTip: 'Nuevas Consultas',
    },
    {
      path: 'pacientes',
      name: 'Pacientes',
      icon: SickRoundedIcon,
      toolTip: 'Pacientes'
    },
    {
      path: 'horarios',
      name: 'Horarios',
      icon: CalendarMonthRoundedIcon,
      toolTip: 'Horarios',
    },
    // {
    //   path: 'planatencion',
    //   name: 'Plan atención',
    //   icon: LocalHospitalRoundedIcon,
    //   toolTip: 'Plan atención',
    // },
    {
      path: 'generarcitamedico',
      name: 'Generar Cita',
      icon: EventRoundedIcon,
      toolTip:'Generar Cita'
    },
  ],
  'enfermera': [
    {
      path: '.',
      name: 'Inicio',
      icon: HomeRoundedIcon,
      toolTip: 'Inicio',
    },
    {
      path: 'triajesatendidos',
      name: 'Triajes Atendidos',
      icon: EventAvailableRoundedIcon,
      toolTip: 'Triajes Atendidos',
    },
    {
      path: 'nuevostriajes',
      name: 'Nuevos Triajes',
      icon: TodayRoundedIcon,
      toolTip: 'Nuevos Triajes',
    },
    {
      path: 'triaje',
      name: 'Triaje',
      icon: MedicalInformationRoundedIcon,
      toolTip: 'Triaje',
    },
    {
      path: 'generarcitaenfermera',
      name: 'Generar Cita',
      icon: EventRoundedIcon,
      toolTip:'Generar Cita'
    },
  ]
}

export function EspecialistaNavConfig() {
  const { user } = useContext(UserContext)

  const rol = 'medico'
  // const rol = 'enfermera'

  return {
    links: ROLES[rol]
  }
}

import { Routes, Route } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import Inicio from './inicio'
import { EspecialistaNavConfig } from './layoutEspecialista'
import { ModuloPsicologia } from './ModuloPsicologia'
import { CitasAtendidas } from './CitasAtendidas'
import { NuevasConsultas } from './NuevasConsultas'
import { Pacientes } from './Pacientes'
import { Odontologia } from './Odontologia'
import { ModuloNutricion } from './ModuloNutricion'
import { Horarios } from './Horarios'
import { AtencionTriaje } from './AtencionTriaje'
import { PlanAtencion } from './PlanAtencion'
import { AtencionIntegral } from './AtencionIntegral'
import { NuevosTriajes } from './NuevosTriajes'
import { TriajesAtendidos } from './TriajesAtendidos'
import { GenerarCitaEnfermera } from './GenerarCita/indexEnfermera'
import { GenerarCitaMedico } from './GenerarCita/indexMedico'
import AtencionOdontologica from './Odontologia/AtencionOdontologica'
import EvaluacionOdontologica from './Odontologia/EvaluacionOdontologica'
import { FormEvaluacion } from './ModuloPsicologia/components/FormEvaluacion'
import { FormFichaPersonalEstudiante } from './ModuloPsicologia/components/FormFichaPersonalEstudiante'
import { FormHistoriaClinicaPsicologia } from './ModuloPsicologia/components/FormHistoriaClinicaPsicologia'
import { FormHistoriaClinica } from './ModuloNutricion/components/FormHistoriaClinica'
import { FormRecordatorio } from './ModuloNutricion/components/FormRecordatorio'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { SolicitarCitaAPaciente } from '../Admini/SolicitarCitaAPaciente'

const EspecialistaDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={EspecialistaNavConfig} />}>
        <Route path='/inicio' element={<ProtectedRoute />} >
          <Route index element={<Inicio />} />
        </Route>
        <Route path='modulopsicologia' element={<ProtectedRoute />}>
          <Route index element={<ModuloPsicologia />} />
        </Route>
        <Route path='/modulopsicologia/evaluacion-psicologica' element={<ProtectedRoute />}>
          <Route index element={<FormEvaluacion />} />
        </Route>
        <Route path='/modulopsicologia/ficha-personal-estudiante' element={<ProtectedRoute />}>
          <Route index element={<FormFichaPersonalEstudiante />} />
        </Route>
        <Route path='/modulopsicologia/hc-psicologica' element={<ProtectedRoute />}>
          <Route index element={<FormHistoriaClinicaPsicologia />} />
        </Route>
        <Route path='medicinageneral' element={<ProtectedRoute />}>
          <Route index element={<AtencionIntegral />} />
        </Route>
        <Route path='modulonutricion' element={<ProtectedRoute />}>
          <Route index element={<ModuloNutricion />} />
        </Route>
        <Route path='modulonutricion/historia-clinica-nutricional' element={<ProtectedRoute />}>
          <Route index element={<FormHistoriaClinica />} />
        </Route>
        <Route path='modulonutricion/recordatorio-24horas' element={<ProtectedRoute />}>
          <Route index element={<FormRecordatorio />} />
        </Route>
        <Route path='citasatendidas' element={<ProtectedRoute />}>
          <Route index element={<CitasAtendidas />} />
        </Route>
        <Route path='nuevasconsultas' element={<ProtectedRoute />}>
          <Route index element={<NuevasConsultas />} />
        </Route>
        <Route path='pacientes' element={<ProtectedRoute />}>
          <Route index element={<Pacientes />} />
        </Route>
        <Route path='odontologia' element={<ProtectedRoute />}>
          <Route index element={<Odontologia />} />
          <Route path='atencion-odontologica' element={<AtencionOdontologica />} />
          <Route path='evaluacion-odontologica' element={<EvaluacionOdontologica />} />
        </Route>
        <Route path='atencion-odontologica' element={<ProtectedRoute />}>
          <Route index element={<AtencionOdontologica />} />
        </Route>
        <Route path='evaluacion-odontologica' element={<ProtectedRoute />}>
          <Route index element={<EvaluacionOdontologica />} />
        </Route>
        <Route path='horarios' element={<ProtectedRoute />}>
          <Route index element={<Horarios />} />
        </Route>
        <Route path='triaje' element={<ProtectedRoute />}>
          <Route index element={<AtencionTriaje />} />
        </Route>
        <Route path='planatencion' element={<ProtectedRoute />}>
          <Route index element={<PlanAtencion />} />
        </Route>
        <Route path='atencionintegral' element={<ProtectedRoute />}>
          <Route index element={<AtencionIntegral />} />
        </Route>
        <Route path='nuevostriajes' element={<ProtectedRoute />}>
          <Route index element={<NuevosTriajes />} />
        </Route>
        <Route path='triajesatendidos' element={<ProtectedRoute />}>
          <Route index element={<TriajesAtendidos />} />
        </Route>
        <Route path='generarcitaenfermera' element={<ProtectedRoute />}>
          <Route index element={<GenerarCitaEnfermera />} />
        </Route>
        <Route path='generarcitamedico' element={<ProtectedRoute />}>
          <Route index element={<GenerarCitaMedico />} />
        </Route>
        <Route path='solicitar-cita-paciente' element={<ProtectedRoute />}>
          <Route index element={<SolicitarCitaAPaciente />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default EspecialistaDashboard

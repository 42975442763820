import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import ButtonAdd from '../../../../components/ButtonAdd'
import Input from '../../../../components/Input'
import { Switch } from '../../../../components/Switch'
import { useAlertMessage } from '../../../../context/notifications'
import { useGetByIdEspecialidad } from '../../../../hooks/useEspecialidades'
import { useEffect } from 'react'

const EspecialidadSchema = yup.object({
  desEspecialidad: yup
    .string()
    .required('* Campo requerido'),
  abrevEspecialidad: yup
    .string()
    .max(10, 'máxima longitud de caracteres es 10')
    .required('* Campo requerido'),
  periodoIteracion: yup
    .string()
    .required('* Campo requerido'),
  requiereTriaje: yup
    .boolean()
    .required('* Campo requerido'),
})

const FormEspecialidad = ({ open, handleClose, edit, id, mutate, isLoading }) => {

  const { data: especialidad } = useGetByIdEspecialidad(id) || []
  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EspecialidadSchema),
    defaultValues: {
      desEspecialidad: especialidad?.desEspecialidad || '',
      abrevEspecialidad: especialidad?.abrevEspecialidad || '',
      periodoIteracion: especialidad?.periodoIteracion || 0,
      requiereTriaje: especialidad?.requiereTriaje || 0,
    }
  })

  useEffect(() => {
    if (edit) { reset(especialidad ? especialidad[0] : {}) }
  }, [especialidad])

  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { showError, showSuccess } = useAlertMessage()

  const onSubmit = data => {
    const formData = {
      desEspecialidad: data.desEspecialidad,
      abrevEspecialidad: data.abrevEspecialidad,
      periodoIteracion: data.periodoIteracion,
      requiereTriaje: Number(data.requiereTriaje),
      estado: data?.estado
    }
    const msg = edit ? 'No se pudo editar Especialidad' : 'No se pudo crear Especialidad'
    const msg2 = edit
      ? 'Especialidad editado correctamente'
      : 'Especialidad creado correctamente'

    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR ESPECIALIDAD' : 'AÑADIR ESPECIALIDAD'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información básica de la Especialidad
            </DialogContentText>
            <Grid container spacing={3} alignContent='center'>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='desEspecialidad'
                  label='Especialidad'
                  placeholder='Ingrese especialidad'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.desEspecialidad?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='abrevEspecialidad'
                  label='Abreviación especialidad'
                  placeholder='Ingrese abreviación de especialidad'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.abrevEspecialidad?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='periodoIteracion'
                  label='Tiempo por cita'
                  placeholder='Ingrese tiempo por cita'
                  type='number'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.periodoIteracion?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  !edit && (
                    <Switch
                      id='requiereTriaje'
                      name='requiereTriaje'
                      label='Requiere triaje'
                      defaultChecked
                      control={control}
                    />
                  )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()

              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormEspecialidad
import { useCreatePaciente } from '../../../../hooks/usePacientes'
import FormPaciente from './PacienteForm'

const CreatePaciente = () => {
  const { mutate, isLoading } = useCreatePaciente()

  return (
    <>
      <FormPaciente edit={false} mutate={mutate} data={''} isLoading={isLoading} />
    </>
  )
}

export default CreatePaciente

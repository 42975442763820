import { useCreateUsuario } from '../../../../hooks/useUsuarios'
import FormUsuarios from './FormUsuarios'
const CreateUsuarios = () => {
    const { mutate, isLoading } = useCreateUsuario()
    return (
        <>
            <FormUsuarios edit={false} id={''} mutate={mutate} isLoading={isLoading} />
        </>
    )
}
export default CreateUsuarios
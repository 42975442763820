import {
  Button,
  Dialog, DialogActions,
  // DialogTitle,
  DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Switch, Tooltip
} from '@mui/material'
import { useState } from 'react'
import { useAlertMessage } from '../../../../context/notifications'
import { useUpdateEstadoAcceso } from '../../../../hooks/useAccesos'

export default function UpdateEstado({ id, data }) {
  const { mutate } = useUpdateEstadoAcceso(id)
  const checked = Boolean(data?.estadoRegistro)
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const confirmState = () => {
    // console.log(Number(checked), Number(!checked), data)
    const formData = {
      estadoRegistro: Number(!checked)
    }
    const msg = 'No se pudo cambiar el estado'
    const msg2 = 'Se cambió el estado correctamente'

    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
      }
    })
    handleClose()
  }
  return (
    <>
      <Tooltip title='Editar estado'>
        <FormGroup
          onClick={handleClickOpen}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                name='checkedB'
                color='primary'
              />
            }
          />
        </FormGroup>
      </Tooltip>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cambiar estado</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <b>{`¿Está seguro que quiere cambiar el estado de ${data?.nombreEtiqueta}?`}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            CANCELAR
          </Button>
          <Button variant='contained' onClick={confirmState}>
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

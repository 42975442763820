import axios from 'axios'
import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/especialista`)
  return data
}

export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialista] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/especialista/${idEspecialista}`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/especialista`, body)
  return data
}

export const GetByDni = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, dni] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/especialista/especialista/${dni}`)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put(
    ` ${fullPath}/bienestar/especialista/${id}`, body
  )
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.delete(
    ` ${fullPath}/bienestar/especialista/${id}`
  )
  return data
}

export const GenerarHorario = async id => {
  const { full_path: fullPath } = usePath()

  const { data: isDeleted } = await axiosClient.delete(
    ` ${fullPath}/bienestar/especialista/eliminar-todo-horario/${id}`
  )

  if (isDeleted) {
    const { data } = await axiosClient.post(
      ` ${fullPath}/bienestar/especialista/generar-horario/${id}`
    )
    return data
  }
}

export const DeleteHorario = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.delete(
    ` ${fullPath}/bienestar/especialista/eliminar-todo-horario/${id}`
  )
  console.log({ data })
  return data
}
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetById, GetAll, Update, GenerarHorario, GetByDni } from '../services/especialistas'

const key = 'especialistas'

export const useGetAllEspecialista = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useCreateEspecialista = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGetByIdEspecialista = (idEspecialista, enabled = true) =>
  useQuery([key, idEspecialista], GetById, {
    enabled
  })

export const useGetByDniEspecialista = (dni, enabled = true) =>
  useQuery([key, dni], GetByDni, {
    enabled
  })

export const useUpdateEspecialista = idEspecialista => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idEspecialista, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeleteEspecialista = idEspecialista => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(idEspecialista), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGenerarHorario = () => {
  const queryClient = useQueryClient()

  return useMutation((id) => GenerarHorario(id), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
// useQuery([key, idEspecialista], GenerarHorario, {
//   enabled
// })



import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.get (`${fullPath}/bienestar/perfil`)
    return data
}

export const GetById = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, id] = queryKey
    const { data: [ response ] } = await axiosClient.get (`${fullPath}/bienestar/perfil/${id}`)
    return response
}

export const Create = async body => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.post (`${fullPath}/bienestar/perfil`, body)
    return data
}

export const Update = async (id, body) => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.put (`${fullPath}/bienestar/perfil/${id}`, body)
    return data
}

export const DeleteElement = async id => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.delete (`${fullPath}/bienestar/perfil/${id}`)
    return data
}
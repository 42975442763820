import React, { useContext, useEffect, useState } from 'react'
import ContentMainBox from '../../../components/MainBox'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useCreateCita, useGetCitasByIdEspecialista } from '../../../hooks/useCitas'
import { useAlertMessage } from '../../../context/notifications'
import { useGetAllHorarioDetalle } from '../../../hooks/useHorarioDet'
import { useGetPacienteByNroDoc } from '../../../hooks/usePacientes'
import { UserContext } from '../../../context/userContext'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const CitaSchema = yup.object({
  nroDocPaciente: yup
    .string()
    .length(8, 'Debe tener 8 dígitos')
    .required('Campo requerido'),
  fechaCita: yup
    .date()
    .required('Campo requerido'),
})

export const GenerarCitaMedico = () => {

  const { showError, showSuccess } = useAlertMessage()
  const { mutate } = useCreateCita()

  const { user } = useContext(UserContext)
  const especialista = user.idEspecialista

  const [paciente, setPaciente] = useState()
  const [nroDocPaciente, setNroDocPaciente] = useState('')
  const [horarioDet, setHorarioDet] = useState()
  // var date = new Date();
  // date.setUTCHours(-5,0,0,0);
  const date = new Date().toLocaleDateString('sv',{timeZone: 'America/Lima'})
  const [fechaCita, setFechaCita] = useState(date);

  let { data: HorarioDetalle } = useGetAllHorarioDetalle() || []
  let { data: CitasByEsp } = useGetCitasByIdEspecialista(especialista) || []
  const Citas = CitasByEsp ? CitasByEsp : []
  let { data: Paciente, refetch, isSuccess, isError, isLoading } = useGetPacienteByNroDoc(nroDocPaciente, false)

  useEffect(() => {
    setPaciente(Paciente?.idPaciente)
  }, [Paciente?.idPaciente])

  const { handleSubmit, register, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CitaSchema),
    defaultValues: {
      'fechaCita': fechaCita
    }
  })

  const tranformarDia = (fecha) => {
    let diaLetra = new Date(fecha).getDay()
    if (diaLetra === 0) return 'lunes'
    if (diaLetra === 1) return 'martes'
    if (diaLetra === 2) return 'miercoles'
    if (diaLetra === 3) return 'jueves'
    if (diaLetra === 4) return 'viernes'
    else return 'feriado'
  }

  const [dia, setDia] = useState(tranformarDia(fechaCita));
  const [ahora, setAhora] = useState(false)

  const comboHorariosDet = HorarioDetalle
    ? HorarioDetalle.flatMap(({ horarioDetalle_: horarioDet, idEspecialista }) => {
      return horarioDet.map((item) => ({
        horarioDetId: item.idHorarioDet,
        diaHorario: item.dia,
        hora: item.horaIni,
        especialistaId: idEspecialista
      }))
    })
    : [
      {
        horarioDetId: 1,
        diaHorario: 'lunes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
      {
        horarioDetId: 2,
        diaHorario: 'martes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
    ]

  const onSubmit = () => {
    const formData = {
      idPaciente: paciente,
      idEspecialista: especialista,
      idHorarioDet: horarioDet,
      fechaCita: fechaCita,
      estado: 1
    }
    const msg = 'No se pudo crear Cita'
    const msg2 = 'Cita creada correctamente'
    console.log(formData)
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
      }
    })
  }

  const newHorasDisponibles = comboHorariosDet.filter((horarioDet) => {
    if (fechaCita !== '' && especialista !== '') {
      if (horarioDet.diaHorario === dia && horarioDet.especialistaId === especialista) {
        const result = Citas.find((cita) =>
          cita.idHorarioDet === horarioDet.horarioDetId && cita.fechaCita.slice(0, 10) === fechaCita);
        if (result === undefined) return horarioDet
      }
    }
    else {
      return horarioDet
    }
  })

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Solicitar cita
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className='mt-2'
            >
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Nro. Documento'
                      size='small'
                      type='number'
                      InputProps={{
                        readOnly: false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={refetch}>
                              <SearchRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register('nroDocPaciente', {
                        onChange: ({ target: { value } }) => (value.length >= 7) && setNroDocPaciente(value)
                      })}
                      error={!!errors.nroDocPaciente}
                      helperText={!!errors.nroDocPaciente ? errors.nroDocPaciente.message : null}
                    />
                  </Grid>

                  {
                    isSuccess &&
                    <Grid item xs={12} className='ml-4 -mt-1 mb-2' sx={{
                      ...(Paciente.nombres === undefined && { display: 'none' })
                    }}>
                      <span className='font-bold'>{'Paciente: '}</span>
                      <span >{`${Paciente?.nombres} ${Paciente?.apPaterno} ${Paciente?.apMaterno}`}</span>
                    </Grid>
                  }
                  {
                    isLoading &&
                    <Grid item xs={12} className='flex -mt-1 mb-2 items-center gap-2'>
                      <CircularProgress size={15} sx={{ color: '#707070', ml: 2 }} />
                      <span className='text-[#707070] text-sm'>Buscando...</span>
                    </Grid>
                  }
                  {
                    isError &&
                    <Grid item xs={12} className='ml-4 -mt-2 mb-2 text-xs text-[#d32f2f]'>
                      No se encontró el número de documento
                    </Grid>
                  }

                  <Grid item xs={12}>

                    <TextField
                      fullWidth
                      size='small'
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                      value={fechaCita}
                      disabled={!paciente}
                      {...register('fechaCita', {
                        onChange: (e) => {
                          setFechaCita(e.target.value)
                          setDia(tranformarDia(e.target.value))
                        }
                      })}
                      error={!!errors.horarioDet}
                      helperText={!!errors.horarioDet ? errors.horarioDet.message : null}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                    >
                      {(especialista != '' && paciente) ? (newHorasDisponibles.length === 0
                        ? <Typography variant='body1' className='mx-auto'>No hay horarios disponibles para este día</Typography>
                        : newHorasDisponibles.map((option, i) => (
                          <Button
                            sx={{ marginX: 1, marginY: 1 }}
                            onClick={() => setHorarioDet(option.horarioDetId)}
                            variant={horarioDet === option.horarioDetId ? 'contained' : 'outlined'}
                            color='primary'

                            size='large'
                          >
                            {option.hora}
                          </Button>

                        ))) :
                        <></>}
                      {
                        (especialista != '' && paciente) &&
                        ((newHorasDisponibles.length !== 0 && dia !== 'feriado') &&
                          <Grid item xs={2}>
                            <Button
                              size='large'
                              onClick={() => {
                                setAhora(true)
                                setHorarioDet()
                              }}
                              variant={ahora && !horarioDet ? 'contained' : 'outlined'}
                            >
                              Ahora
                            </Button>
                          </Grid>
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color='primary'
                      type='submit'
                      disabled={!paciente || !especialista || !fechaCita || !ahora && !horarioDet}
                    >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { GetAll, Create, GetById, Update } from '../services/horariosDetalle'

const key = 'horarioDetalles'

export const useGetAllHorarioDetalle = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useCreateHorarioDetalle = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGetHorarioDetalleById = (idHorario, enabled = true) =>
  useQuery([key, idHorario], GetById, {
    enabled
  })

export const useUpdateHorarioDetalle = (id) => {
  const queryClient = useQueryClient()

  return useMutation((body) => Update(id, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
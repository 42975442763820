import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const Input = React.forwardRef((props, ref) => {
  const { control, id, label, disabled = false, placeholder, multiline = false, rows = 1, type = 'text', data = [], inputProps = null, error = false, helperText = '', defaultValue = '', valorChancado } = props
  return (type === 'select'
    ? <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          label={label}
          value={value}
          onChange={onChange}
          variant="outlined"
          size="small"
          select
          fullWidth
          placeholder={placeholder}
          InputProps={inputProps}
          error={error}
          helperText={helperText}
          disabled={disabled}
        >
          {data.map((elemento, index) =>
            <MenuItem key={index} value={elemento.value}>{elemento.denominacion}</MenuItem>)}
        </TextField>
      )}
    />
    : <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          label={label}
          value={valorChancado ? valorChancado : value}
          onChange={onChange}
          variant="outlined"
          size="small"
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          placeholder={placeholder}
          fullWidth
          type={type}
          InputProps={inputProps}
          error={error}
          helperText={helperText}
          ref={ref}
        />
      )}
    />)
})


export const InputDate = React.forwardRef((props, ref) => {
  const { control, id, defaultValue, ...rest } = props
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          ref={ref}
          {...field}
          {...rest}
        />
      )}
    />
  )
})

export default Input

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, GetById, Update } from '../services/perfiles'

const key = 'Perfiles'

export const useGetAllPerfiles = (enabled = true) =>
    useQuery(key, GetAll, {
        enabled
    })

export const useGetByIdPerfil = (idPerfil, enabled = true) =>
    useQuery([key, idPerfil], GetById, {
        enabled
    })

export const useCreatePerfil = () => {
    const queryClient = useQueryClient()
    return useMutation(Create, {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useUpdatePerfil = idPerfil => {
    const queryClient = useQueryClient()

    return useMutation(body => Update(idPerfil, body), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useDeletePerfil = idPerfil => {
    const queryClient = useQueryClient()

    return useMutation(() => DeleteElement(idPerfil), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}
import { Grid } from '@mui/material'
import { DataGridStyle } from '../../../components/DataGridStyle'
import CreateAccesos from './components/CreateAccesos'
import UpdateAccesos from './components/UpdateAccesos'
import DeleteAccesos from './components/DeleteAccesos'
import Loading from '../../../components/Loading'
import { useGetAllAccesos } from '../../../hooks/useAccesos'
import ContentMainBox from '../../../components/MainBox'
import UpdateEstado from './components/UpdateEstado'

const columns = [
  {
    headerClassName: 'super-app-theme--header ',
    field: 'rol',
    headerName: 'Rol',
    width: 120,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nombreEtiqueta',
    headerName: 'Etiqueta',
    minWidth: 180,
    flex: 0.3,
    type: 'string',
    headerAlign: 'left',
    align: 'left'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'descripcion',
    headerName: 'Descripción',
    minWidth: 180,
    flex: 0.3,
    type: 'string',
    headerAlign: 'left',
    align: 'left'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'ruta',
    headerName: 'Ruta',
    minWidth: 180,
    flex: 0.3,
    type: 'string',
    headerAlign: 'left',
    align: 'left'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'menu',
    headerName: 'Menu',
    width: 120,
    type: 'string',
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => {
      const valor = params.value;
      return valor === 1 ? 'Si' : 'No';
    },
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estadoRegistro',
    headerName: 'Estado',
    width: 80,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      return <UpdateEstado id={row.idAcceso} data={row} />
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accions',
    width: 160,
    getActions: ({ row }, i) => {
      return [
        <UpdateAccesos id={row.idAcceso} key={i} />,
        <DeleteAccesos id={row.idAcceso} key={i} />
      ]
    },
  }
]

export const Accesos = () => {
  const { data: accesos, isLoading } = useGetAllAccesos()
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              Accesos
            </Grid>
            <Grid item>
              <CreateAccesos />
            </Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={380}
                  rows={accesos || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.idAcceso}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}


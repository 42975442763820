import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetByIdPerfil = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, perfilCodigo] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/listamenu/${perfilCodigo}`)
  return data
}

export const Create = async listaMenu => {
  try {
    const data = await Promise.all(listaMenu.map(async (item) => {
      const { full_path: fullPath } = usePath()
      const { data } = await axiosClient.post (`${fullPath}/bienestar/listamenu`, item)
      if (data.error) {
        const { data } = await axiosClient.put (`${fullPath}/bienestar/listamenu
        ?PerfilCodigo=${item.perfilCodigo}
        &MenuCodigo=${item.menuCodigo}
        &NivelAcceso=${item.nivelAcceso}`)
        console.log('UDPATE', data)
        return data
      }
      return data
    }))
    console.log('ALL SERVICE', data)
    return data

  } catch (error) {
    console.log(error)
  }
}

// export const Update = async (id, body) => {
//   const { full_path: fullPath } = usePath()
//   const { data } = await axiosClient.put (`${fullPath}/bienestar/listamenu/${id}`, body)
//   return data
// }

// export const UpdateEstado = async (id, body) => {
//   const { full_path: fullPath } = usePath()
//   const { data } = await axiosClient.put (`${fullPath}/bienestar/listamenu/estado/${id}`, body)
//   return data
// }

// export const DeleteElement = async id => {
//   const { full_path: fullPath } = usePath()
//   const { data } = await axiosClient.delete (`${fullPath}/bienestar/listamenu/${id}`)
//   return data.data
// }
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import ArticleIcon from '@mui/icons-material/Article'
import { useNavigate } from 'react-router-dom'

export const ButtonCreateFormatoDetalle = ({ formato }) => {

    const navigate = useNavigate()
    return (
        <Tooltip title='Crear Formato Detalle'>
            <IconButton
                aria-label='crear formatoDetalle'
                onClick={() => navigate(`/administrador/formatodetalles`, { state: formato })}
            >
                <ArticleIcon />
            </IconButton>
        </Tooltip>
    )
}

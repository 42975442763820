import React from 'react'
import ConfirmationModal from './ConfirmationModal'

export const UpdateRequiereTriaje = ({ data }) => {

    return (
        <ConfirmationModal
            title="Cambiar estado de triaje"
            description={`¿Está seguro que quiere cambiar el estado de triaje de ${data.desEspecialidad}?`}
            tooltip="Editar estado de triaje"
            data={data}
            property='requiereTriaje'
        />
    )
}

import { useUpdateFormatoDetalle } from '../../../../hooks/useFormatoDetalles'
import FormFormatoDetalles from './FormFormatoDetalles'

const UpdateFormatoDetalles = ({ id }) => {
  const { mutate, isLoading } = useUpdateFormatoDetalle(id)
  return (
    <>
      <FormFormatoDetalles edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdateFormatoDetalles

import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import MedicalInformationRoundedIcon from '@mui/icons-material/MedicalInformationRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';

export const Icons = {
  HomeRoundedIcon: <HomeRoundedIcon />,
  ClassRoundedIcon: <ClassRoundedIcon />,
  RecentActorsRoundedIcon: <RecentActorsRoundedIcon />,
  SickRoundedIcon: <SickRoundedIcon />,
  DateRangeRoundedIcon: <DateRangeRoundedIcon />,
  EventAvailableRoundedIcon: <EventAvailableRoundedIcon />,
  TodayRoundedIcon: <TodayRoundedIcon />,
  MedicalInformationRoundedIcon: <MedicalInformationRoundedIcon />,
  CalendarMonthRoundedIcon: <CalendarMonthRoundedIcon />,
  EventRoundedIcon: <EventRoundedIcon />,
  ListAltRoundedIcon: <ListAltRoundedIcon />,
  LocalHospitalRoundedIcon: <LocalHospitalRoundedIcon />,
  PeopleRoundedIcon: <PeopleRoundedIcon />,
  AssignmentIndRoundedIcon: <AssignmentIndRoundedIcon />,
  DriveFileMoveRoundedIcon: <DriveFileMoveRoundedIcon />,

  default: <NewReleasesRoundedIcon />
}

export const RolIcon = {
  'administrador': AdminPanelSettingsRoundedIcon,
  'especialista': AccountBoxRoundedIcon,
  'paciente': PersonRoundedIcon,
}
// export const Icons = {
//   "Inicio": <HomeRoundedIcon />,
//   "Especialidades": <ClassRoundedIcon />,
//   "Especialistas": <RecentActorsRoundedIcon />,
//   "Pacientes": <SickRoundedIcon />,
//   "Citas": <DateRangeRoundedIcon />,
//   "Citas Atendidas": <EventAvailableRoundedIcon />,
//   "Nuevas Consultas": <TodayRoundedIcon />,
//   "Triajes Atendidos": <EventAvailableRoundedIcon />,
//   "Nuevos Triajes": <TodayRoundedIcon />,
//   "Triaje": <MedicalInformationRoundedIcon />,
//   "Horarios": <CalendarMonthRoundedIcon />,
//   "Generar Cita": <EventRoundedIcon />,
//   "Mis Citas": <ListAltRoundedIcon />,
//   "Perfil Clinico": <LocalHospitalRoundedIcon />,
//   "Solicitar Cita": <EventRoundedIcon />,
// }

import axios from 'axios'
import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'


export const GetAll = async (body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/Triaje`, body)
  return data
}
export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idTriaje] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/Triaje/${idTriaje}`)
  return data
}

export const GetByNroDoc = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, NroDoc] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/Triaje/Paciente/${NroDoc}`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/Triaje`, body)

  return data
}
export const Update = async body => {
  const {frecuenciaCardiaca, frecuenciaRespiratoria, ...rest} = body
  const formData = {
    ...rest,
    fechaHora: new Date(),
    frecuencia_cardiaca: frecuenciaCardiaca,
    frecuencia_respiratoria: frecuenciaRespiratoria
  }
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/Triaje/${body.id}`, formData)
  return data
}
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, Filter, GetAll, GetById, GetByNroDoc, Update, search } from '../services/pacientes'

const key = 'pacientes'

export const useGetAllPaciente = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetByIdPaciente = (idPaciente, enabled = true) =>
  useQuery(key, GetById(idPaciente), {
    enabled
  })

export const useGetPacienteByNroDoc = (NroDoc, enabled = true) =>
  useQuery([key, NroDoc], GetByNroDoc, {
    enabled
  })

export const useSearchPacientes = (searchParams, enabled = true) =>
  useQuery([key, searchParams], search, {
    enabled,
    select: (data) => data.map(({ persona, ...paciente }) => {
      return {
        id: paciente?.idPaciente,
        nroDoc: persona?.dni,
        tipoPaciente: paciente.tipoPaciente,
        paciente: `${persona.apPaterno} ${persona.apMaterno} ${persona.nombres}`,
        ...paciente,
        ...persona,
      }
    })
  })

export const useCreatePaciente = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdatePaciente = idPaciente => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idPaciente, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeletePaciente = idPaciente => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(idPaciente), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useFilterPacientes = (search, enabled = true) =>
  useQuery([key, search], Filter, {
    enabled
  })

export function groupAccesos(data) {
  const groupedByRole = {}
  data?.forEach((item) => {
    const { rol } = item
    if (!groupedByRole[rol]) {
      groupedByRole[rol] = [];
    }
    groupedByRole[rol].push(item)
  });
  return groupedByRole
}
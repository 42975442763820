import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import './App.css';
import AdminDashboard from './pages/Admini';
import EspecialistaDashboard from './pages/Especialista';
import Login from './pages/Login';
import PacienteDashboard from './pages/Paciente';
import { ProtectedRoute } from './components/ProtectedRoute';
import { SolicitarCita, SolicitarCitaExterno } from './pages/Libre/SolicitarCita'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path="/login" element={<Login />} />
        <Route path="/solicitarcitaexterno" element={<SolicitarCitaExterno />} />
        <Route path="/" element={<ProtectedRoute />}
        >
          <Route path="/especialista/*" element={<EspecialistaDashboard />} />
          <Route path="/paciente/*" element={<PacienteDashboard />} />
          <Route path="/administrador/*" element={<AdminDashboard />} />
        </Route>
        
    </>
  )
)

function App() {
  return <RouterProvider router={router} />
}

export default App

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../components/Input'
import ContentMainBox from '../../../components/MainBox'
import { useAlertMessage } from '../../../context/notifications'
import {
  useCreateCita,
  useGetHorariosDisponibles
} from '../../../hooks/back-clinica/useCitas'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import { useGetAlumnoODocenteByDni } from '../../../hooks/back-clinica/useTutoria'

const SemestreSchema = yup.object({
  // pacienteId: yup.number().required('Campo requerido'),
  especialidadId: yup.number().required('Campo requerido'),
  especialistaId: yup.number().required('Campo requerido'),
  // horarioDetId: yup.number().required('Campo requerido'),
  fechaCita: yup.date('Ingresar una fecha válida').required('Campo requerido')
})

export const SolicitarCitaAPaciente = () => {
  const { showError, showSuccess } = useAlertMessage()
  const { mutate } = useCreateCita()

  let { data: Especialistas } = useGetAllEspecialista() || []
  let { data: Especialidades } = useGetAllEspecialidad() || []

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
    register,
    setValue
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SemestreSchema),
    defaultValues: {
      tipoPersona: '',
      dniPaciente: '',
      motivoConsulta: '',
      especialidadId: '',
      especialistaId: '',
      fechaCita: new Date().toLocaleDateString('sv', {
        timeZone: 'America/Lima'
      })
      // pacienteId: PACIENTE_ID,
      // horarioDetId: '',
    }
  })

  // console.log("idPaciente", watch("dniPaciente"))
  const { data: horariosDisponibles, refetch } = useGetHorariosDisponibles(
    {
      fechaCita: watch('fechaCita'),
      idEspecialista: watch('especialistaId')
    },
    false
  )

  const comboEspecialistas = Especialistas
    ? Especialistas.map(especialista => {
        return {
          value: especialista.idEspecialista,
          especialistaId: especialista.idEspecialista,
          denominacion: `${especialista.persona.nombres} ${especialista.persona.apPaterno} ${especialista.persona.apMaterno}`,
          especialidadId: especialista.idEspecialidad
        }
      }).filter(
        especialista => especialista.especialidadId === watch('especialidadId')
      )
    : []

  const comboEspecialidades = Especialidades
    ? Especialidades.map(especialidad => {
        return {
          value: especialidad.idEspecialidad,
          denominacion: especialidad.desEspecialidad
        }
      })
    : []

  const onSubmit = data => {
    const formData = {
      idPaciente: dataPaciente.idPaciente,
      motivoConsulta: data.motivoConsulta,
      idEspecialista: data.especialistaId,
      // idHorarioDet: horarioDet.idHorarioDet,
      idHorarioDet: null,
      fechaCita: data.fechaCita
    }

    const msg = 'No se pudo crear Cita'
    const msg2 = 'Cita creada correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        reset()
        showSuccess(msg2)
      }
    })
  }
  // const ESPECIALISTA = watch('especialistaId')

  const [dniPaciente, setDniPaciente] = useState(null)
  console.log('dniPaciente', dniPaciente)

  const {
    data: dataPaciente,
    refetch: refetchPaciente,
    isError,
    isLoading
  } = useGetAlumnoODocenteByDni(
    { dni: dniPaciente, tipoPersona: watch('tipoPersona') },
    false
  )
  console.log('data', dataPaciente, isLoading)

  const handleOnChangeDni = e => {
    console.log('e.target.value', e.target.value)
    if (e.target.value.length === 8) {
      setDniPaciente(e.target.value)
    }
  }

  useEffect(() => {
    if (dniPaciente) {
      refetchPaciente()
    }
  }, [dniPaciente])

  useEffect(() => {
    refetch()
  }, [watch('especialistaId'), watch('fechaCita')])

  // useEffect(() => {
  //   setHorarioDet(null)
  // }, [watch('fechaCita')])

  useEffect(() => {
    setValue('especialistaId', '')
    setValue(
      'fechaCita',
      new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' })
    )
  }, [watch('especialidadId')])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>Solicitar cita</div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent='center'
              alignItems='center'
              alignContent='center'
              wrap='wrap'
              className='mt-2'
            >
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='tipoPersona'
                      label='Tipo'
                      size='small'
                      type='select'
                      data={[
                        { value: 'alumno', denominacion: 'Alumno' },
                        { value: 'docente', denominacion: 'Docente' }
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='DNI del paciente'
                      size='small'
                      disabled={watch('tipoPersona') === '' ? true : false}
                      error={isError}
                      helperText={isError ? 'No se encontró al paciente' : null}
                      {...register('dniPaciente', {
                        onChange: handleOnChangeDni
                      })}
                    />
                    {/* <Input
                      control={control}
                      fullWidth
                      id='dniPaciente'
                      label='DNI del paciente'
                      size='small'
                      disabled={watch('tipoPersona') === '' ? true : false}
                      onChange = {handleOnChangeDni}
                    /> */}
                    {isLoading && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>Buscando paciente...</p>
                        <CircularProgress
                          style={{ height: '20px', width: '20px' }}
                        />
                      </div>
                    )}
                    {dataPaciente && (
                      <div>
                        <span
                          style={{ fontWeight: 'bold', marginRight: '10px' }}
                        >
                          Paciente:
                        </span>
                        <span>
                          {dataPaciente?.nombres} {dataPaciente?.apPaterno}{' '}
                          {dataPaciente?.apMaterno}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      multiline
                      rows={2}
                      id='motivoConsulta'
                      label='Motivo Consulta'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='especialidadId'
                      label='Especialidad'
                      size='small'
                      type='select'
                      data={comboEspecialidades}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='especialistaId'
                      label='Especialista'
                      size='small'
                      type='select'
                      data={comboEspecialistas}
                      disabled={watch('especialidadId') === '' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                      size='small'
                      disabled={watch('especialistaId') === '' ? true : false}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      alignContent='center'
                      wrap='wrap'
                      gap={1}
                    >
                      {ESPECIALISTA != '' ? (
                        horariosDisponibles?.length === 0 ? (
                          <Typography variant='body1' className='mx-auto'>
                            No hay horarios disponibles para este día
                          </Typography>
                        ) : (
                          horariosDisponibles?.map((option, i) => (
                            <Button
                              onClick={() => setHorarioDet(option)}
                              variant={
                                horarioDet?.idHorarioDet === option.idHorarioDet
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color='primary'
                              size='large'
                            >
                              {option.horaIni}
                            </Button>
                          ))
                        )
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      fullWidth
                      disabled={!isValid}
                    >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

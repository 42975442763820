import { axiosClient, axiosClinicaClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/paciente`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/paciente`, body)

  return data
}

export const GetById = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/paciente/${id}`)

  return data
}

export const GetByNroDoc = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, NroDoc] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/paciente/idPaciente/${NroDoc}`)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/paciente/${id}`, body)
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.delete (`${fullPath}/bienestar/paciente/${id}`)
  return data.data
}

export const Filter = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, value] = queryKey

  if (value.length < 5) {
    return []
  }
  const { data } = await axiosClient.get (`${fullPath}/bienestar/paciente/filtro/dni_nombre?Valor=${value}`)
  // const { data } = await axiosClient.get(`https://www.balldontlie.io/api/v1/players`)
  return data
}

// ---------------

export const search = async ({queryKey}) => {
  const [,searchParams] = queryKey
  const query = new URLSearchParams(searchParams).toString()
  const { data } = await axiosClinicaClient.get (`/mantenimiento/pacientes/buscar?${query}`)
  return data
}
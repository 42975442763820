import { useUpdatePaciente } from '../../../../hooks/usePacientes'
import FormPaciente from './PacienteForm'

const UpdatePaciente = ({ data }) => {
  const { mutate } = useUpdatePaciente(data.id)
  return (
    <>
      <FormPaciente edit={true} data={data} mutate={mutate} />
    </>
  )
}

export default UpdatePaciente

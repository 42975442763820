function getTimeRange(startTime, endTime, interval) {
    let [startHour, startMinutes] = startTime.split(":").map(Number)
    let [endHour, endMinutes] = endTime.split(":").map(Number)
    let timeRange = []
    let intervalParts = interval.split(":")
    let intervalHour = parseInt(intervalParts[0])
    let intervalMinutes = parseInt(intervalParts[1])

    while (startHour < endHour) {
        timeRange.push({
            horaInicio: formartHour(startHour, startMinutes),
            horaFin: ''
        })
        startHour += intervalHour
        startMinutes += intervalMinutes
        if (startMinutes >= 60) {
            startHour++
            startMinutes = startMinutes - 60
        }
        if (startHour === endHour) {
            if (startMinutes > endMinutes) {
                break
            }
        }
    }

    timeRange = timeRange.map((item, index) => {
        if (index === timeRange.length - 1) {
            item.horaFin = formartHour(endHour, endMinutes)
        } else {
            item.horaFin = timeRange[index + 1].horaInicio
        }
        return item
    })
    return timeRange
}

function formartHour(intervaloHoras, minutes) {
    const formartHour = (intervaloHoras < 10 ? '0' : '') + intervaloHoras + ':' + (minutes < 10 ? '0' : '') + minutes
    return formartHour
}

function timeConverter(min) {
    let intervaloHoras = Math.floor(min / 60);
    min = min % 60;
    let output = `${intervaloHoras.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
    return output;
}

export const generarHorario = ({ horaInicio, horaFin, intervalo, fechaInicio, fechaFin }) => {
    const rangoHoras = getTimeRange(horaInicio, horaFin, timeConverter(intervalo))
    const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
    const validDays = getValidDays(fechaInicio, fechaFin)
    const horario = []
    dias.forEach(dia => {
        rangoHoras.forEach(hora => {
            horario.push({
                diaHorario: dia,
                horaInicio: hora.horaInicio,
                horaFin: hora.horaFin,
                estado: validDays.includes(dia) ? 1 : 0
            })
        })
    }
    )
    return horario
}

export const debounce = (func) => {
    let timer
    return function (...args) {
        const context = this
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            func.apply(context, args)
        }, 500)
    }
}

export function getValidDays(inicio, fin) {
    const fechaInicio = new Date(inicio)
    const fechaFin = new Date(fin)
    const diff = fechaFin.getTime() - fechaInicio.getTime()
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
    if (diffDays < 7) {
        const DAYS = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
        let days = []
        for (let i = 0; i <= diffDays; i++) {
            const newDate = (new Date(fechaInicio.getTime() + (i * 24 * 60 * 60 * 1000)))
            const day = DAYS[newDate.getDay()]
            days.push(day)
        }
        return days.filter(e => e != null)
    } else {
        return ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
    }
}
import { Box, ButtonGroup, IconButton, Input, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import DataGridListMobile from './DataGridListMobile'
import ListRoundedIcon from '@mui/icons-material/ListRounded'
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded'
export const DataGridStyle = ({
    height,
    rows,
    columns,
    experimentalFeatures,
    checkboxSelection = false,
    setAlumnosSeleccionados,
    getRowId
}) => {
    const [table, setTable] = useState(true)
    const handleClick = (value) => {
        setTable(value)
    }
    return (
        <>
            <Box
                sx={{
                    height: height,
                    width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: '#99D3D6',
                    },
                    ...(!table && { display: 'none' }),
                }}
            >
                {checkboxSelection
                    ? <DataGrid
                        rows={rows}
                        columns={columns}
                        checkboxSelection={checkboxSelection}
                        experimentalFeatures={experimentalFeatures}
                        getRowId={getRowId}
                        onSelectionModelChange={itm => {
                            setAlumnosSeleccionados(itm)
                        }}
                    />
                    : <DataGrid
                        rows={rows}
                        columns={columns}
                        experimentalFeatures={experimentalFeatures}
                        getRowId={getRowId}
                    />}
            </Box>
            <Box
                sx={{
                    height: height,
                    overflow: 'auto',
                    width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: '#99D3D6',
                    },
                    ...(table && { display: 'none' }),
                    border: '1px solid',
                    borderRadius: '10px',
                    borderColor: '#ddd'
                }}
            >
                <DataGridListMobile rows={rows} columns={columns} getRowId={getRowId} />
            </Box>
            <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                sx={{
                    marginY: 2,
                }}
            >
                <Tooltip title="Tabla">
                    <IconButton
                        sx={{
                            color: table && '#217E93',
                        }}
                        onClick={() => handleClick(true)}
                    >
                        <TableChartRoundedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Lista">
                    <IconButton
                        sx={{
                            color: !table && '#217E93',
                        }}
                        onClick={() => handleClick(false)}
                    >
                        <ListRoundedIcon />
                    </IconButton>
                </Tooltip>
            </ButtonGroup>
        </>

    )
}

import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/acceso`)
  return data
}

export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idAcceso] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/acceso/${idAcceso}`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/acceso`, body)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/acceso/${id}`, body)
  return data
}

export const UpdateEstado = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/acceso/estado/${id}`, body)
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.delete (`${fullPath}/bienestar/acceso/${id}`)
  return data.data
}
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import ArticleIcon from '@mui/icons-material/Article'
import { useNavigate } from 'react-router-dom'

export const ButtonCreateFormato = ({ especialidad }) => {

    const navigate = useNavigate()
    return (
        <Tooltip title='Crear Formato'>
            <IconButton
                aria-label='crear formato'
                onClick={() => navigate(`/administrador/formatos`, { state: especialidad })}
            >
                <ArticleIcon />
            </IconButton>
        </Tooltip>
    )
}

import { createContext, useEffect, useState } from "react";
// import { useGetMenu } from "../hooks/useMenu";
import { Icons } from "../components/layout/icons";

export const AccesosContext = createContext();

const AccesosProvider = ({ children }) => {
  const [accesos, setAccesos] = useState([])
  // const { data: accesosCompleto } = useGetMenu()
  // const getMenu = () => {
  //   let addLista = accesosCompleto?.map(item => {
  //     if (item.mostrarEnMenu) {
  //       return {
  //         ...item,
  //         nivelAcceso: _p.filter(subP => subP.menuCodigo === item.menuCodigo).length > 0 ? _p.filter(subP => subP.menuCodigo === item.menuCodigo)[0].listaMenu.nivelAcceso : 0,
  //         lista: [],
  //       }
  //     } else return null
  //   })
  //     .filter(item => item !== null)

  //   let tree = []

  //   for (let i = 0; i < addLista?.length; i++) {
  //     if (addLista[i].nivel === 3) {
  //       // 030106
  //       let parent = addLista.filter(elem => ((elem.menuCodigo.substring(0, 4) === addLista[i].menuCodigo.substring(0, 4)) && (elem.menuCodigo.substring(4, 6) === '00'))).pop()
  //       parent?.lista.push(addLista[i])
  //     } else if (addLista[i].nivel === 2) {
  //       // 030106
  //       let parent = addLista.filter(elem => ((elem.menuCodigo.substring(0, 2) === addLista[i].menuCodigo.substring(0, 2)) && (elem.menuCodigo.substring(2, 6) === '0000'))).pop()
  //       parent?.lista.push(addLista[i])
  //     } else {
  //       tree.push(addLista[i])
  //     }
  //   }
  //   return tree
  // }
  const getMenu = () => {
    return JSON.parse(localStorage.getItem("_p"))
  }

  const getMenuDropdown = () => {
    let menu = getMenu()

    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i]?.lista.length; j++) {
        menu[i].lista[j].lista.filter(elem => (elem.nivelAcceso > 0)).length > 0 ? menu[i].lista[j].nivelAcceso = 1 : 0
      }
      menu[i].lista.filter(elem => (elem.nivelAcceso > 0)).length > 0 ? menu[i].nivelAcceso = 1 : 0
    }
    return menu
  }

  return <AccesosContext.Provider value={{ getMenu, getMenuDropdown }}>{children}</AccesosContext.Provider>
}

export default AccesosProvider;
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { Box, Button, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EspecialidadContext } from '../../../context/especialidadContext'
import { useGetFormatosCita } from '../../../hooks/useAtencionPaciente'
import { UserContext } from '../../../context/userContext'

export const Odontologia = () => {
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita
  const { especialidad } = useContext(UserContext)

  const { data } = useGetFormatosCita({ idEspecialidad: especialidad.idEspecialidad })
  const navigate = useNavigate()

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {
            data?.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: '#f2f4f4',
                    border: '1px solid #9d9d9d',
                    color: '#000',
                    justifyContent: 'flex-start',
                    padding: '15px 30px',
                  }}
                  onClick={() => navigate(`/especialista/odontologia/${item.rutaEspecialidad}`, { state: { ...dataCita, idFormato: item.idFormato } })}
                >
                  {`${index + 1}. ${item?.nombreFormato}`}
                </Button>
              </Grid>
            ))
          }
        </Grid>
        <Button
          sx={{
            position: 'absolute',
            bottom: 40,
            right: 40,
            padding: '10px 50px 10px 40px'
          }}
          variant="outlined"
          startIcon={<KeyboardBackspaceRoundedIcon />}
          onClick={() => navigate(-1)}
        >
          Regresar
        </Button>
      </Box>
    </>
  )
}
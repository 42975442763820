import FormEspecialidad from './FormEspecialidad'
import { useCreateEspecialidad } from '../../../../hooks/useEspecialidades'
import OpenButton from './OpenButton'

const CreateEspecialidad = () => {
  const { mutate, isLoading } = useCreateEspecialidad()

  return (
    <>
      <OpenButton edit={false} id={null} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default CreateEspecialidad

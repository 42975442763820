import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'


export const GetAll = async (body) => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.get (`${fullPath}/bienestar/formatoDetalle`, body)
    return data
}
export const GetById = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, id] = queryKey
    const { data } = await axiosClient.get (`${fullPath}/bienestar/formatoDetalle/${id}`)
    return data[0] || {}
}
export const GetByIdFormato = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, id] = queryKey
    const { data } = await axiosClient.get (`${fullPath}/bienestar/formatoDetalle/formato/${id}`)
    return data || []
}
export const Create = async body => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.post (`${fullPath}/bienestar/formatoDetalle/`, body)

    return data
}
export const Update = async (id, body) => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.put (`${fullPath}/bienestar/formatoDetalle/${id}`, body)
    return data
}
export const DeleteElement = async id => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.delete (`${fullPath}/bienestar/formatoDetalle/${id}`)
    return data.data
}
import SearchIcon from '@mui/icons-material/Search'
import { Box, Chip, CircularProgress, debounce, Grid, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useSearchPacientes } from '../../../hooks/usePacientes'
import CreatePaciente from './components/CreatePaciente'
import DeletePaciente from './components/DeletePaciente'
import UpdatePaciente from './components/UpdatePaciente'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    width: 80,
  },
  {
    headerClassName: 'super-app-theme--header ',
    cellClassName: 'uppercase',
    field: 'nroDoc',
    headerName: 'DNI',
    width: 130,
    type: 'string',

  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'paciente',
    cellClassName: 'uppercase',
    headerName: 'Paciente',
    minWidth: 300,
    flex: 0.5,
    type: 'string',
    headerAlign: 'center',
    align: 'center'
  },
  // {
  //   headerClassName: 'super-app-theme--header',
  //   field: 'escuelaProfesional',
  //   headerName: 'Escuela Profesional',
  //   cellClassName: 'uppercase',
  //   width: 300,
  //   type: 'string',
  //   headerAlign: 'center',
  //   align: 'center'
  // },
  {
    headerClassName: 'super-app-theme--header',
    field: 'tipoPaciente',
    headerName: 'Tipo Paciente',
    width: 150,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => {
      const colors = {
        Administrador: "warning",
        Docente: "success",
        Estudiante: "info",
      }
      return <Chip size="small" color={colors[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={value}
      />
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accions',
    width: 160,
    getActions: ({ row }) => {
      return [
        <UpdatePaciente data={row} />,
        <DeletePaciente data={row} />
      ]
    },
  }
]

export const Pacientes = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchValue = searchParams.get('s') ?? ''
  const { data: pacientes, isLoading } = useSearchPacientes({
    search: searchValue
  })
  const onSearch = (formData) => {
    console.log(formData)
    navigate(`/administrador/pacientes?s=${formData.search}`)
  }

  const { handleSubmit, control } = useForm({
    defaultValues: {
      search: searchValue || '',
    }
  })

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item>Pacientes</Grid>
            <Grid item>
              <CreatePaciente />
            </Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSearch)} className='flex gap-2'>
                <Controller
                  name="search"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="search"
                      placeholder='Buscar paciente por Nombre o DNI'
                      defaultValue={searchValue || ''}
                      onChange={debounce((e) => {
                        field.onChange(e)
                        navigate(`/administrador/pacientes?s=${e.target.value}`)
                      }, 500)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {
                              isLoading ? <Box sx={{ display: 'flex'}}>
                                <CircularProgress color="inherit" size={24}/>
                              </Box> :
                                <SearchIcon />
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid item className='mt-4' xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={380}
                  rows={pacientes || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.id}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TokenProvider from './context/core/TokenContext';
import ThemeContext from './context/theme'
import { EspecialidadProvider } from './context/especialidadContext';
import NotificationsProvider from './context/notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from './context/userContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AccesosProvider from './context/accesosContext';
import { CitaProvider } from './context/citasContext';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

const googleId = process.env.REACT_APP_GOOGLE_AUTH_TOKEN

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <AccesosProvider>
          <EspecialidadProvider>
            <NotificationsProvider>
              <ThemeContext>
                <CitaProvider>
                  <TokenProvider>
                    <GoogleOAuthProvider clientId={googleId}>
                      <App />
                    </GoogleOAuthProvider>
                  </TokenProvider>
                </CitaProvider>
              </ThemeContext>
            </NotificationsProvider>
          </EspecialidadProvider>
        </AccesosProvider>
      </UserProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

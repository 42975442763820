import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { ApplyFilters, AutoCompletePaciente, Filter, FilterButtons, FilterDates, SearchInput, useFilterByEspecialidad, useFilterByMaxDate, useFilterByMinDate, useFilterByPaciente } from '../../../components/Filters'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { UserContext } from '../../../context/userContext'
import { useGetCitas } from '../../../hooks/useCitas'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecha',
    headerName: 'Fecha',
    type: 'date',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'especialidad',
    headerName: 'Especialidad',
    width: 220,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'paciente',
    headerName: 'Paciente',
    width: 300,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'estado',
    headerName: 'Estado',
    width: 200,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell({ row }) {
      return (
        <span>Atendido</span>
      )
    }
  },
]

export const TriajesAtendidos = () => {
  const { user, especialidad: espe } = useContext(UserContext)
  const estado = 1 // (Citas atendidas / Diferentes de pendiente)
  const { data, isLoading } = useGetCitas(espe.idEspecialista, estado)

  const comboCitas =
    data ?
      data.map((cita, index) => ({
        id: index,
        fecha: cita.fechaCita,
        hora: cita.horaCitaIni,
        especialidad: user.descEspecialidad,
        paciente: cita.nombre,
        estado: cita.estado
      })) : []

  const { filterByPaciente, paciente, setPaciente } = useFilterByPaciente()
  const { filterByEspecialidad, especialidad, setEspecialidad, especialidades } = useFilterByEspecialidad()
  const { filterMinDate, minDate, setMinDate, fechaInicial } = useFilterByMinDate()
  const { filterMaxDate, maxDate, setMaxDate, fechaActual } = useFilterByMaxDate()
  const filters = [
    {
      name: 'paciente',
      filter: filterByPaciente,
      filterValue: paciente,
      setFilterValue: setPaciente
    },
    {
      name: 'especialidad',
      filter: filterByEspecialidad,
      filterValue: especialidad,
      setFilterValue: setEspecialidad
    },
    {
      name: 'fecha',
      filter: filterMinDate,
      filterValue: minDate,
      setFilterValue: setMinDate
    },
    {
      name: 'fecha',
      filter: filterMaxDate,
      filterValue: maxDate,
      setFilterValue: setMaxDate
    }
  ]

  const clearFilters = () => {
    setPaciente('')
    setEspecialidad('')
    setMinDate(fechaInicial)
    setMaxDate(fechaActual)
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Triajes Atendidos
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <ApplyFilters
            data={comboCitas}
            filters={filters}
            renderItem={() =>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={380}
                  rows={comboCitas}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.id}
                />
              </Loading>
            }
          >
            <Grid item xs={12} md={4}>
              <Filter label='Especialidad' data={[{ desEspecialidad: 'medicina general' }, { desEspecialidad: 'odontologia' }]} setValue={setEspecialidad} value={especialidad} filterKey="desEspecialidad" />
            </Grid>
            <Grid item xs={12} md={3}>
              <FilterDates label='Fecha inicial' value={minDate} setValue={setMinDate} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FilterDates label='Fecha final' value={maxDate} setValue={setMaxDate} />
            </Grid>
            <Grid item xs={12} md={2}>
              <FilterButtons clearFilters={clearFilters} />
            </Grid>
            <Grid item xs={12} md={12}>
              <AutoCompletePaciente value={paciente} setValue={setPaciente} />
            </Grid>
          </ApplyFilters>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../components/Input'
import ContentMainBox from '../../../components/MainBox'
import { useAlertMessage } from '../../../context/notifications'
import { UserContext } from '../../../context/userContext'
import { useCreateCita, useGetHorariosDisponibles } from '../../../hooks/back-clinica/useCitas'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import { useGetPacienteByNroDoc } from '../../../hooks/usePacientes'

const SemestreSchema = yup.object({
  // pacienteId: yup.number().required('Campo requerido'),
  especialidadId: yup.number().required('Campo requerido'),
  especialistaId: yup.number().required('Campo requerido'),
  // horarioDetId: yup.number().required('Campo requerido'),
  fechaCita: yup.date('Ingresar una fecha válida').required('Campo requerido')
})

export const SolicitarCita = () => {
  const { user } = useContext(UserContext)
  const { showError, showSuccess } = useAlertMessage()
  const [horarioDet, setHorarioDet] = useState()

  const { mutate } = useCreateCita()
  const { data: paciente } = useGetPacienteByNroDoc(user?.dni)
  const PACIENTE_ID = paciente?.idPaciente

  let { data: Especialistas } = useGetAllEspecialista() || []
  let { data: Especialidades } = useGetAllEspecialidad() || []

  const { handleSubmit, control, formState: { isValid }, watch, reset, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SemestreSchema),
    defaultValues: {
      motivoConsulta: '',
      especialidadId: '',
      especialistaId: '',
      fechaCita: new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' })
      // pacienteId: PACIENTE_ID,
      // horarioDetId: '',
    }
  })

  const { data: horariosDisponibles, refetch } = useGetHorariosDisponibles({
    fechaCita: watch('fechaCita'),
    idEspecialista: watch('especialistaId')
  }, false)

  const comboEspecialistas = Especialistas
    ? Especialistas.map(especialista => {
      return {
        value: especialista.idEspecialista,
        especialistaId: especialista.idEspecialista,
        denominacion: `${especialista.persona.nombres} ${especialista.persona.apPaterno} ${especialista.persona.apMaterno}`,
        especialidadId: especialista.idEspecialidad
      }
    }).filter(especialista => especialista.especialidadId === watch('especialidadId'))
    : []

  const comboEspecialidades = Especialidades
    ? Especialidades.map(especialidad => {
      return {
        value: especialidad.idEspecialidad,
        denominacion: especialidad.desEspecialidad
      }
    }) : []

  const onSubmit = (data) => {
    const formData = {
      idPaciente: PACIENTE_ID,
      motivoConsulta: data.motivoConsulta,
      idEspecialista: data.especialistaId,
      idHorarioDet: horarioDet.idHorarioDet,
      fechaCita: data.fechaCita,
    }

    const msg = 'No se pudo crear Cita'
    const msg2 = 'Cita creada correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        reset()
        showSuccess(msg2)
      }
    })
  }
  const ESPECIALISTA = watch('especialistaId')

  useEffect(() => {
    refetch()
  }, [watch('especialistaId'), watch('fechaCita')])

  useEffect(() => {
    setHorarioDet(null)
  }, [watch('fechaCita')])

  useEffect(() => {
    setValue('especialistaId', '')
    setValue('fechaCita', new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' }))
  }, [watch('especialidadId')])


  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>Solicitar cita</div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              direction='row'
              justifyContent='center'
              alignItems='center'
              alignContent='center'
              wrap='wrap'
              className='mt-2'
            >
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      multiline
                      rows={2}
                      id='motivoConsulta'
                      label='Motivo Consulta'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='especialidadId'
                      label='Especialidad'
                      size='small'
                      type='select'
                      data={comboEspecialidades}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='especialistaId'
                      label='Especialista'
                      size='small'
                      type='select'
                      data={comboEspecialistas}
                      disabled={watch('especialidadId') === '' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      fullWidth
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                      size='small'
                      disabled={watch('especialistaId') === '' ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      alignContent='center'
                      wrap='wrap'
                      gap={1}
                    >
                      {ESPECIALISTA != '' ? (
                        horariosDisponibles?.length === 0 ? (
                          <Typography variant='body1' className='mx-auto'>
                            No hay horarios disponibles para este día
                          </Typography>
                        ) : (
                          horariosDisponibles?.map((option, i) => (

                            <Button
                              onClick={() =>
                                setHorarioDet(option)
                              }
                              variant={
                                horarioDet?.idHorarioDet === option.idHorarioDet
                                  ? 'contained'
                                  : 'outlined'
                              }
                              color='primary'
                              size='large'
                            >
                              {option.horaIni}
                            </Button>
                          ))
                        )
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      fullWidth
                      disabled={!isValid || !horarioDet}
                    >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

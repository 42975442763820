import * as yup from 'yup'

const AccesoSchema = yup.object({
  rol: yup
    .string()
    .required('* Campo requerido'),
  nombreEtiqueta: yup
    .string()
    .required('* Campo requerido'),
  descripcion: yup
    .string()
    .required('* Campo requerido'),
  ruta: yup
    .string()
    .required('* Campo requerido'),
  iconoAcceso: yup
    .string()
    .required('* Campo requerido'),
  menu: yup
    .number()
    .required('* Campo requerido'),
})
export default AccesoSchema

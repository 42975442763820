import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, Update, GetById, search } from '../services/usuarios'

const key = 'Usuarios'

export const useGetAllUsuarios = (enabled = true) =>
  useQuery([key, searchParams], GetAll, {
    enabled
  })

export const useSearchUsuarios = (searchParams, enabled = true) =>
  useQuery([key, searchParams], search, {
    enabled,
    select: (data) => data.map (usuario => {
      return {
        ...usuario,
        usuario: `${usuario.persona.apPaterno} ${usuario.persona.apMaterno} ${usuario.persona.nombres}`,
        perfilNombre: usuario.perfil.perfilNombre,
        perfilCodigo: usuario.perfil.perfilCodigo,
      }
    })
  })

export const useCreateUsuario = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
export const useGetByIdUsuario = (id, enabled = true) =>
  useQuery([key, id], GetById, {
    enabled
  })

export const useUpdateUsuario = id => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(id, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeleteUsuario = id => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(id), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material'
import ButtonChangeTheme from '../ButtonChangeTheme'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import styled from '@emotion/styled'
import MuiAppBar from '@mui/material/AppBar'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { Link, useNavigate } from 'react-router-dom'
import useLocalStorage from '../../hooks/useLocalStorage'
import { TokenContext } from '../../context/core/TokenContext'
import { useContext, useState } from 'react'
import { UserContext } from '../../context/userContext';

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const HeaderLayout = ({ handleDrawerOpen, open, setOpen }) => {
  const { user } = useContext(UserContext)
  const [tokenLS, setTokenLS] = useLocalStorage('token', '')
  const [persona, setPersona] = useLocalStorage("persona", {})
  const { modifyAxiosClient } = useContext(TokenContext)
  const navigate = useNavigate()
  const logout = () => {

    setTokenLS('')
    modifyAxiosClient(tokenLS)
    localStorage.removeItem('token')
    localStorage.removeItem('_p')
    localStorage.removeItem('dni')
    navigate('/login')
  }

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' })
          }}
        >
          <MenuRoundedIcon />
        </IconButton>
        <Typography variant="h6" component="div" className='text-end mx-2' sx={{ flexGrow: 1, ...(open && { display: 'none' }) }}>
          {persona?.nombres}
        </Typography>
        <Box sx={{ flexGrow: 0, ...(open && { display: 'none' }) }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircleRoundedIcon fontSize='large' />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >

            <Link
              to={'/login'}
              className='no-underline'
            >
              <MenuItem onClick={logout}>
                <Typography textAlign="center">Cerrar sesión</Typography>
              </MenuItem>
            </Link>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderLayout

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, Update, GetById, GetByIdEspecialidad } from '../services/formatos'

const key = 'Formatos'

export const useGetAllFormatos = (enabled = true) =>
    useQuery(key, GetAll, {
        enabled
    })

export const useCreateFormato = () => {
    const queryClient = useQueryClient()

    return useMutation(Create, {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}
export const useGetByIdFormato = (id, enabled = true) =>
    useQuery(['FormatoId', id], GetById, {
        enabled
    })
export const useGetAllByEspecialidad = (id, enabled = true) =>
    useQuery([key, id], GetByIdEspecialidad, {
        enabled
    })

export const useUpdateFormato = id => {
    const queryClient = useQueryClient()

    return useMutation(body => Update(id, body), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useDeleteFormato = id => {
    const queryClient = useQueryClient()

    return useMutation(() => DeleteElement(id), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}
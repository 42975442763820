import { createContext, useReducer } from "react"

export const UserContext = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SET_ROL':
            return {
                ...state,
                rol: action.payload
            }
        case 'SET_ESPECIALIDAD':
            return {
                ...state,
                especialidad: action.payload
            }
        default:
            return state
    }
}

const initialState = {
    user: {
        email: 'email@example.com',
        picture:localStorage.getItem("picture") || "",
        nombres: localStorage.getItem("name") || "Nombres de usuario",
        dni: JSON.parse(localStorage.getItem("dni")) || "ninguno",
        perfil: {
            perfilCodigo: 0,
            perfilNombre: "perfilNombre",
            descripcion: "descripcion",
            icono: "icono",
            estado: 0
        },
        estado: 0
    },
    rol: localStorage.getItem("rol") || "especialista",
    especialidad: {
        idEspecialidad: JSON.parse(localStorage.getItem("especialidad"))?.especialidad?.idEspecialidad || -1,
        desEspecialidad: JSON.parse(localStorage.getItem("especialidad"))?.especialidad?.desEspecialidad || 'ninguno',
        idEspecialista: JSON.parse(localStorage.getItem("especialidad"))?.idEspecialista || -1,
        cmp: JSON.parse(localStorage.getItem("especialidad"))?.cmp
    },
    setRol: () => '',
    setUser: () => { },
    setEspecialidad: () => { }
}

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setUser = (user) => {
        dispatch({
            type: 'SET_USER',
            payload: user
        })
    }
    const setRol = (rol) => {
        dispatch({
            type: 'SET_ROL',
            payload: rol
        })
    }
    const setEspecialidad = (especialidad) => {
        dispatch({
            type: 'SET_ESPECIALIDAD',
            payload: especialidad
        })
    }

    return (
        <UserContext.Provider value={{
            user: state.user,
            rol: state.rol,
            especialidad: state.especialidad,
            setRol,
            setUser,
            setEspecialidad
        }}>
            {children}
        </UserContext.Provider>
    )
}

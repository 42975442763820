import { useMutation, useQuery, useQueryClient } from 'react-query'
import { atenderPaciente, getDatosCita, getFormatosCita } from '../services/atencion-paciente'

const key = 'atencion-paciente'

export const useGetFormatosCita = ({ idEspecialidad }) => {
  return useQuery({
    queryKey: [key, idEspecialidad],
    queryFn: getFormatosCita,
  })
}

export const useGetDatosCita = ({ idFormato, idCita }) => {
  return useQuery({
    queryKey: [key, idFormato, idCita],
    queryFn: getDatosCita,
  })
}

export const useAtenderPaciente = () => {
  const queryClient = useQueryClient()
  return useMutation(body => atenderPaciente(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
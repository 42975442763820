import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, GetById, Update } from '../services/especialidadesexternas'

const key = 'especialidadesexternas'

export const useGetAllEspecialidad = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetByIdEspecialidad = (idEspecialidad, enabled = true) =>
  useQuery([key,idEspecialidad], GetById, {
    enabled
  })

export const useCreateEspecialidad = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateEspecialidad = idEspecialidad => {
  const queryClient = useQueryClient()

  return useMutation(body => Update(idEspecialidad, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useDeleteEspecialidad = idEspecialidad => {
  const queryClient = useQueryClient()

  return useMutation(() => DeleteElement(idEspecialidad), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}


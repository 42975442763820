import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, GetByIdPerfil } from '../services/listaMenu'

const key = 'ListaMenu'

export const useGetListaMenu = (perfilCodigo, enabled = true) =>
  useQuery([key, perfilCodigo], GetByIdPerfil, {
    enabled
  })


export const useCreateListaMenu = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
import * as yup from 'yup'

const FormatoSchema = yup.object({
    nombreCampo: yup
        .string()
        .required('* Campo requerido'),
    tipoValor: yup
        .string()
        .required('* Campo requerido'),
    idFormato: yup
        .number()
        .required('* Campo requerido'),
    estado: yup
        .number()
        .required('* Campo requerido'),
})
export default FormatoSchema

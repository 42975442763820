import React, { useState } from 'react'
import ButtonAdd from '../../../../components/ButtonAdd'
import { useCreateTriaje } from '../../../../hooks/back-clinica/useTriajeClinica'
import { FormTriaje } from './FormTriaje'

export const CreateTriaje = () => {
  const { mutate } = useCreateTriaje()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <ButtonAdd openModal={handleOpen} />
      <FormTriaje
        mutate={mutate}
        isLoading={false}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  )
}

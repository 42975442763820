import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControlLabel, Switch as SwitchMui } from '@mui/material'

export const Switch = ({ control, id, label }) => {
    return (
        <Controller
            name={id}
            control={control}
            render={({ field: { onChange, value } }) =>
                <FormControlLabel
                    label={label}
                    control={
                        <SwitchMui
                            id={id}
                            onChange={onChange}
                            checked={value}
                        />
                    }
                />
            }
        />
    )
}

import { yupResolver } from '@hookform/resolvers/yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  Divider,
  Grid,
  Slider,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../../../components/Input'
import { useAlertMessage } from '../../../../context/notifications'
import { useGetAllAccesos } from '../../../../hooks/useAccesos'
import { useCreateListaMenu, useGetListaMenu } from '../../../../hooks/useListaMenu'
import { useGetByIdPerfil } from '../../../../hooks/usePerfiles'
import { groupAccesos } from '../../../../utils'
import PerfilesSchema from '../schema/perfiles.schema'
import SliderPerfil from './SliderPerfil'

const DialogFormPerfiles = ({ edit, id, mutate, isLoading, open, handleClose }) => {
  const { data: perfil } = useGetByIdPerfil(id, edit)
  const { data: listaMenu } = useGetListaMenu(id, edit)
  const { mutate: mutateListaMenu } = useCreateListaMenu()


  const listaMenuDefault = {}
  listaMenu?.forEach((item) => {
    listaMenuDefault[item.menuCodigo] = item.nivelAcceso
  })

  const { data } = useGetAllAccesos()
  const accesos = groupAccesos(data)

  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { showError, showSuccess } = useAlertMessage()

  const { handleSubmit, reset, control, formState: { errors }, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(PerfilesSchema),
    defaultValues: {
      perfilNombre: perfil?.perfilNombre || '',
      descripcion: perfil?.descripcion || '',
      icono: perfil?.icono || 'ninguno',
      estadoRegistro: perfil?.estadoRegistro || 1,
      listaMenu: listaMenuDefault || {}
    }
  })
  const onSubmit = data => {
    // console.log('submit', data)
    const { listaMenu } = data

    const newListaMenu = Object.entries(listaMenu).map(([menuCodigo, nivelAcceso]) => ({
      perfilCodigo: edit ? id : 0,
      menuCodigo: parseInt(menuCodigo),
      nivelAcceso,
    })).filter((item) => item.nivelAcceso > 0)

    let formData = {
      perfilNombre: data.perfilNombre,
      descripcion: data.descripcion,
      icono: data.icono,
      listaMenu: newListaMenu
    }

    if (edit) {
      formData = {
        ...formData,
        estado: 1,
      }
    }

    const successMessage = edit ? 'Perfil editado con exito' : 'Perfil creado con exito'
    const errorMessage = edit ? 'No se pudo editar perfil' : 'No se pudo crear perfil'
    mutate(formData, {
      onError: () => {
        // handleClose()
        showError(errorMessage)
        // reset()
      },
      onSuccess: () => {
        // mutateListaMenu(newListaMenu, {
        //   onError: () => {
        //     showError('No se creo la lista mneu')
        //   },
        //   onSuccess: () => {
        //     showSuccess('Lista menu creado con exito')
        //   }
        // })
        // handleClose()
        showSuccess(successMessage)
        // reset()
      }
    })
  }

  useEffect(() => {
    if (edit) {
      reset({
        perfilNombre: perfil?.perfilNombre || '',
        descripcion: perfil?.descripcion || '',
        icono: perfil?.icono || 'ninguno',
        estadoRegistro: perfil?.estadoRegistro || 1,
        listaMenu: listaMenuDefault || {}
      })
    }
  }, [perfil, listaMenu])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR PERFIL' : 'AÑADIR PERFIL'}</DialogTitle>
        <Grid container >
          <Grid item xs={12} >
            <Divider variant='middle'></Divider>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información de Perfil
            </DialogContentText>
            <Grid container spacing={1} alignContent='center'>
              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='perfilNombre'
                  label='Nombre de perfil'
                  placeholder='Ingrese Nombre'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.perfilNombre?.message}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Input
                  control={control}
                  id='descripcion'
                  label='Descripción'
                  placeholder='Ingrese descripción'
                />
                <Typography
                  variant='subtitle2'
                  align='left'
                  color='red'
                >
                  {errors.descripcion?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {
                  Object.keys(accesos).map((rol, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          {rol?.toUpperCase()}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          {accesos[rol]?.map(({ idAcceso, nombreEtiqueta, ...other }) => (
                            <Grid container spacing={2} key={idAcceso}>
                              <Grid item xs={4}>
                                <Typography variant='subtitle2' align='left' color='#212121'>
                                  {nombreEtiqueta}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Controller
                                  name={`listaMenu.${idAcceso}`} // Use a unique name for each Slider
                                  control={control}
                                  // defaultValue={{ menuCodigo: idAcceso, nivelAcceso: 0 }} // Initial value
                                  defaultValue={0}
                                  render={({ field }) => {
                                    return <Box sx={{ width: 300 }}>
                                      <Slider
                                        {...field}
                                        onChange={(e, value) => {
                                          // onChange({ menuCodigo: idAcceso, nivelAcceso: value })
                                          field.onChange(value)
                                          setValue(`listaMenu.${idAcceso}`, value);
                                        }}
                                        // value={props.value ? props.value.nivelAcceso : 0}
                                        aria-label="Custom marks"
                                        step={1}
                                        min={0}
                                        max={5}
                                        valueLabelDisplay="auto"
                                        marks={[
                                          {
                                            value: 0,
                                            label: 'Sin acceso',
                                          },
                                          {
                                            value: 1,
                                            label: 'Listar',
                                          },
                                          {
                                            value: 2,
                                            label: 'Abrir',
                                          },
                                          {
                                            value: 3,
                                            label: 'Nuevo',
                                          },
                                          {
                                            value: 4,
                                            label: 'Anular',
                                          },
                                          {
                                            value: 5,
                                            label: 'Autorizar',
                                          },
                                        ]}
                                      />
                                    </Box>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          <button type="submit">Enviar</button>

                          {
                            // accesos[rol]?.map(({ idAcceso, nombreEtiqueta, ...other }, idx) => (
                            //   <Grid container spacing={2}>
                            //     <Grid item xs={4} >
                            //       <Typography
                            //         variant='subtitle2'
                            //         align='left'
                            //         color='#212121'
                            //       >
                            //         {nombreEtiqueta}
                            //       </Typography>
                            //     </Grid>
                            //     <Grid item xs={8}>
                            //       <Box sx={{ width: 300 }}>
                            //         <Slider
                            //           aria-label="Custom marks"
                            //           value={1}
                            //           // getAriaValueText={valuetext}
                            //           // onChange={handleSliderChange}
                            //           step={1}
                            //           min={0}
                            //           max={5}
                            //           valueLabelDisplay="auto"
                            //           marks={[
                            //             {
                            //               value: 0,
                            //               label: 'Sin acceso',
                            //             },
                            //             {
                            //               value: 1,
                            //               label: 'Listar',
                            //             },
                            //             {
                            //               value: 2,
                            //               label: 'Abrir',
                            //             },
                            //             {
                            //               value: 3,
                            //               label: 'Nuevo',
                            //             },
                            //             {
                            //               value: 4,
                            //               label: 'Anular',
                            //             },
                            //             {
                            //               value: 5,
                            //               label: 'Autorizar',
                            //             },
                            //           ]}
                            //         />
                            //       </Box>
                            //     </Grid>
                            //   </Grid>
                            // ))
                          }
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()
              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
export default DialogFormPerfiles;

export const AccordionAccesos = ({ menu, open, setOpen, level = 1, actualizarListaMenus, newListaMenus }) => {
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangeOnClose = (panel) => (event, newExpanded) => {
    setOpen(!open)
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <>
      {
        menu.map(({ nombre, lista }, index) => {
          return (
            <Accordion
              key={index}
              expanded={open ? expanded === index : false}
              onChange={open ? handleChange(index) : handleChangeOnClose(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {nombre}
                </Typography>
              </AccordionSummary>
              <>
                {
                  lista?.length > 0 &&
                  <AccordionDetails>
                    <Box>
                      {
                        lista.map(({ menuCodigo, nombre, lista, ...other }, idx) => {
                          if (lista?.length > 0) {
                            return (
                              <AccordionAccesos
                                key={idx}
                                menu={[{ menuCodigo, nombre, lista, ...other }]}
                                open={open}
                                setOpen={setOpen}
                                level={level + 1}
                                actualizarListaMenus={actualizarListaMenus}
                                newListaMenus={newListaMenus}
                              />
                            )
                          }

                          return (<Box
                            key={idx}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={4} >
                                <Typography
                                  variant='subtitle2'
                                  align='left'
                                  color='#212121'
                                >
                                  {nombre}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <SliderPerfil actualizarListaMenus={actualizarListaMenus} codigo={menuCodigo} valorMenu={newListaMenus?.filter((menu) => menu.menuCodigo === menuCodigo)} />
                              </Grid>
                            </Grid>
                          </Box>)
                        })}
                    </Box>
                  </AccordionDetails>
                }
              </>
            </Accordion >
          )
        })
      }
    </>
  )
}
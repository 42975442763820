import { useState } from 'react'
import {
  Button,
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogContentText,
  IconButton
} from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { useDeletePerfil } from '../../../../hooks/usePerfiles'
import { useAlertMessage } from '../../../../context/notifications'
import { LoadingButton } from '@mui/lab'

const EliminarPerfiles = ({ id }) => {
  const { mutate: deletePerfil, isLoading } = useDeletePerfil(id)
  const { showError, showSuccess } = useAlertMessage()
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const onSubmit = () => {
    deletePerfil({
      onError: () => {
        handleClose()
        showError('Error al eliminar')
      },
      onSuccess: () => {
        handleClose()
        showSuccess('Eliminado con exito')
      },
    })
  }

  return (
    <>
      <Tooltip title='Eliminar Perfil'>
        <IconButton aria-label='delete' onClick={handleClickOpen}>
          <DeleteRoundedIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle>ELIMINAR Paciente</DialogTitle> */}
        <DialogContent >
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <b>¿Está seguro que quiere eliminar la informacion: {id}?</b>
            <br />Una vez eliminado el registro, no podrás recuperar la información registrada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            REGRESAR
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={() => onSubmit()}
            variant='contained'
          >
            SI, ELIMINAR
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EliminarPerfiles
import { useUpdateUsuario } from '../../../../hooks/useUsuarios'
import FormUsuarios from './FormUsuarios'

const UpdateUsuarios = ({ id }) => {
  const { mutate, isLoading } = useUpdateUsuario(id)
  return (
    <>
      <FormUsuarios edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdateUsuarios

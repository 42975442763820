import { createContext, useEffect, useReducer } from "react";

export const EspecialidadContext = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ESPECIALIDAD':
            return {
                ...state,
                especialidad: action.payload
            }
        default:
            return state
    }
}
const initialState = {
    especialidad: {
        idEspecialidad: 1,
        desEspecialidad: "MEDICINA GENERAL",
        abrevEspecialidad: "MED. GRAL.",
        periodoIteracion: 30,
        requiereTriaje: 1,
        estado: 1
    },
}
export const EspecialidadProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const setEspecialidad = (especialidad) => {
        dispatch({
            type: 'SET_ESPECIALIDAD',
            payload: especialidad
        })
    }

    useEffect(() => {
        const storage = JSON.parse(localStorage.getItem('especialidad'))
        if (storage)
            setEspecialidad(storage.especialidad)
    }, [])

    return (
        <EspecialidadContext.Provider value={{
            especialidad: state.especialidad,
            setEspecialidad
        }}>
            {children}
        </EspecialidadContext.Provider>
    )
}
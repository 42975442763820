import { Button, Divider, Grid, MenuItem, TextField } from '@mui/material'
import ContentMainBox from '../../../components/MainBox'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useLocation, useNavigate } from 'react-router-dom'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalExamenes } from './components/ModalExamenes';
import { ModalError } from './components/ModalError';
import { ModalDiagnosticos } from './components/ModalDiagnosticos';
import { DatosGenerales } from '../../../components/DatosGenerales';
import { EspecialidadContext } from '../../../context/especialidadContext';
import { useAtencion } from '../../../hooks/useAtencion';
import { useAlertMessage } from '../../../context/notifications';

const CamposPaciente = [
  'nombres',
  'apellidos',
  'nroDoc',
  'tipoPaciente',
  'fechaNacimiento',
  'direccion',
  'celular',
  'alergias',
  'vacunas',
  'cirugias',
  'personaResponsable',
  'celularResponsable',
  'sexo',
  'edad',
  'procedencia',
  'carreraProfesional',
  'numeroHermanos',
  'accidentesGraves',
  'codigoEstudiante',
  'semestre',
  'email',
  'tutor',
  'viveResidenciaUniv',
  'idioma',
  'religion',
  'LugarOcupaHermano'
]

const atencionIntegral = yup.object({
  tiempoEnfermedad: yup
    .string()
    .required('*Campo requerido'),
  motivoConsulta: yup
    .string()
    .required('*Campo requerido'),
  relatoCronologico: yup
    .string()
    .required('*Campo requerido'),
  anamnesis: yup
    .string()
    .required('*Campo requerido'),
  antecedentes: yup
    .string()
    .required('*Campo requerido'),
  examenFisico: yup
    .string()
    .required('*Campo requerido'),
  apetito: yup
    .string()
    .required('*Campo requerido'),
  sed: yup
    .string()
    .required('*Campo requerido'),
  orina: yup
    .string()
    .required('*Campo requerido'),
  deposiciones: yup
    .string()
    .required('*Campo requerido'),
  sueño: yup
    .string()
    .required('*Campo requerido'),
  examenesAuxiliares: yup
    .string(),
  // .required('*Campo requerido'),
  diagnostico: yup
    .string(),
  // .required('*Campo requerido'),
  tratamiento: yup
    .string()
    .required('*Campo requerido'),
  interconsulta: yup
    .string()
    .required('*Campo requerido'),
})

const estadoFisicos = {
  'Apetito': false,
  'Sed': false,
  'Sueño': false,
  'Orina': false,
  'Deposiciones': false,
}

const dataExamenRapido = [
  {
    idExamen: 1,
    tipoExamen: 'Pruebas de laboratorio',
    descripcionExamen: 'Análisis de sangre'
  },
  {
    idExamen: 2,
    tipoExamen: 'Pruebas de laboratorio',
    descripcionExamen: 'Análisis de orina'
  },
  {
    idExamen: 3,
    tipoExamen: 'Pruebas de laboratorio',
    descripcionExamen: 'Análisis de heces'
  },
  {
    idExamen: 4,
    tipoExamen: 'Pruebas de imagen',
    descripcionExamen: 'Radiografía'
  },
  {
    idExamen: 5,
    tipoExamen: 'Pruebas de imagen',
    descripcionExamen: 'Tomografía'
  },
  {
    idExamen: 6,
    tipoExamen: 'Pruebas de imagen',
    descripcionExamen: 'Resonancia magnética'
  },
  {
    idExamen: 7,
    tipoExamen: 'Pruebas de imagen',
    descripcionExamen: 'Ecografía'
  },
  {
    idExamen: 8,
    tipoExamen: 'Pruebas endoscópicas',
    descripcionExamen: 'Colonoscopía'
  },
  {
    idExamen: 9,
    tipoExamen: 'Anatomia patológica',
    descripcionExamen: 'Muestra de tejido'
  },
  {
    idExamen: 10,
    tipoExamen: 'Anatomia patológica',
    descripcionExamen: 'Biopsia'
  },
  {
    idExamen: 11,
    tipoExamen: 'Anatomia patológica',
    descripcionExamen: 'Análisis citológico'
  },
  {
    idExamen: 12,
    tipoExamen: 'Electrograma',
    descripcionExamen: 'Electrocardiograma'
  },
  {
    idExamen: 13,
    tipoExamen: 'Electrograma',
    descripcionExamen: 'Electroencefalograma'
  },
  {
    idExamen: 14,
    tipoExamen: 'Electrograma',
    descripcionExamen: 'Electromiograma'
  },
]

export const AtencionIntegral = () => {
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita
  const [state, setState] = useState(estadoFisicos)
  const [stateModalExamenes, setStateModalExamenes] = useState({ open: false, addItem: false })
  const [stateModalDiagnosticos, setStateModalDiagnosticos] = useState({ open: false, addItem: false })
  const [openModalError, setOpenModalError] = useState(false)

  const [mensajeError, setMensajeError] = useState('')
  const [dataExamenesPaciente, setDataExamenesPaciente] = useState([])
  const [dataDiagnosticosPaciente, setDataDiagnosticosPaciente] = useState([])

  const [examen, setExamen] = useState()

  const { showError, showSuccess } = useAlertMessage()
  const { mutate } = useAtencion()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, getValues, setValue, setFocus, reset } = useForm({
    resolver: yupResolver(atencionIntegral),
  })
  const { especialidad } = useContext(EspecialidadContext)
  // const handleChange = (event) => {
  //   const { name, checked } = event.target
  //   setState({
  //     ...state,
  //     [name]: checked,
  //   });

  //   if (checked) {
  //     setValue("examenFisico", `${getValues("examenFisico")}${name}\n`)
  //   } else {
  //     setValue("examenFisico", getValues("examenFisico").replace(`${name}\n`, ''))
  //   }
  // }

  const onSubmit = (data) => {
    const llaves = Object.keys(data)
    const Lista = llaves.map((ojb) => {
      return {
        nombreCampo: ojb,
        valor: data[`${ojb}`]
      }
    })
    const DatosEnviar = {
      idCita: dataCita.id,
      datosForm: Lista
    }
    const msg = 'No se pudo guardar datos de la atencion'
    const msg2 = 'Datos guardados correctamente'
    // console.log(DatosEnviar)
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
        reset()
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }

  useEffect(() => {
    setFocus('examenFisico')
  }, [state])

  const onAddExamenAuxiliar = () => {
    const datosExamen = examen
    //Realizar validaciones
    if (!datosExamen) {
      setMensajeError('Seleccione una opción')
      setOpenModalError(true)
      return
    }
    if (dataExamenesPaciente.filter(({ examen: { idExamen } }) => idExamen === datosExamen.idExamen).length !== 0) {
      setMensajeError('Ya existe la opción seleccionada')
      setOpenModalError(true)
      return
    }
    // Agregar examen y mostrar modal
    setDataExamenesPaciente([...dataExamenesPaciente, { examen: datosExamen, rutaArchivo: '', resultados: '' }])
    setStateModalExamenes({ open: true, addItem: true })
  }

  const onAddDiagnostico = (nombreDiagnostico) => {
    //Realizar validaciones
    if (nombreDiagnostico === '') {
      setMensajeError('Seleccione una opción')
      setOpenModalError(true)
      return
    }
    if (dataDiagnosticosPaciente.filter(e => e.nombre === nombreDiagnostico).length !== 0) {
      setMensajeError('Ya existe la opción seleccionada')
      setOpenModalError(true)
      return
    }
    // Agregar examen y mostrar modal
    setDataDiagnosticosPaciente([...dataDiagnosticosPaciente, { nombre: nombreDiagnostico, cie10: 'cie-10', tipo: '' }])
    setStateModalDiagnosticos({ open: true, addItem: true })
  }

  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <div>Atención Integral</div>
      </ContentMainBox.Title>
      <ContentMainBox.Content>
        <Grid item xs={12}>
          <DatosGenerales DNI={'41526321'} triaje={especialidad.requiereTriaje} CamposPaciente={CamposPaciente} />
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <Divider>Enfermedad actual</Divider>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <TextField
                label='Tiempo de enfermedad, forma de inicio, curso'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('tiempoEnfermedad')}
                error={!!errors.tiempoEnfermedad}
                helperText={!!errors.tiempoEnfermedad ? errors.tiempoEnfermedad.message : null}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Motivo de consulta'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('motivoConsulta')}
                error={!!errors.motivoConsulta}
                helperText={!!errors.motivoConsulta ? errors.motivoConsulta.message : null}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Anamnesis'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('anamnesis')}
                error={!!errors.anamnesis}
                helperText={!!errors.anamnesis ? errors.anamnesis.message : null}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Relato cronológico'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('relatoCronologico')}
                error={!!errors.relatoCronologico}
                helperText={!!errors.relatoCronologico ? errors.relatoCronologico.message : null}
              />
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Antecedentes personales y familiares'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('antecedentes')}
                error={!!errors.antecedentes}
                helperText={!!errors.antecedentes ? errors.antecedentes.message : null}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <TextField
                label='Examen físico'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('examenFisico')}
                error={!!errors.examenFisico}
                helperText={!!errors.examenFisico ? errors.examenFisico.message : null}
              />
              {/* {
                Object.keys(state).map((estado, index) => {
                  return <FormControlLabel
                    key={index}
                    control={
                      <Checkbox checked={state[estado]} name={estado} onChange={handleChange} />
                    }
                    label={estado}
                  />
                })
              } */}
            </Grid>

            <Grid item xs={12}>
              <Divider>Funciones biológicas</Divider>
            </Grid>

            <Grid item container columns={10} spacing={2}>
              <Grid item lg={2} xs={12}>
                <TextField
                  label='Apetito'
                  size='small'
                  fullWidth
                  {...register('apetito')}
                  error={!!errors.apetito}
                  helperText={!!errors.apetito ? errors.apetito.message : null}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  label='Sed'
                  size='small'
                  fullWidth
                  {...register('sed')}
                  error={!!errors.sed}
                  helperText={!!errors.sed ? errors.sed.message : null}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  label='Orina'
                  size='small'
                  fullWidth
                  {...register('orina')}
                  error={!!errors.orina}
                  helperText={!!errors.orina ? errors.orina.message : null}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  label='Deposiciones'
                  size='small'
                  fullWidth
                  {...register('deposiciones')}
                  error={!!errors.deposiciones}
                  helperText={!!errors.deposiciones ? errors.deposiciones.message : null}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  label='Sueño'
                  size='small'
                  fullWidth
                  {...register('sueño')}
                  error={!!errors.sueño}
                  helperText={!!errors.sueño ? errors.sueño.message : null}
                />
              </Grid>
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    label='Examenes auxiliares'
                    size='small'
                    fullWidth
                    defaultValue=''
                    select
                    {...register('examenesAuxiliares', {
                      onChange: () => {
                        const value = getValues('examenesAuxiliares')
                        const examenSelected = dataExamenRapido.find(({ descripcionExamen: desc }) => desc === value)
                        setExamen(examenSelected)
                      }
                    })}
                    error={!!errors.examenesAuxiliares}
                    helperText={!!errors.examenesAuxiliares ? errors.examenesAuxiliares.message : null}
                  >
                    {
                      dataExamenRapido.map(({ idExamen, descripcionExamen }) => (
                        <MenuItem key={idExamen} value={descripcionExamen}>{descripcionExamen}</MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    size='large'
                    fullWidth
                    onClick={() => onAddExamenAuxiliar()}
                    startIcon={<AddRoundedIcon />}
                    sx={{
                      borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                    }}
                  >
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    size='large'
                    fullWidth
                    onClick={() => setStateModalExamenes({ open: true, addItem: false })}
                    startIcon={<VisibilityRoundedIcon />}
                    sx={{
                      borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                    }}
                  >
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    label='Diagnóstico'
                    size='small'
                    fullWidth
                    defaultValue=''
                    select
                    {...register('diagnostico')}
                    error={!!errors.diagnostico}
                    helperText={!!errors.diagnostico ? errors.diagnostico.message : null}
                  >
                    <MenuItem value="diagnostico1">Diagnostico1</MenuItem>
                    <MenuItem value="diagnostico2">Diagnostico2</MenuItem>
                    <MenuItem value="diagnostico3">Diagnostico3</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    size='large'
                    fullWidth
                    onClick={(() => onAddDiagnostico(getValues('diagnostico')))}
                    startIcon={<AddRoundedIcon />}
                    sx={{
                      borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                    }}
                  >
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    size='large'
                    fullWidth
                    onClick={() => setStateModalDiagnosticos({ open: true, addItem: false })}
                    startIcon={<VisibilityRoundedIcon />}
                    sx={{
                      borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                    }}
                  >
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Tratamiento'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('tratamiento')}
                error={!!errors.tratamiento}
                helperText={!!errors.tratamiento ? errors.tratamiento.message : null}
              />
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                label='Interconsulta'
                size='small'
                fullWidth
                multiline
                rows={3}
                {...register('interconsulta')}
                error={!!errors.interconsulta}
                helperText={!!errors.interconsulta ? errors.interconsulta.message : null}
              />
            </Grid>

            <Grid item container justifyContent="space-around">
              <Grid item xs={3}>
                <Button variant='outlined' fullWidth
                  onClick={() => navigate("/especialista/nuevasconsultas")}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button type='submit' variant='contained' fullWidth>
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <ModalError
          openModal={openModalError}
          setOpenModal={setOpenModalError}
          mensaje={mensajeError}
        />
        <ModalExamenes
          stateModal={stateModalExamenes}
          setStateModal={setStateModalExamenes}
          data={dataExamenesPaciente}
          setData={setDataExamenesPaciente}
        />
        <ModalDiagnosticos
          stateModal={stateModalDiagnosticos}
          setStateModal={setStateModalDiagnosticos}
          data={dataDiagnosticosPaciente}
          setData={setDataDiagnosticosPaciente}
        />
      </ContentMainBox.Content>
    </ContentMainBox>
  )
}

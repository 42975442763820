import React, { useContext } from 'react'
import ContentMainBox from '../../../../components/MainBox'
import { Grid, Divider, Button, InputAdornment } from '@mui/material'
import Input from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUpdateCita } from '../../../../hooks/useCitas'
import { useAlertMessage } from '../../../../context/notifications'
import { DatosTriaje } from '../../../../components/DatosTriaje'
import { DatosGenerales } from '../../../../components/DatosGenerales'
import { EspecialidadContext } from '../../../../context/especialidadContext'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'
import { useAtencion } from '../../../../hooks/useAtencion'


const InputSchema = yup.object({
  antecedentes: yup
    .string()
    .required('*Campo requerido'),
  alergiaAlimento: yup
    .string()
    .required('*Campo requerido'),
  consumoAlimentos: yup
    .string()
    .required('*Campo requerido'),
  nroVecesCome: yup
    .string()
    .required('*Campo requerido'),
  desayuno: yup
    .string()
    .required('*Campo requerido'),
  almuerzo: yup
    .string()
    .required('*Campo requerido'),
  cena: yup
    .string()
    .required('*Campo requerido'),
  tiempoComidas: yup
    .string()
    .required('*Campo requerido'),
  comidaPreferida: yup
    .string()
    .required('*Campo requerido'),
  bebidaPreferida: yup
    .string()
    .required('*Campo requerido'),
  habitosNocivos: yup
    .string()
    .required('*Campo requerido'),
  tipoActividadFisica: yup
    .string()
    .required('*Campo requerido'),
  tiempoDedica: yup
    .string()
    .required('*Campo requerido'),
  examen: yup
    .string()
    .required('*Campo requerido'),
  resultado: yup
    .string()
    .required('*Campo requerido'),
  pesoIdeal: yup
    .string()
    .required('*Campo requerido'),
  circunferenciaMediaBrazo: yup
    .string()
    .required('*Campo requerido'),
  circunferenciaCintura: yup
    .string()
    .required('*Campo requerido'),
  circunferenciaCadera: yup
    .string()
    .required('*Campo requerido'),
  porcentajeGrasaCorporal: yup
    .string()
    .required('*Campo requerido'),
  porcentajeGrasaViceral: yup
    .string()
    .required('*Campo requerido'),
  porcentajeMusculo: yup
    .string()
    .required('*Campo requerido'),
  diagnosticoNutricional: yup
    .string()
    .required('*Campo requerido'),
  prescripcionReceta: yup
    .string()
    .required('*Campo requerido'),
})

const CamposPaciente = [
  'nombres',
  'apellidos',
  'nroDoc',
  'fechaNacimiento',
  'direccion',
  'celular',
  'sexo',
  'edad',
  'procedencia',
  'carreraProfesional',
  'codigoEstudiante'
]

export const FormHistoriaClinica = () => {

  const navigate = useNavigate()
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita
  const { mutate } = useAtencion()
  const { showError, showSuccess } = useAlertMessage()
  const { especialidad } = useContext(EspecialidadContext)
  const { handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(InputSchema),
  })

  const onSubmit = data => {
    const formData = {
      antecedentes: data.antecedentes,
      alergiaAlimento: data.alergiaAlimento,
      consumoAlimentos: data.consumoAlimentos,
      nroVecesCome: data.nroVecesCome,
      desayuno: data.desayuno,
      almuerzo: data.almuerzo,
      cena: data.cena,
      tiempoComidas: data.tiempoComidas,
      comidaPreferida: data.comidaPreferida,
      bebidaPreferida: data.bebidaPreferida,
      habitosNocivos: data.habitosNocivos,
      tipoActividadFisica: data.tipoActividadFisica,
      tiempoDedica: data.tiempoDedica,
      examen: data.examen,
      resultado: data.resultado,
      pesoIdeal: data.pesoIdeal,
      circunferenciaMediaBrazo: data.circunferenciaMediaBrazo,
      circunferenciaCintura: data.circunferenciaCintura,
      circunferenciaCadera: data.circunferenciaCadera,
      porcentajeGrasaCorporal: data.porcentajeGrasaCorporal,
      porcentajeGrasaViceral: data.porcentajeGrasaViceral,
      porcentajeMusculo: data.porcentajeMusculo,
      diagnosticoNutricional: data.diagnosticoNutricional,
      prescripcionReceta: data.prescripcionReceta,
    }
    const llaves = Object.keys(formData)
    const Lista = llaves.map((ojb) => {
      return {
        nombreCampo: ojb,
        valor: data[`${ojb}`]
      }
    })
    const DatosEnviar = {
      idCita: dataCita.id,
      datosForm: Lista
    }
    const msg = 'No se pudo guardar datos de la atencion'
    const msg2 = 'Datos guardados correctamente'
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
        reset()
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }

  const onConcluirCita = () => {
    // Cambiar estado de cita a concluido
    const updateCita = { ...dataCita, estado: 0 }
    mutate(updateCita, {
      onError: () => {
        showError('Ocurrió un error. Inténtelo de nuevo')
      },
      onSuccess: () => {
        showSuccess('Cita concluida exitosamente')
      }
    })
    navigate("/especialista/nuevasconsultas", { replace: true })
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div>
            Historia Clínica Nutricional
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={12} xs={12}>
                <DatosGenerales DNI={dataCita.nroDoc} triaje={especialidad.requiereTriaje} CamposPaciente={CamposPaciente} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider />
              </Grid>

              <Grid item md={12} xs={12}>
                <Input
                  control={control}
                  id='antecedentes'
                  label='Antecedentes personales y/o familiares'
                  multiline
                  rows={3}
                  error={!!errors.antecedentes}
                  helperText={!!errors.antecedentes ? errors.antecedentes.message : null}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Divider>Historia Dietética</Divider>
              </Grid>

              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='alergiaAlimento'
                  label='Alergia a algún alimento'
                  error={!!errors.alergiaAlimento}
                  helperText={!!errors.alergiaAlimento ? errors.alergiaAlimento.message : null}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='consumoAlimentos'
                  label='Consumo de alimentos'
                  type='select'
                  data={[
                    { value: 'casa', denominacion: 'Casa' },
                    { value: 'restaurant', denominacion: 'Restaurant' },
                  ]}
                  error={!!errors.consumoAlimentos}
                  helperText={!!errors.consumoAlimentos ? errors.consumoAlimentos.message : null}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='nroVecesCome'
                  label='Nro de veces que come'
                  type='select'
                  data={[
                    { value: '1', denominacion: '1' },
                    { value: '2', denominacion: '2' },
                    { value: '3', denominacion: '3' },
                    { value: '4', denominacion: '4' },
                    { value: '5', denominacion: '5' },
                  ]}
                  error={!!errors.nroVecesCome}
                  helperText={!!errors.nroVecesCome ? errors.nroVecesCome.message : null}
                />
              </Grid>

              <Grid container item spacing={2}>

                <Grid item md={12} xs={12}>
                  <Divider>Horario de comidas</Divider>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Input
                    control={control}
                    id='desayuno'
                    label='Desayuno'
                    type='time'
                    error={!!errors.desayuno}
                    helperText={!!errors.desayuno ? errors.desayuno.message : null}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Input
                    control={control}
                    id='almuerzo'
                    label='Almuerzo'
                    type='time'
                    error={!!errors.almuerzo}
                    helperText={!!errors.almuerzo ? errors.almuerzo.message : null}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Input
                    control={control}
                    id='cena'
                    label='Cena'
                    type='time'
                    error={!!errors.cena}
                    helperText={!!errors.cena ? errors.cena.message : null}
                  />
                </Grid>
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='tiempoComidas'
                  label='Tiempo que dedica a las comidas'
                  type='select'
                  data={[
                    { value: 'rapido', denominacion: 'Rápido (menos de 20 min)' },
                    { value: 'moderado', denominacion: 'Moderado (de 21 a 60 min)' },
                    { value: 'lento', denominacion: 'Lento (más de una hora)' },
                  ]}
                  error={!!errors.tiempoComidas}
                  helperText={!!errors.tiempoComidas ? errors.tiempoComidas.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='comidaPreferida'
                  label='Comida preferida'
                  error={!!errors.comidaPreferida}
                  helperText={!!errors.comidaPreferida ? errors.comidaPreferida.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='bebidaPreferida'
                  label='Bebida preferida'
                  error={!!errors.bebidaPreferida}
                  helperText={!!errors.bebidaPreferida ? errors.bebidaPreferida.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='habitosNocivos'
                  label='Hábitos nocivos'
                  error={!!errors.habitosNocivos}
                  helperText={!!errors.habitosNocivos ? errors.habitosNocivos.message : null}
                />
              </Grid>

              <Grid container item spacing={2} >

                <Grid item md={12} xs={12}>
                  <Divider>Actividad física</Divider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    control={control}
                    id='tipoActividadFisica'
                    label='Tipo de actividad física'
                    type='select'
                    data={[
                      { value: 'sedentarismo', denominacion: 'Sedentarismo' },
                      { value: 'leve', denominacion: 'Actividad física leve' },
                      { value: 'moderada', denominacion: 'Actividad física moderada' },
                      { value: 'intensa', denominacion: 'Actividad física intensa' },
                    ]}
                    error={!!errors.tipoActividadFisica}
                    helperText={!!errors.tipoActividadFisica ? errors.tipoActividadFisica.message : null}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    control={control}
                    id='tiempoDedica'
                    label='Tiempo que le dedica'
                    error={!!errors.tiempoDedica}
                    helperText={!!errors.tiempoDedica ? errors.tiempoDedica.message : null}
                  />
                </Grid>
              </Grid>

              <Grid item md={12} xs={12}>
                <Divider>Evaluación bioquímica</Divider>
              </Grid>

              <Grid item md={5} xs={12}>
                <Input
                  control={control}
                  id='examen'
                  label='Examen'
                  error={!!errors.examen}
                  helperText={!!errors.examen ? errors.examen.message : null}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  variant='contained'
                  size='large'
                  fullWidth
                  // onClick={() => setStateModalDiagnosticos({open:true, addItem:false})}
                  startIcon={<DescriptionRoundedIcon />}
                  sx={{
                    borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                  }}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Input
                  control={control}
                  id='resultado'
                  label='Resultado'
                  error={!!errors.resultado}
                  helperText={!!errors.resultado ? errors.resultado.message : null}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Divider>Evaluación nutricional</Divider>
              </Grid>

              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='pesoIdeal'
                  label='Peso ideal'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  }}
                  error={!!errors.pesoIdeal}
                  helperText={!!errors.pesoIdeal ? errors.pesoIdeal.message : null}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='circunferenciaMediaBrazo'
                  label='Circunferencia media del brazo'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  error={!!errors.circunferenciaMediaBrazo}
                  helperText={!!errors.circunferenciaMediaBrazo ? errors.circunferenciaMediaBrazo.message : null}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input
                  control={control}
                  id='circunferenciaCintura'
                  label='Circunferencia de cintura'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  error={!!errors.circunferenciaCintura}
                  helperText={!!errors.circunferenciaCintura ? errors.circunferenciaCintura.message : null}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='circunferenciaCadera'
                  label='Circunferencia de cadera'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  error={!!errors.circunferenciaCadera}
                  helperText={!!errors.circunferenciaCadera ? errors.circunferenciaCadera.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='porcentajeGrasaCorporal'
                  label='% Grasa corporal'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  error={!!errors.porcentajeGrasaCorporal}
                  helperText={!!errors.porcentajeGrasaCorporal ? errors.porcentajeGrasaCorporal.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='porcentajeGrasaViceral'
                  label='% Grasa viceral'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  error={!!errors.porcentajeGrasaViceral}
                  helperText={!!errors.porcentajeGrasaViceral ? errors.porcentajeGrasaViceral.message : null}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <Input
                  control={control}
                  id='porcentajeMusculo'
                  label='% Músculo'
                  type='number'
                  inputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  error={!!errors.porcentajeMusculo}
                  helperText={!!errors.porcentajeMusculo ? errors.porcentajeMusculo.message : null}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Input
                  control={control}
                  id='diagnosticoNutricional'
                  label='Diagnóstico nutricional'
                  multiline
                  rows={3}
                  error={!!errors.diagnosticoNutricional}
                  helperText={!!errors.diagnosticoNutricional ? errors.diagnosticoNutricional.message : null}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  control={control}
                  id='prescripcionReceta'
                  label='Prescripción o receta'
                  multiline
                  rows={3}
                  error={!!errors.prescripcionReceta}
                  helperText={!!errors.prescripcionReceta ? errors.prescripcionReceta.message : null}
                />
              </Grid>

              <Grid item container justifyContent="space-around">
                <Grid item md={4}>
                  <Button variant='outlined' fullWidth
                    onClick={() => navigate(-1)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item md={4}>
                  <Button type='submit' variant='contained' fullWidth>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

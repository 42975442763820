import { Grid } from '@mui/material'
import { DataGridStyle } from '../../../components/DataGridStyle'
import CreatePerfiles from './components/CreatePerfiles'
import UpdatePerfiles from './components/UpdatePerfiles'
import DeletePerfiles from './components/DeletePerfiles'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useGetAllPerfiles } from '../../../hooks/usePerfiles'

const columns = [

  {
    headerClassName: 'super-app-theme--header ',
    cellClassName: 'uppercase',
    field: 'perfilNombre',
    headerName: 'Nombre',
    width: 210,
    type: 'string',

  },
  {
    headerClassName: 'super-app-theme--header ',
    cellClassName: 'uppercase',
    field: 'descripcion',
    headerName: 'Descripción',
    minWidth: 400,
    flex: 0.1,
    type: 'string',

  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accions',
    width: 200,
    getActions: ({ row }, index) => {
      return [
        <UpdatePerfiles key={index} id={row.perfilCodigo} />,
        <DeletePerfiles key={index} id={row.perfilCodigo} />
      ]
    },
  }
]

export const Perfiles = () => {
  const { data: perfiles, isLoading } = useGetAllPerfiles()
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems={'center'}>
            <Grid item>
              Pefiles
            </Grid>
            <Grid item>
              <CreatePerfiles />
            </Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={380}
                  rows={perfiles || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.perfilCodigo}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>

      </ContentMainBox>
    </>
  )
}


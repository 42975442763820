import React, { useEffect, useState } from 'react'
import ContentMainBox from '../../../components/MainBox'
import Grid from '@mui/material/Grid'
import Input from '../../../components/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, CircularProgress, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { useCreateCita, useGetCitasByIdEspecialista } from '../../../hooks/useCitas'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'
import { useAlertMessage } from '../../../context/notifications'
import { useGetAllHorarioDetalle } from '../../../hooks/useHorarioDet'
import { useGetPacienteByNroDoc } from '../../../hooks/usePacientes'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const CitaSchema = yup.object({
  nroDocPaciente: yup
    .string()
    .length(8, 'Debe tener 8 dígitos')
    .required('Campo requerido'),
  especialidad: yup
    .string()
    .required('Campo requerido'),
  especialista: yup
    .string()
    .required('Campo requerido'),
  fechaCita: yup
    .date()
    .required('Campo requerido'),
})

export const GenerarCitaEnfermera = () => {
  const { showError, showSuccess } = useAlertMessage()
  const { mutate } = useCreateCita()

  const [paciente, setPaciente] = useState('')
  const [nroDocPaciente, setNroDocPaciente] = useState('')
  const [especialidad, setEspecialidad] = useState('')
  const [especialista, setEspecialista] = useState('')
  const [horarioDet, setHorarioDet] = useState()

  const date = new Date().toLocaleDateString('sv',{timeZone: 'America/Lima'}) //yyyy-mm-dd
  const [fechaCita, setFechaCita] = useState(date);

  let { data: Especialistas } = useGetAllEspecialista() || []
  let { data: Especialidades } = useGetAllEspecialidad() || []
  let { data: HorarioDetalle } = useGetAllHorarioDetalle() || []
  let { data: CitasByEsp, refetch: refetchCitas } = useGetCitasByIdEspecialista(especialista, false) || []
  const Citas = CitasByEsp ? CitasByEsp : []
  let { data: Paciente, refetch: refetchPaciente, isSuccess, isError, isLoading } = useGetPacienteByNroDoc(nroDocPaciente, false)

  useEffect(() => {
    setPaciente(Paciente?.idPaciente)
  }, [Paciente?.idPaciente])

  useEffect(() => {
    refetchCitas()
  }, [especialista])

  const { handleSubmit, register, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(CitaSchema),
    defaultValues: {
      'fechaCita': fechaCita
    }
  })

  const tranformarDia = (fecha) => {
    let diaLetra = new Date(fecha).getDay()
    if (diaLetra === 0) return 'lunes'
    if (diaLetra === 1) return 'martes'
    if (diaLetra === 2) return 'miercoles'
    if (diaLetra === 3) return 'jueves'
    if (diaLetra === 4) return 'viernes'
    else return 'feriado'
  }
  const [dia, setDia] = useState(tranformarDia(fechaCita));
  const [ahora, setAhora] = useState(false)

  const comboHorariosDet = HorarioDetalle
    ? HorarioDetalle.flatMap(({ horarioDetalle: horarioDet, idEspecialista }) => {
      return horarioDet.map((item) => ({
        horarioDetId: item.idHorarioDet,
        diaHorario: item.dia,
        hora: item.horaIni,
        especialistaId: idEspecialista
      }))
    })
    : [
      {
        horarioDetId: 1,
        diaHorario: 'lunes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
      {
        horarioDetId: 2,
        diaHorario: 'martes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
    ]

  const comboEspecialistas =
    Especialistas ?
      Especialistas.map((especialista) => {
        return {
          especialistaId: especialista.idEspecialista,
          denominacion: especialista.nombres,
          especialidadId: especialista.idEspecialidad
        }
      }) : [
        {
          especialistaId: 1,
          denominacion: 'Juan Bautista'
        },
        {
          especialistaId: 1,
          denominacion: 'Jose Parra'
        },
      ]

  const comboEspecialidades =
    Especialidades ?
      Especialidades.map((especialidad) => {
        return { especialidadId: especialidad.idEspecialidad, denominacion: especialidad.desEspecialidad }
      }) : [
        {
          especialidadId: 1,
          denominacion: 'Odontología'
        },
        {
          especialidadId: 2,
          denominacion: 'Psicología'
        },
      ]

  const onSubmit = () => {
    const formData = {
      idPaciente: paciente,
      idEspecialista: especialista,
      idHorarioDet: horarioDet,
      fechaCita: fechaCita,
      estado: 1
    }
    const msg = 'No se pudo crear Cita'
    const msg2 = 'Cita creada correctamente'
    console.log(formData)
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
      }
    })
  }

  const newHorasDisponibles = comboHorariosDet.filter((horarioDet) => {
    if (fechaCita !== '' && especialista !== '') {
      if (horarioDet.diaHorario === dia && horarioDet.especialistaId === especialista) {
        const result = Citas.find((cita) =>
          cita.idHorarioDet === horarioDet.horarioDetId && cita.fechaCita.slice(0, 10) === fechaCita);
        if (result === undefined) return horarioDet
      }
    }
    else {
      return horarioDet
    }
  })

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Solicitar cita
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className='mt-2'
            >
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Nro. Documento'
                      size='small'
                      type='number'
                      InputProps={{
                        readOnly: false,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={refetchPaciente}>
                              <SearchRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register('nroDocPaciente', {
                        onChange: ({ target: { value } }) => (value.length >= 7) && setNroDocPaciente(value)
                      })}
                      error={!!errors.nroDocPaciente}
                      helperText={!!errors.nroDocPaciente ? errors.nroDocPaciente.message : null}
                    />
                  </Grid>

                  {
                    isSuccess &&
                    <Grid item xs={12} className='ml-4 -mt-1 mb-2' sx={{
                      ...(Paciente.nombres === undefined && { display: 'none' })
                    }}>
                      <span className='font-bold'>{'Paciente: '}</span>
                      <span>{`${Paciente?.nombres} ${Paciente?.apPaterno} ${Paciente?.apMaterno}`}</span>
                    </Grid>
                  }
                  {
                    isLoading &&
                    <Grid item xs={12} className='flex -mt-1 mb-2 items-center gap-2'>
                      <CircularProgress size={15} sx={{ color: '#707070', ml: 2 }} />
                      <span className='text-[#707070] text-sm'>Buscando...</span>
                    </Grid>
                  }
                  {
                    isError &&
                    <Grid item xs={12} className='ml-4 -mt-2 mb-2 text-xs text-[#d32f2f]'>
                      No se encontró el número de documento
                    </Grid>
                  }

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='especialidadId'
                      label='Especialidad'
                      select
                      value={especialidad}
                      defaultValue=''
                      disabled={!paciente}
                      size="small"
                      {...register('especialidad', {
                        onChange: (e) => setEspecialidad(e.target.value)
                      })}
                      error={!!errors.especialidad}
                      helperText={!!errors.especialidad ? errors.especialidad.message : null}
                    >
                      {comboEspecialidades.map((option) => (
                        <MenuItem key={option.especialidadId} value={option.especialidadId}>
                          {option.denominacion}
                        </MenuItem>
                      ))}
                    </TextField>

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='especialistaId'
                      label='Especialista'
                      select
                      defaultValue=''
                      value={especialista}
                      size="small"
                      disabled={especialidad === '' ? true : false}
                      {...register('especialista', {
                        onChange: (e) => setEspecialista(e.target.value)
                      })}
                      error={!!errors.especialista}
                      helperText={!!errors.especialista ? errors.especialista.message : null}
                    >
                      {comboEspecialistas.filter((unidad) => {
                        if (especialidad !== '') {
                          if (unidad.especialidadId === especialidad) {
                            return unidad
                          }
                        }
                        else {
                          return unidad
                        }
                      }).map((option, index) => (
                        <MenuItem key={index} value={option.especialistaId}>
                          {option.denominacion}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>

                    <TextField
                      fullWidth
                      size='small'
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                      value={fechaCita}
                      disabled={especialista === '' ? true : false}
                      {...register('fechaCita', {
                        onChange: (e) => {
                          setFechaCita(e.target.value)
                          setDia(tranformarDia(e.target.value))
                        }
                      })}
                      error={!!errors.horarioDet}
                      helperText={!!errors.horarioDet ? errors.horarioDet.message : null}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                    >
                      {(especialista != '') ? (newHorasDisponibles.length === 0
                        ? <Typography variant='body1' className='mx-auto'>No hay horarios disponibles para este día</Typography>
                        : newHorasDisponibles.map((option, i) => (
                          <Grid item xs={2} key={i}>
                            <Button
                              onClick={() => setHorarioDet(option.horarioDetId)}
                              variant={horarioDet === option.horarioDetId ? 'contained' : 'outlined'}
                              color='primary'
                              size='large'
                            >
                              {option.hora}
                            </Button>
                          </Grid>
                        ))) :
                        <></>}
                      {
                        (especialista != '') &&
                        ((newHorasDisponibles.length !== 0 && dia !== 'feriado') &&
                          <Grid item xs={2}>
                            <Button
                              size='large'
                              onClick={() => {
                                setAhora(true)
                                setHorarioDet()
                              }}
                              variant={ahora && !horarioDet ? 'contained' : 'outlined'}
                            >
                              Ahora
                            </Button>
                          </Grid>
                        )
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color='primary'
                      type='submit'
                      disabled={!paciente || !especialidad || !especialista || !fechaCita || !horarioDet && !ahora}
                    >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
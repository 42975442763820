import { axiosClient } from "../api/axios";
import { usePath } from '../hooks/usePath';

export const GetByIdEspecialista = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEspecialista, tipoConsulta] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/especialista/${idEspecialista}?TipoConsulta=${tipoConsulta}`)
    return data
}
export const GetCitaPendiente = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEspecialista, idPaciente] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/especialista/CitaPendiente?IdEspecialista=${idEspecialista}&IdPaciente=${idPaciente}`)
    return data
}
export const GetCitaPasada = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEspecialista, Dni, fechaInicial, fechaFinal, nEstado] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/especialista/CitaPasada?IdEspecialista=${idEspecialista}&Dni=${Dni}&FechaInicial=${fechaInicial}&FechaFinal=${fechaFinal}&nEstado=${nEstado}`)
    return data
}

export const GetByIdEnfermeraTriajeAtendido = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEnfermera, idEspecialidad, fechaFinal, fechaInicial] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/enfermera/TriajeAtendido/${idEnfermera}?IdEspecialidad=${idEspecialidad}&FechaInicial=${fechaInicial}&FechaFinal=${fechaFinal}`)
    return data
}
export const GetByIdEnfermeraTriajePendiente = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEnfermera, idEspecialidad] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/enfermera/TriajePendiente/${idEnfermera}?IdEspecialidad=${idEspecialidad}`)
    return data
}

export const GetAdministrador = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idEspecialista, idEspecialidad, fechaFinal, fechaInicial] = queryKey

    const searchParams = new URLSearchParams()
    idEspecialista && searchParams.append('IdEspecialista', idEspecialista)
    idEspecialidad && searchParams.append('IdEspecialidad', idEspecialidad)
    fechaInicial && searchParams.append('FechaInicial', fechaInicial)
    fechaFinal && searchParams.append('FechaFinal', fechaFinal)
    
    console.log(searchParams.toString())

    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/Administrador?${searchParams.toString()}`)
    return data
}
export const GetByIdPaciete = async ({ queryKey }) => {
    const { full_path: fullPath } = usePath()
    const [_key, idPaciente] = queryKey
    const { data } = await axiosClient.get(`${fullPath}/bienestar/CitaMedica/${idPaciente}`)
    return data
}

export const Create = async (body) => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.post(`${fullPath}/bienestar/CitaMedica`, body)
    return data
}

export const Update = async (idCitaMedica, body) => {
    const { full_path: fullPath } = usePath()
    const { data } = await axiosClient.put(`${fullPath}/bienestar/CitaMedica/${idCitaMedica}`, body)
    return data
}

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createCita, getCitasEspecialista, getCitasPaciente, getHorariosDisponibles } from '../../services/back-clinica/citas'

const key = 'citas'

export const useGetCitasEspecialista = ({
  idEspecialista,
  fechaInicio = '',
  fechaFin = '',
  dniPaciente = '',
  estado = ''
}, enabled = true) =>
  useQuery([key, { idEspecialista, fechaInicio, fechaFin, dniPaciente, estado }], getCitasEspecialista, {
    enabled
  })

export const useGetCitasPaciente = ({
  idPaciente,
  fechaInicio,
  fechaFin,
  idEspecialidad,
}, enabled = true) =>
  useQuery([key, idPaciente, fechaInicio, fechaFin, idEspecialidad], getCitasPaciente, {
    enabled
  })


export const useGetHorariosDisponibles = ({
  fechaCita,
  idEspecialista
}, enabled = true) =>
  useQuery([key, fechaCita, idEspecialista], getHorariosDisponibles, {
    enabled
  })

export const useCreateCita = () => {
  const queryClient = useQueryClient()
  return useMutation(body => createCita(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
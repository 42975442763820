import { LoadingButton } from '@mui/lab'
import html2canvas from 'html2canvas'
import pdfMake from 'pdfmake'
import { useRef, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
export const useCreatePdf = () => {
    const refHtml = useRef(null)
    const [printing, setPrinting] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const genPdf = (print = false) => {
        const documentPdf = refHtml?.current
        print ? setPrinting(true) : setDownloading(true)
        html2canvas(documentPdf).then(function (canvas) {
            const imgObj = {
                image: canvas.toDataURL(),
                width: 800,
                style: {
                    alignment: "center",
                }
            }
            const documentDefinition = {
                content: [imgObj],
                pageSize: "A4",
                pageOrientation: "landscape",
                pageMargins: [40, 60, 40, 60]
            }
            const pdfDocGenerator = pdfMake.createPdf(documentDefinition)
            setPrinting(false)
            setDownloading(false)
            print ? pdfDocGenerator.print() : pdfDocGenerator.download()
        })
    }

    const DownloadButton = () => (
        <LoadingButton
            loading={downloading}
            loadingPosition='start'
            startIcon={<FileDownloadRoundedIcon />}
            variant='contained'
            onClick={() => genPdf()}
        >
            Descargar
        </LoadingButton>
    )
    const PrintButton = () => (
        <LoadingButton
            loading={printing}
            loadingPosition='start'
            startIcon={<LocalPrintshopRoundedIcon />}
            variant='contained'
            onClick={() => genPdf(true)}
        >
            Imprimir
        </LoadingButton>
    )

    return {
        refHtml,
        printing,
        downloading,
        DownloadButton,
        PrintButton,
        genPdf
    }
}

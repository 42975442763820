import EspecialidadForm from './FormEspecialidad'
import { useUpdateEspecialidad } from '../../../../hooks/useEspecialidades'
import OpenButton from './OpenButton'

const UpdateEspecialidad = ({ id }) => {
  const { mutate, isLoading } = useUpdateEspecialidad(id)
  return (
    <>
      <OpenButton edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdateEspecialidad

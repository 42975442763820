import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContentMainBox from '../../../components/MainBox'
import { DataGridStyle } from '../../../components/DataGridStyle'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useGetAllPaciente } from '../../../hooks/usePacientes';
import Loading from '../../../components/Loading';
import { VerCitas } from './components/VerCitas';
import { ApplyFilters, AutoCompletePaciente, Filter, FilterAutoCompleteCampo, FilterButtons, FilterTipoPaciente, useFilterByPaciente, useFilterByPacienteEscuelaProfesional, useFilterByPacienteNroDoc, useFilterByTipoPaciente } from '../../../components/Filters';
const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'nroDoc',
    cellClassName: 'uppercase',
    headerName: 'N° Doc',
    type: 'string',
    headerAlign: 'center',
    align: 'center'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'paciente',
    cellClassName: 'uppercase',
    headerName: 'Paciente',
    width: 300,
    type: 'string',
    headerAlign: 'center',
    align: 'center'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'escuelaProfesional',
    headerName: 'Escuela Profesional',
    cellClassName: 'uppercase',
    width: 300,
    type: 'string',
    headerAlign: 'center',
    align: 'center'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'tipoPaciente',
    cellClassName: 'uppercase',
    headerName: 'Tipo Paciente',
    width: 200,
    type: 'string',
    headerAlign: 'center',
    align: 'center'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'historiaClinica',
    headerName: 'Historia Clínica',
    width: 200,
    renderCell: ({ row }) => {
      const [open, setOpen] = useState(false)
      const handleOpen = () => {
        setOpen(true)
      }
      const handleClose = () => {
        setOpen(false)
      }
      return (
        <>
          <VerCitas
            open={open}
            data={row}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
          <Tooltip title='historia clínica'>
            <IconButton
              aria-label='historiaClinica'
              onClick={handleOpen}>
              <FolderOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>

      )
    },
    headerAlign: 'center',
    align: 'center'
  }
]
const rows = [
  {
    idPaciente: 1,
    nroDoc: '74235184',
    paciente: 'Choque Bueno Fiorella Silvia',
    escuelaProfesional: 'Ing. Informática',
    historiaClinica: '',
  }
]
export const Pacientes = () => {

  const { data: pacientes, isLoading } = useGetAllPaciente();
  const comboPacientes =
    pacientes ?
      pacientes.map((paciente) => {
        return { nroDoc: paciente.nroDoc, idPaciente: paciente.idPaciente, paciente: paciente.nombres + ' ' + paciente.apPaterno + ' ' + paciente.apMaterno, tipoPaciente: paciente.tipoPaciente, escuelaProfesional: paciente.nombres }
      }) : []
  //Eliminar valores TipoPaciente repetidos 
  const pacientesMap = comboPacientes.map(item => {
    return [item.tipoPaciente.toUpperCase(), item]
  })

  const tipoPacienteMap = new Map(pacientesMap)
  const valorUnicoTipoPaciente = [...tipoPacienteMap.values()]
  const { filterByTipoPaciente, tipoPaciente, setTipoPaciente } = useFilterByTipoPaciente()
  const { filterByPaciente, paciente, setPaciente } = useFilterByPaciente()
  const { filterByPacienteNroDoc, nroDoc, setPacienteNroDoc } = useFilterByPacienteNroDoc()
  const { filterByPacienteEscuelaProfesional, escuelaProfesional, setPacienteEscuelaProfesional } = useFilterByPacienteEscuelaProfesional()
  const filters = [
    {
      name: 'tipoPaciente',
      filter: filterByTipoPaciente,
      filterValue: tipoPaciente,
      setFilterValue: setTipoPaciente
    },
    {
      name: 'nroDoc',
      filter: filterByPacienteNroDoc,
      filterValue: nroDoc,
      setFilterValue: setPacienteNroDoc
    },
    {
      name: 'paciente',
      filter: filterByPaciente,
      filterValue: paciente,
      setFilterValue: setPaciente
    },
    {
      name: 'escuelaProfesional',
      filter: filterByPacienteEscuelaProfesional,
      filterValue: escuelaProfesional,
      setFilterValue: setPacienteEscuelaProfesional
    },
  ]
  const clearFilters = () => {
    setPaciente('')
    setTipoPaciente('')
    setPacienteNroDoc('')
    setPacienteEscuelaProfesional('')
    // setMaxDate('')
  }
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Pacientes
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <ApplyFilters
                data={comboPacientes}
                filters={filters}
                renderItem={() =>
                  <Loading isLoading={isLoading}>
                    <DataGridStyle
                      height={380}
                      rows={comboPacientes}
                      columns={columns}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.idPaciente}
                    />
                  </Loading>
                }
              >
                <Grid item xs={12} md={5}>
                  <FilterAutoCompleteCampo label='EscuelaProfesional' data={comboPacientes} setValue={setPacienteEscuelaProfesional} value={escuelaProfesional} campo="escuelaProfesional" />
                </Grid>
                <Grid item xs={12} md={5}>
                  <FilterTipoPaciente label='TipoPaciente' data={valorUnicoTipoPaciente} setValue={setTipoPaciente} value={tipoPaciente} filterKey="tipoPaciente" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FilterButtons clearFilters={clearFilters} />
                </Grid>
                {/* <Grid item xs={5}>
                <FilterAutoCompleteCampo label='DNI' data={comboPacientes} setValue={setPacienteNroDoc} value={nroDoc} campo="nroDoc" />
              </Grid> */}
                <Grid item xs={12}>
                  <AutoCompletePaciente setValue={setPaciente} value={paciente} />
                </Grid>
              </ApplyFilters>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import { useState } from 'react'
import {
  Button,
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  DialogContentText,
  IconButton
} from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { useDeletePaciente } from '../../../../hooks/usePacientes'

const EliminarPaciente = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { mutate: deletePaciente } = useDeletePaciente(data.id)

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const onSubmit = () => {
    deletePaciente()
    handleClose()
  }

  return (
    <>
      <Tooltip title='Eliminar Paciente'>
          <IconButton aria-label='delete' onClick={handleClickOpen}>
            <DeleteRoundedIcon />
          </IconButton>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle>ELIMINAR Paciente</DialogTitle> */}
        <DialogContent >
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <b>¿Está seguro que quiere eliminar el registro: {data?.nombres}?</b>
          <br />Una vez eliminado el registro, no podrás recuperar la información registrada.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            REGRESAR
          </Button>
          <Button variant='contained' onClick={onSubmit}>
            SI, ELIMINAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EliminarPaciente

import { Grid, MenuItem, TextField } from '@mui/material'
import React, { useCallback, useEffect, useState, useTransition } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useGetCitasAdministrador } from '../../../hooks/useCitaMedica'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Fecha',
    field: 'fecha',
    width: 120,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Hora',
    field: 'hora',
    width: 120,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Especialidad',
    field: 'especialidad',
    cellClassName: 'uppercase',
    width: 300,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Paciente',
    field: 'paciente',
    cellClassName: 'uppercase',
    width: 300,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Estado',
    field: 'estadoCita',
    cellClassName: 'uppercase',
    width: 150,
    type: 'string',
  }
]

const now = new Date().toISOString().split('T')[0]

export const Citas = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [isPending, startTransition] = useTransition()
  const idEspecialidad = searchParams.get('especialidad')
  const idEspecialista = searchParams.get('especialista')
  const fechaInicial = searchParams.get('fechaInicial') || now
  const fechaFinal = searchParams.get('fechaFinal') || now

  const { data: especialidades } = useGetAllEspecialidad()
  const { data: citas, isLoading } = useGetCitasAdministrador(idEspecialista, idEspecialidad, fechaInicial, fechaFinal) || []

  const [dateRange, setDateRange] = useState([
    fechaInicial,
    fechaFinal,
  ])

  const debouncedDateRange = useDebounce(dateRange, 500)

  const createQueryString = useCallback((params) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    for (const [key, value] of Object.entries(params)) {
      if (value === null || value === undefined || value === '') {
        newSearchParams.delete(key)
      } else {
        newSearchParams.set(key, value)
      }
    }

    return newSearchParams.toString()
  }, [searchParams])


  useEffect(() => {
    const [fechaInicial, fechaFinal] = debouncedDateRange
    startTransition(() => {
      const newQueryString = createQueryString({
        fechaInicial,
        fechaFinal,
      })

      navigate(`${pathname}?${newQueryString}`, {
        scroll: false,
      })
    })
  }, [debouncedDateRange])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item>Citas</Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2.5} >
              <TextField
                label="Especialidades"
                size="small"
                select
                defaultValue={idEspecialidad}
                onChange={(e) => {
                  const newSearchParams = createQueryString({
                    "especialidad": e.target.value,
                  })

                  navigate(`${pathname}?${newSearchParams.toString()}`, {
                    scroll: false,
                  })
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">TODOS</MenuItem>
                {
                  especialidades?.map((especialidad) => (
                    <MenuItem key={especialidad.idEspecialidad} value={especialidad.idEspecialidad}>{especialidad.desEspecialidad}</MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5} >
              <TextField
                label="Fecha Inicial"
                type="date"
                size="small"
                defaultValue={fechaInicial}
                onChange={(e) => setDateRange((prev) => [e.target.value, prev[1]])}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label="Fecha Final"
                type="date"
                size="small"
                defaultValue={fechaInicial}
                onChange={(e) => setDateRange((prev) => [prev[0], e.target.value])}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={500}
                  rows={citas || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.id}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox >
    </>
  )
}

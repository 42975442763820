import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Grid, TextField } from '@mui/material';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DatosGenerales } from '../../../components/DatosGenerales';
import ContentMainBox from '../../../components/MainBox';
import { EspecialidadContext } from '../../../context/especialidadContext';

const planAtencionSchema = yup.object({
  motivoConsulta: yup
    .string()
    .required('Ingresar motivo de consulta'),
  diagnosticos: yup
    .string()
    .required('Ingresar diagnósticos'),
  instruccionMedica: yup
    .string()
    .required('Ingresar instrucción médica'),
  recetaMedica: yup
    .string()
    .required('Ingresar receta médica'),
  vacunasCompletas: yup
    .string()
    .required('Ingresar vacunas completas'),
  examenFisico: yup
    .string()
    .required('Ingresar examen físico'),
})

export const PlanAtencion = () => {

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(planAtencionSchema),
  })

  // const {data : dataTriaje} = useGetByIdTriaje('USER_ID')
  // const [showTriaje, setShowTriaje] = useState(true)
  const {especialidad} = useContext(EspecialidadContext)
  const onSubmit = (data) => {
    const formData = {
      motivoConsulta: data.motivoConsulta,
      diagnosticos: data.diagnosticos,
      instruccionMedica: data.instruccionMedica,
      recetaMedica: data.recetaMedica,
      vacunasCompletas: data.vacunasCompletas,
      examenFisico: data.examenFisico,
    }
    console.log(formData)

    const msgError = 'No se pudo guardar los datos'
    const msgSuccess = 'Datos guardados correctamente'
    mutate(formData, {
      onError: () => {
        showError(msgError)
        reset()
      },
      onSuccess: () => {
        showSuccess(msgSuccess)
        reset()
      }
    })
  }
  const CamposPaciente=[
  'nombres',
  'apellidos',
  'nroDoc',
  'fechaNacimiento',
  'direccion',
  'celular',
  'sexo',
  'edad',
  'procedencia',
  'carreraProfesional',
  'codigoEstudiante'

]
  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <div>Plan de atención</div>
      </ContentMainBox.Title>
      <ContentMainBox.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            {/* <DatosGenerales DNI={41526321} triaje={especialidad.requiereTriaje} CamposPaciente={CamposPaciente}/>  */}
            </Grid>
            <Grid item xs={12} className='-mt-1'>
              <Divider />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <TextField
                label='Motivo de consulta'
                size='small'
                multiline
                rows={3}
                fullWidth
                {...register('motivoConsulta')}
                error={!!errors.motivoConsulta}
                helperText={!!errors.motivoConsulta ? errors.motivoConsulta.message : null}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <TextField
                label='Diagnósticos'
                size='small'
                multiline
                rows={2}
                fullWidth
                {...register('diagnosticos')}
                error={!!errors.diagnosticos}
                helperText={!!errors.diagnosticos ? errors.diagnosticos.message : null}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label='Instrucción médica'
                size='small'
                fullWidth
                {...register('instruccionMedica')}
                error={!!errors.instruccionMedica}
                helperText={!!errors.instruccionMedica ? errors.instruccionMedica.message : null}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label='Receta médica'
                size='small'
                fullWidth
                {...register('recetaMedica')}
                error={!!errors.recetaMedica}
                helperText={!!errors.recetaMedica ? errors.recetaMedica.message : null}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label='Vacunas completas'
                size='small'
                fullWidth
                {...register('vacunasCompletas')}
                error={!!errors.vacunasCompletas}
                helperText={!!errors.vacunasCompletas ? errors.vacunasCompletas.message : null}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <TextField
                label='Examen físico'
                size='small'
                fullWidth
                {...register('examenFisico')}
                error={!!errors.examenFisico}
                helperText={!!errors.examenFisico ? errors.examenFisico.message : null}
              />
            </Grid>
            <Grid item container justifyContent='space-evenly' rowSpacing={2}>

              <Grid item md={2} sm={3} xs={12}>
                <Button variant='contained' fullWidth color='error' onClick={() => reset()}>
                  Cancelar
                </Button>
              </Grid>

              <Grid item md={2} sm={3} xs={12}>
                <Button type='submit' variant='contained' fullWidth>
                  Guardar
                </Button>
              </Grid>


            </Grid>
          </Grid>
        </form>
      </ContentMainBox.Content>
    </ContentMainBox>
  )
}

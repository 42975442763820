import { useTheme } from '@emotion/react';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Hidden, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AccesosContext } from '../../context/accesosContext';
import { UserContext } from '../../context/userContext';
import { groupAccesos } from '../../utils';
import { Icons, RolIcon } from './icons';

export default function SidebarMobile({ handleDrawerClose, open, setOpen }) {
  const { getMenu } = useContext(AccesosContext)

  const accesos = groupAccesos(getMenu().map(item => {
    const { acceso, ...other } = item
    return { ...acceso, ...other }
  }))

  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const theme = useTheme()
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const [expanded, setExpanded] = React.useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangeOnClose = (panel) => (event, newExpanded) => {
    setOpen(!open)
    setExpanded(newExpanded ? panel : false)
  }

  const list = () => (
    <Box
      sx={{ width: 240 }}
      role="presentation"
    >

      <List className='bg-[#F2F4F4]'>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
          sx={{ opacity: open ? 1 : 0 }}

        >
          <Grid item xs={10}>
            <img src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png" alt="foto de perfil"></img>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', color: '#217E93' }} variant="h5">{user.rol}</Typography>
          </Grid>
        </Grid>
        {/* {Object.keys(accesos).map((rol, index) => {
          const Icon = RolIcon[rol] ?? RolIcon['default']

          return (
            <Accordion
              key={index}
              expanded={open ? expanded === index : false}
              onChange={handleChange(index)}
              sx={{ opacity: open ? 1 : 0 }}
            >
              <AccordionSummary
                expandIcon={
                  open &&
                  <ArrowForwardIosSharp sx={{ color: '#217E93' }} />
                }
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Tooltip title={rol?.toUpperCase()}>
                    <Icon sx={{ color: '#217E93' }} />
                  </Tooltip>
                  {
                    open &&
                    <Typography color={'title'} sx={{ fontWeight: 'bold', color: '#217E93' }}>{rol?.toUpperCase()}</Typography>
                  }
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {
                    accesos[rol].map((element, i) => (
                      element.menu === 1 && <Link
                        to={`/${rol}/${element.ruta}`}
                        className='no-underline'
                        style={{ color: theme.palette.text.primary }}
                        key={i}
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        <ListItemButton
                          sx={{
                            maxHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5
                          }}
                        >
                          <ListItemIcon
                            className='text-[#217E93]'
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center'
                            }}
                          >
                            <Tooltip title={element?.nombreEtiqueta}>
                              {
                                Icons[element?.iconoAcceso] ?? Icons['default']
                              }
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText
                            className='text-[#217E93]'
                            primary={element?.nombreEtiqueta}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Link>
                    ))
                  }
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        })} */}
        {
            Object.keys(accesos).map((rol, index) => {
              const Icon = RolIcon[rol] ?? RolIcon['default'];

              return (
                <Grid key={index} >
                  <Box sx={{ display:!open? 'none':'flex', justifyContent: 'center',alignItems: 'center', gap: 2 ,marginBottom:2}}>
                    <Tooltip title={rol?.toUpperCase()}>
                      <Icon sx={{ color: '#217E93' }} />
                    </Tooltip>
                    <Typography color={'title'} sx={{ fontWeight: 'bold', color: '#217E93' }}>
                      {rol?.toUpperCase()}
                    </Typography>
                  </Box>

                  <ul>
                    {accesos[rol].map((element, i) => (
                      element.menu === 1 && (
                        <Link
                          to={`/${rol}/${element.ruta}`}
                          className='no-underline'
                          style={{ color: theme.palette.text.primary }}
                          key={i}
                          
                        >
                          <li>
                            <ListItemButton
                            onClick={toggleDrawer(false)}
                              sx={{
                                maxHeight: 48,
                                justifyContent: 'initial',
                                px: 2.5,
                                display: 'flex',
                              }}
                            >
                              <ListItemIcon
                                className='text-[#217E93]'
                                sx={{
                                  minWidth: 0,
                                  mr: 3,
                                  justifyContent: 'center',
                                }}
                              >
                                <Tooltip title={element?.nombreEtiqueta}>
                                  {Icons[element?.iconoAcceso] ?? Icons['default']}
                                </Tooltip>
                              </ListItemIcon>
                              <ListItemText
                                className='text-[#217E93]'
                                primary={element?.nombreEtiqueta}
                              />
                            </ListItemButton>
                          </li>
                        </Link>
                      )
                    ))}
                  </ul>
                </Grid>
              );
            })
          }
      </List>
      <Divider />
      <List
        sx={{
          display: 'block',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Link
          to={'/login'}
          className='no-underline'
          style={{ color: theme.palette.text.primary }}
        >
          <ListItemButton
            sx={{
              maxHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}
            onClick={() => {
              localStorage.removeItem('token')
              localStorage.removeItem('_p')
              localStorage.removeItem('dni')
              navigate('/login')
            }}
          >
            <ListItemIcon
              className='text-[#217E93]'
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <Tooltip title='Cerrar sesión'>
                <ExitToAppRoundedIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              className='text-[#217E93]'
              primary='Cerrar sesión'
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      </List>
    </Box >
  );

  return (
    <div>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor='left'
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
}
import EditIcon from '@mui/icons-material/Edit'
import {
  IconButton,
  Tooltip
} from '@mui/material'
import { useState } from 'react'
import ButtonAdd from '../../../../components/ButtonAdd'
import DialogFormPerfiles from './DialogFormPerfiles'

const FormPerfiles = ({ edit, id, mutate, isLoading }) => {

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  return (
    <>
      {edit ? (
        <Tooltip title='Editar Perfil'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      {
        open && <DialogFormPerfiles open={open} handleClose={handleClose} edit={edit} id={id} mutate={mutate} isLoading={isLoading} />
      }
    </>
  )
}

export default FormPerfiles;
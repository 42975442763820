import { Routes, Route } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import Inicio from './inicio'
import { pacienteNavConfig } from './layoutPaciente'
import { MisCitas } from './MisCitas'
import { PerfilClinico } from './PerfilClinico'
import { SolicitarCita } from './SolicitarCita'
import { ProtectedRoute } from '../../components/ProtectedRoute'


const PacienteDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={pacienteNavConfig} />}>
        <Route path='/inicio' element={<ProtectedRoute />} >
          <Route index element={<Inicio />} />
        </Route>
        <Route path='perfilclinico' element={<ProtectedRoute />}>
          <Route index element={<PerfilClinico />} />
        </Route>
        <Route path='solicitarcita' element={<ProtectedRoute />}>
          <Route index element={<SolicitarCita />} />
        </Route>
        <Route path='miscitas' element={<ProtectedRoute />}>
          <Route index element={<MisCitas />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default PacienteDashboard

import FormEspecialista from './EspecialistaForm'
import { useCreateEspecialista } from '../../../../hooks/useEspecialistas'

const CreateEspecialista = () => {
  const { mutate, isLoading } = useCreateEspecialista()

  return (
    <>
      <FormEspecialista edit={false} mutate={mutate} data={''} isLoading={isLoading} />
    </>
  )
}

export default CreateEspecialista

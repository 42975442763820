import { Grid } from '@mui/material'
import CreateFormatos from './components/CreateFormatos'
import UpdateFormatos from './components/UpdateFormatos'
import DeleteFormatos from './components/DeleteFormatos'
import { useGetAllByEspecialidad } from '../../../hooks/useFormatos'
import ContentMainBox from '../../../components/MainBox'
import Input from '../../../components/Input'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import UpdateEstado from './components/UpdateEstado'
import { useLocation } from 'react-router-dom'
import { ButtonCreateFormatoDetalle } from './components/ButtonCreateFormatoDetalle'

const columns = [
    {
        headerClassName: 'super-app-theme--header ',
        field: 'nombreFormato',
        headerName: 'Nombre Formato',
        minWidth: 300,
        flex: 0.5,
        type: 'string',
    },
    {
        headerClassName: 'super-app-theme--header',
        cellClassName: 'uppercase',
        field: 'Estado',
        headerName: 'Estado',
        minWidth: 100,
        flex: 0.5,
        renderCell: ({ row }) => {
            return (
                <UpdateEstado data={row} />
            )
        },
    },
    {
        headerClassName: 'super-app-theme--header',
        field: 'action',
        type: 'actions',
        headerName: 'Acción',
        cellClassName: 'accions',
        width: 250,
        getActions: ({ row }, i) => {
            return [
                <UpdateFormatos id={row.idFormato} key={i} />,
                <DeleteFormatos id={row.idFormato} key={i} />,
                <ButtonCreateFormatoDetalle formato={row} key={i} />
            ]
        },
    }
]

export const Formatos = () => {
    const { state: especialidad } = useLocation()

    const [Formato, setFormato] = useState('')
    const [nombreFormato, setNombreFormato] = useState('')
    const { data: Formatos } = useGetAllByEspecialidad(especialidad.idEspecialidad)
    const buscar = () => {
        setFiltro({
            Formato: Formato,
            FormatoNombre: nombreFormato
        })
    }
    console.log(Formatos)
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item >
                            FORMATOS DE {especialidad.desEspecialidad}
                        </Grid>
                        <Grid item >
                            <CreateFormatos />
                        </Grid>
                    </Grid>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={3}>
                            <LoadingButton
                                fullWidth
                                loading={false}//debe de ser de un usePage(por corregir)
                                loadingPosition='start'
                                startIcon={<SearchIcon />}
                                variant='contained'
                                onClick={buscar}//es una funcion constante(por definir)
                            >
                                Buscar
                            </LoadingButton>
                        </Grid>

                        <Grid item xs={12}>
                            <DataGridStyle
                                height={380}
                                rows={Formatos || []}
                                columns={columns}
                                experimentalFeatures={{ newEditingApi: true }}
                                getRowId={(row) => row.idFormato}
                            />
                        </Grid>
                    </Grid>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}

import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { Button, ButtonGroup, Chip, Grid, IconButton, Tooltip } from '@mui/material'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Input, { InputDate } from '../../../components/Input'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { COLOR_ESTADO, ESTADOS } from '../../../constants'
import { UserContext } from '../../../context/userContext'
import { useGetCitasEspecialista } from '../../../hooks/back-clinica/useCitas'
import { formatHour } from '../../../utils/date-formats'
import { VerCita } from './components/VerCita'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecha',
    headerName: 'Fecha',
    type: 'date',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'dni',
    headerName: 'DNI',
    minWidth: 100,
    flex: 0.1,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'paciente',
    headerName: 'Paciente',
    width: 300,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estado',
    headerName: 'Estado',
    minWidth: 100,
    flex: 0.1,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => {
      return <Chip size="small" color={COLOR_ESTADO[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={ESTADOS[value]}
      />
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'ver',
    headerName: 'Ver',
    width: 200,
    type: 'string',
    renderCell: ({ row }) => {
      const [open, setOpen] = useState(false)
      const handleOpen = () => {
        setOpen(true)
      }
      const handleClose = () => {
        setOpen(false)
      }
      return (
        <>
          <VerCita
            open={open}
            data={row}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
          <Tooltip title='Ver Cita'>
            <IconButton
              aria-label='Ver Cita'
              onClick={handleOpen}
            >
              <FindInPageIcon />
            </IconButton>
          </Tooltip>
        </>

      )
    },
    headerAlign: 'center',
    align: 'center',

  },
]


export const CitasAtendidas = () => {
  const { especialidad: especialidadContext } = useContext(UserContext)

  // const { data: pacientes } = useGetAllPaciente()
  // const formatPacientes = pacientes ? pacientes?.map((paciente) => {
  //   const { dni, nombres, apPaterno, apMaterno } = paciente?.persona
  //   return {
  //     value: dni,
  //     denominacion: `${nombres} ${apPaterno} ${apMaterno}`
  //   }
  // }) : []

  const { control, formState, handleSubmit, watch, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      dniPaciente: '',
      fechaInicio: new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toLocaleDateString('sv', { timeZone: 'America/Lima' }),
      fechaFin: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).toLocaleDateString('sv', { timeZone: 'America/Lima' }),
      estado: 'atendido',
    }
  })
  const isValid = formState.isValid

  const { data, refetch, isLoading } = useGetCitasEspecialista({
    idEspecialista: especialidadContext.idEspecialista,
    dniPaciente: watch('dniPaciente'),
    fechaInicio: watch('fechaInicio'),
    fechaFin: watch('fechaFin'),
    estado: watch('estado'),
  }, false)
  const citas2 = data?.data ? data.data?.map(cita => {

    const { dni, nombres, apPaterno, apMaterno } = cita.paciente.persona
    return {
      id: cita.idCita,
      fecha: cita.fechaCita,
      hora: `${formatHour(cita?.horaCitaIni)} - ${formatHour(cita?.horaCitaFin)}`,
      paciente: `${nombres} ${apPaterno} ${apMaterno}`,
      dni,
      estado: cita.estado,
    }
  })
    : []

  const handleSearch = () => {
    refetch()
  }
  const clearFilters = () => {
    reset()
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Citas Atendidas
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={4}>
                <Input
                  control={control}
                  id='dniPaciente'
                  label='Buscar paciente (DNI)'
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <InputDate
                  control={control}
                  fullWidth
                  id='fechaInicio'
                  label='Fecha inicial'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={!!formState.errors.fechaInicio}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <InputDate
                  control={control}
                  fullWidth
                  id='fechaFin'
                  label='Fecha final'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={!!formState.errors.fechaFin}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Input
                  control={control}
                  id='estado'
                  label='Estado'
                  type='select'
                  data={[
                    { value: 'atendido', denominacion: 'Atendido' },
                    { value: 'derivado', denominacion: 'Derivado' },
                    { value: 'noAsistio', denominacion: 'No asistió' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
                  <Button
                    sx={{ width: '80%' }}
                    variant='contained'
                    startIcon={<FilterAltRoundedIcon />}
                    type='submit'
                    disabled={!isValid}
                  >
                    Filtrar
                  </Button>
                  <Button
                    sx={{ width: '20%' }}
                    color='primary'
                    onClick={clearFilters}
                  >
                    <FilterAltOffRoundedIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item className='mt-4' xs={12}>
                <Loading isLoading={isLoading}>
                  <DataGridStyle
                    height={380}
                    rows={citas2 || []}
                    columns={columns}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row) => row.id}
                  />
                </Loading>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/horarioDet`)
  return data
}

export const Create = async (body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/horarioDet`, body)
  return data
}

export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, id] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/horarioDet/${id}`)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/horarioDet/${id}`, body)
  return data
}

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SaveIcon from '@mui/icons-material/Save'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import Loading from '../../../../components/Loading'
import { useAlertMessage } from '../../../../context/notifications'
import { useUpdateHorario } from '../../../../hooks/useHorario'
import {
  useGetHorarioDetalleById,
  useUpdateHorarioDetalle
} from '../../../../hooks/useHorarioDet'
import { generarHorario } from '../utils/horario'
import { Schedule } from './Schedule'

const UpdateHorario = ({ defaultData }) => {
  const { mutate } = useUpdateHorarioDetalle(defaultData?.idHorario)
  const { data, isLoading, error } = useGetHorarioDetalleById(
    defaultData?.idHorario
  )

  let horarioDetalle =
    data &&
    data[0]?.horarioDetalle?.map(({ horaIni, dia, ...other }) => ({
      ...other,
      horaInicio: horaIni,
      diaHorario: dia
    }))
  const [horario, setHorario] = useState(horarioDetalle)
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')
  const [isDisabled, setIsDisabled] = useState(true)
  useEffect(() => {
    if (horario) {
      setIsDisabled(false)
    }
  }, [horario])

  const { showError, showSuccess } = useAlertMessage()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = data => {

    console.log(horario)

    const formData = {
      ...defaultData,
      horarioDetalle: horario
        ?.filter(({ estado }) => estado === 1)
        .map(({ diaHorario, horaInicio, horaFin, estado }) => ({
          dia: diaHorario,
          horaIni: horaInicio,
          horaFin,
          estado
        }))
    }
    const msg = 'No se pudo editar su Horario'
    const msg2 = 'Horario editado correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
      }
    })
  }
  const horaInicio = defaultData?.horaIni
  const horaFin = defaultData?.horaFin
  const intervalo = defaultData?.periodoAtencion
  const fechaInicio = defaultData?.fechaInicio
  const fechaFin = defaultData?.fechaFin

  return (
    <>
      <Tooltip title='Editar Horario'>
        <span>
          <IconButton
            aria-label='edit'
            onClick={handleOpen}
            disabled={defaultData.estado === 0 ? true : false}
          >
            <ScheduleRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          {'EDITAR HORARIO'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
            }}
          />
        </DialogTitle>
        <Box sx={{ margin: '1rem' }}>
          <Loading isLoading={isLoading} error={error}>
            <Schedule
              horaInicio={horaInicio}
              horaFin={horaFin}
              intervalos={intervalo}
              horario={horario || horarioDetalle}
              setHorario={setHorario}
              citas={[]}
            />
          </Loading>
        </Box>
        <DialogActions>
          <Button
            variant='contained'
            color='error'
            startIcon={<CancelRoundedIcon />}
            onClick={() => {
              handleClose()
            }}
          >
            CANCELAR
          </Button>
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
            onClick={onSubmit}
          >
            GUARDAR
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpdateHorario

import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { Box, Button, Collapse, Divider, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DatosGenerales } from '../../../components/DatosGenerales'
import ContentMainBox from '../../../components/MainBox'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Controller, useForm } from 'react-hook-form'
import { EspecialidadContext } from '../../../context/especialidadContext'
import { useAlertMessage } from '../../../context/notifications'
import { useAtenderPaciente, useGetDatosCita } from '../../../hooks/useAtencionPaciente'
import { useCreatePdf } from '../../../hooks/useCreatePdf'
import { Odontograma } from './components/Odontograma'
import Store from './components/data'
import AtencionOdoSchema from './schema/atencionOdontologia.schema'
import { formatBodyOndontograma, formatToothData } from './utils/format-odontograma'
import { Semaforo } from '../../../components/Semaforo'
const TOOTH_FORMAT = ['Pieza_Odontograma', 'Posicion_Pieza', 'Estado_Pieza', 'Abreviación']


const AtencionOdontologica = () => {
  const { mutate, isLoading, isError, isSuccess } = useAtenderPaciente()
  const navigate = useNavigate()
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita
  const { especialidad } = useContext(EspecialidadContext)

  const [toothData, setToothData] = useState(Store.arcada.adulto)
  const { data } = useGetDatosCita({ idFormato: dataCita.idFormato, idCita: dataCita.id })
  const formatos = data?.formato?.camposFormato

  const CamposPaciente = [
    'nombres',
    'apellidos',
    'dni',
    'fechaNacimiento',
    'direccion',
    'celular',
    'tipoPaciente',
    'fechaNacimiento',
    'celular',
    'alergias',
    'celularResponsable',
    'edad',
    'procedencia',
    'carreraProfesional',
    'codigoEstudiante']
  const [showOdontograma, setShowOdontograma] = useState(true)
  const { showSuccess, showError } = useAlertMessage()
  const { control, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(AtencionOdoSchema),
    defaultValues: {
      fecha: new Date().toISOString().slice(0, 10),
      actoMedico: '',
      motivoConsulta: '',
      diagnosticos: '',
      tratamientos: '',
      medicacion: '',
      semaforo: ''
    }
  })

  const onSubmit = data => {
    const consultaDatos = formatBodyOndontograma(toothData, formatos)
    const formData = {
      idCita: dataCita.id,
      motivoConsulta: data.motivoConsulta,
      diagnostico: data.diagnosticos,
      tratamiento: data.tratamientos,
      receta: data.medicacion,
      semaforo: data.semaforo,
      consultaDatos,
    }

    const msg = 'No se pudo guardar datos de la atencion'
    const msg2 = 'Datos guardados correctamente'
    mutate(formData, {
      onError: (response) => {
        showError(msg);
      },
      onSuccess: ({ response }) => {
        showSuccess(msg2)
        reset()
        genPdf(true)
        navigate("/especialista/nuevasconsultas");
      }
    });
  }
  const { refHtml, genPdf } = useCreatePdf()

  useEffect(() => {
    if (data) {
      const formatData = formatToothData(data.formatoAnteriorCita.formatoDetalle)
      setToothData(formatData)
    }
  }, [data])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Box className="flex items-center">
            <Typography className='font-bold'>
              Atención Odontológica
            </Typography>
          </Box>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <DatosGenerales DNI={dataCita.dni} CamposPaciente={CamposPaciente} />
          <Divider className='my-2' />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='fecha'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Fecha'
                      size='small'
                      fullWidth
                      type='date'
                      {...field}
                      error={!!errors.fecha}
                      helperText={!!errors.fecha ? errors.fecha.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='actoMedico'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Acto médico'
                      size='small'
                      fullWidth
                      select
                      {...field}
                      error={!!errors.actoMedico}
                      helperText={!!errors.actoMedico ? errors.actoMedico.message : null}
                    >
                      {['Procedimiento', 'Evaluacion', 'Otro'].map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='motivoConsulta'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Motivo de consulta'
                      size='small'
                      fullWidth
                      multiline
                      rows={3}
                      {...field}
                      error={!!errors.motivoConsulta}
                      helperText={!!errors.motivoConsulta ? errors.motivoConsulta.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='diagnosticos'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Diagnosticos'
                      size='small'
                      fullWidth
                      multiline
                      rows={3}
                      {...field}
                      error={!!errors.diagnosticos}
                      helperText={!!errors.diagnosticos ? errors.diagnosticos.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='tratamientos'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Tratamientos'
                      size='small'
                      fullWidth
                      multiline
                      rows={3}
                      {...field}
                      error={!!errors.tratamientos}
                      helperText={!!errors.tratamientos ? errors.tratamientos.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Controller
                  name='medicacion'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Medicación'
                      size='small'
                      fullWidth
                      multiline
                      rows={3}
                      {...field}
                      error={!!errors.medicacion}
                      helperText={!!errors.medicacion ? errors.medicacion.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider className='my-2' />
            <>
              <Box className='flex items-center'>
                <Typography className='font-bold'>
                  Odontograma
                </Typography>
                <IconButton onClick={() => setShowOdontograma(!showOdontograma)} size="small">
                  {showOdontograma ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                </IconButton>
              </Box>
              <Collapse in={showOdontograma}>
                <div ref={refHtml}>
                  <Odontograma
                    data={toothData}
                    setData={setToothData}
                    paciente={dataCita.paciente}
                  />
                </div>
              </Collapse>
                <Divider sx={{my: "15px"}}/>
                <Grid container>
                  <Grid item xs={4}>
                    <Semaforo
                      control={control}
                      id='semaforo'
                      label='Semaforo'
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.semaforo?.message}
                    </Typography>
                  </Grid>
                </Grid>
            </>
            <Grid item container justifyContent="space-around" marginTop="1rem">
              <Grid item xs={3}>
                <Button variant='outlined' fullWidth
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <LoadingButton loading={isLoading} type='submit' variant='contained' fullWidth>
                  Guardar
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

export default AtencionOdontologica
import SearchIcon from '@mui/icons-material/Search'
import { Box, Chip, CircularProgress, Grid, InputAdornment, TextField, debounce } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import ContentMainBox from '../../../components/MainBox'
import { useSearchUsuarios } from '../../../hooks/useUsuarios'
import CreateUsuarios from './components/CreateUsuarios'
import DeleteUsuarios from './components/DeleteUsuarios'
import UpdateUsuarios from './components/UpdateUsuarios'

const columns = [
  {
    headerClassName: 'super-app-theme--header ',
    field: 'dni',
    headerName: 'DNI',
    minWidth: 100,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header ',
    field: 'usuario',
    headerName: 'Usuario',
    width: 270,
    minWidth: 280,
    flex: 0.5,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'email',
    headerName: 'Email',
    width: 270,
    minWidth: 280,
    flex: 0.2,
    type: 'string',
    headerAlign: 'left',
    align: 'left'
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'perfilNombre',
    cellClassName: "capitalize",
    headerName: 'Perfil',
    width: 150,
    align: 'left',
    renderCell: ({ value }) => {
      const colors = {
        administrador: "warning",
        especialista: "success",
        paciente: "info",
      }
      return <Chip size="small" color={colors[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={value}
      />
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accions',
    width: 250,
    getActions: ({ row }, i) => {
      return [
        <UpdateUsuarios id={row.email} key={i} />,
        <DeleteUsuarios id={row.email} key={i} />
      ]
    }
  }
]

export const Usuarios = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchValue = searchParams.get('s') ?? ''

  const { data: usuarios, isLoading } = useSearchUsuarios({
    search: searchValue
  })

  const onSearch = (formData) => {
    console.log(formData)
    navigate(`/administrador/usuarios?s=${formData.search}`)
  }

  const { handleSubmit, control } = useForm({
    defaultValues: {
      search: searchValue || '',
    }
  })

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item>Usuarios</Grid>
            <Grid item>
              <CreateUsuarios />
            </Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSearch)} className='flex gap-2'>
                <Controller
                  name="search"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="search"
                      placeholder='Buscar usuario por Nombre o DNI'
                      defaultValue={searchValue || ''}
                      onChange={debounce((e) => {
                        field.onChange(e)
                        navigate(`/administrador/usuarios?s=${e.target.value}`)
                      }, 500)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {
                              isLoading ? <Box sx={{ display: 'flex'}}>
                                <CircularProgress color="inherit" size={24}/>
                              </Box> :
                                <SearchIcon />
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>

            <Grid item xs={12}>
              <DataGridStyle
                height={380}
                rows={usuarios || []}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={row => row.email}
              />
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

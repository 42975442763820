import { axiosClient, axiosClinicaClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async (body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/usuario`, body)
  return data
}

export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, id] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/usuario/${id}`)
  return data[0] || {}
}

export const search = async ({queryKey}) => {
  const [,searchParams] = queryKey
  const query = new URLSearchParams(searchParams).toString()
  const { data } = await axiosClinicaClient.get (`/mantenimiento/usuarios/buscar?${query}`)
  return data
}

export const Create = async body => {
  const { data } = await axiosClinicaClient.post(`/mantenimiento/usuarios/crear`, body)
  return data
}

export const Update = async (id, body) => {
  const { data } = await axiosClinicaClient.patch(`/mantenimiento/usuarios/actualizar/${id}`, body)
  return data
}
export const DeleteElement = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClinicaClient.delete(`/mantenimiento/usuarios/eliminar/${id}`)
  return data.data
}
import { axiosClient } from "../api/axios";
import { usePath } from '../hooks/usePath';

export const GetByIdPaciente = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idPaciente] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica/${idPaciente}`)
  return data
}

export const GetByIdEspecialista = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialista] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica/especialista/${idEspecialista}`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/CitaMedica`, body)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/CitaMedica/${id}`, body)
  return data
}

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica`)
  return data
}

export const GetByEstado = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialista, estado] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica/especialista/${idEspecialista}?TipoConsulta=${estado}`)
  return data
}

export const getCitasPacientes = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idPaciente] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica/${idPaciente}`)
  return data
}

export const GetCitas = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialista, tipoConsulta, fechaInicial, fechaFinal] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/CitaMedica/especialista/${idEspecialista}?TipoConsulta=${estado}&fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`)
  return data
}

const CITAS = [
  {
    "fechaCita": "2023-02-17T00:00:00",
    "horaCitaIni": '09:00',
    "nombre": "karen alvarea ccoscco",
    "estado": 0
  },
  {
    "fechaCita": "2023-03-17T00:00:00",
    "horaCitaIni": '09:00',
    "nombre": "Fiorella Choque Bueno",
    "estado": 1
  },
  {
    "fechaCita": "2023-03-16T00:00:00",
    "horaCitaIni": '09:00',
    "nombre": "Fiorella Choque Bueno",
    "estado": 0
  },
  {
    "fechaCita": "2023-03-20T00:00:00",
    "horaCitaIni": '09:00',
    "nombre": "Fiorella Choque Bueno",
    "estado": 1
  }
]
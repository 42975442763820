/* eslint-disable react/jsx-key */
import { Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useAlertMessage } from '../../../context/notifications'
import { UserContext } from '../../../context/userContext'
import { useGetHorarioById } from '../../../hooks/useHorario'
import { useGetHorarioDetalleById } from '../../../hooks/useHorarioDet'
import { GetById } from '../../../services/horarios'
import CreateHorario from './components/CreateHorario'
import UpdateEstado from './components/UpdateEstado'
import { UpdateFecha } from './components/UpdateFecha'
import UpdateHorario from './components/UpdateHorario'
import { useGetByDniEspecialista } from '../../../hooks/useEspecialistas'
const citasMok = [
  {
    "pacienteId": 1,
    "especialidadId": 1,
    "especialistaId": 1,
    "horarioId": 3300,
    "fechaCita": "06/01/2023",
    "estadoCita": "reservado"
  },
  {
    "pacienteId": 1,
    "especialidadId": 1,
    "especialistaId": 1,
    "horarioId": 3286,
    "fechaCita": "09/01/2023",
    "estadoCita": "reservado"
  },
  {
    "pacienteId": 1,
    "especialidadId": 1,
    "especialistaId": 1,
    "horarioId": 3292,
    "fechaCita": "10/01/2023",
    "estadoCita": "reservado"
  },
  {
    "pacienteId": 1,
    "especialidadId": 1,
    "especialistaId": 1,
    "horarioId": 3286,
    "fechaCita": "16/01/2023",
    "estadoCita": "reservado"
  },
  {
    "pacienteId": 1,
    "especialidadId": 1,
    "especialistaId": 1,
    "horarioId": 3288,
    "fechaCita": "11/01/2023",
    "estadoCita": "reservado"
  }]
const columns = [
  { headerClassName: 'super-app-theme--header', field: 'idHorario', headerName: 'ID', width: 100 },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecIni',
    headerName: 'Fecha Inicio',
    width: 200,
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecFin',
    headerName: 'Fecha Fin',
    width: 200,
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'periodoAtencion',
    headerName: 'Periodo de Atención',
    width: 150,
    //column is center
    align: 'center',
    valueFormatter: ({ value }) => {
      return `${value} min`
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estado',
    headerName: 'Estado',
    width: 100,
    align: 'center',
    renderCell: ({ row }) => {
      return (
        <UpdateEstado defaultData={row} />
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accion',
    width: 200,
    getActions: ({ row }) => {
      return [
        <UpdateFecha defaultData={row} />,
        <UpdateHorario defaultData={row} />,
        // <DeleteHorario data={row} />
      ]
    }
  }
]

const rows = [
  { idHorario: 1, fecIni: '01-01-2023', fecFin: '01-02-2023', estado: 0, periodoAtencion: 30, horaIni: '07:00', horaFin: '08:00', horarioDetalle: [] },
  { idHorario: 2, fecIni: '01-03-2023', fecFin: '01-04-2023', estado: 0, periodoAtencion: 30, horaIni: '07:00', horaFin: '08:00', horarioDetalle: [] },
  { idHorario: 3, fecIni: '01-05-2023', fecFin: '01-06-2023', estado: 1, periodoAtencion: 30, horaIni: '07:00', horaFin: '08:00', horarioDetalle: [] },
]
export const Horarios = () => {
  const { especialidad } = useContext(UserContext)
  const { data: horario, isLoading, isError } = useGetHorarioById(especialidad.idEspecialista)

  let fechaActual = new Date()
  fechaActual.setHours(0, 0, 0, 0)
  let fechaFinal = new Date()
  fechaFinal.setHours(0, 0, 0, 0)
  fechaFinal.setDate(fechaActual.getDate() + 6)

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mi Horario
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid
            container
            spacing={2}
            justifyContent="center" sx={{ mx: 2, mt: 0 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} >
              <CreateHorario />
            </Grid>
            <Grid item xs={12}>
              <Loading isLoading={isLoading} error={isError}>
                <DataGridStyle
                  height={500}
                  rows={horario || rows}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.idHorario}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
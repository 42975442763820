import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

export const PlanTratamiento = () => {
  const { control, formState: { errors } } = useFormContext()

  const { fields } = useFieldArray({
    control: control,
    name: 'tratamientos',
  })

  return (
    <Grid container justifyContent="center" spacing={2}>
      {
        fields?.map((item, index) => (
          <Grid container item justifyContent="center" xs={8} spacing={2} marginY="0.2rem" key={item.id}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={600} >{item.tratamiento}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`tratamientos.${index}.cantidad`}
                control={control}
                render={({ field }) => (
                  <TextField
                    label='Cantidad'
                    size='small'
                    type='number'
                    fullWidth
                    {...field}
                    error={!!errors.tratamientos?.[index]?.cantidad}
                    helperText={!!errors.tratamientos?.[index]?.cantidad ? errors.tratamientos?.[index]?.cantidad.message : null}
                  />
                )}
              />

            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`tratamientos.${index}.prioridad`}
                control={control}
                render={({ field }) => (
                  <TextField
                    label='Prioridad'
                    size='small'
                    type='number'
                    fullWidth
                    {...field}
                    error={!!errors.tratamientos?.[index]?.prioridad}
                    helperText={!!errors.tratamientos?.[index]?.prioridad ? errors.tratamientos?.[index]?.prioridad.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))
      }
      <Grid item xs={6}>
        <Controller
          name='riesgoEstomatologico'
          control={control}
          render={({ field }) => (
            <TextField
              label='Riesgo estomatológico'
              size='small'
              fullWidth
              select
              {...field}
              error={!!errors.riesgoEstomatologico}
              helperText={!!errors.riesgoEstomatologico ? errors.riesgoEstomatologico.message : null}
              defaultValue='Bajo'
            >{
                ["Bajo", "Medio", "Alto"].map((item, index) => (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))
              }
            </TextField>
          )}
        />
      </Grid>
    </Grid>
  )
  // return (
  //   <div>
  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       <Grid container justifyContent="center" spacing={2}>
  //         <Grid item xs={3}>
  //           <TextField
  //             label='Tratamiento'
  //             size='small'
  //             fullWidth
  //             defaultValue=''
  //             select
  //             {...register('tratamiento')}
  //             error={!!errors.tratamiento}
  //             helperText={!!errors.tratamiento ? errors.tratamiento.message : null}
  //           >
  //             {
  //               tratamientos.map((item, index) => (
  //                 <MenuItem key={index} value={item}>{item}</MenuItem>
  //               ))
  //             }
  //           </TextField>
  //         </Grid>
  //         <Grid item xs={2}>
  //           <TextField
  //             label='Cantidad'
  //             size='small'
  //             fullWidth
  //             defaultValue=''
  //             type='number'
  //             InputProps={{ inputProps: { min: 1 } }}
  //             {...register('cantidad')}
  //             error={!!errors.cantidad}
  //             helperText={!!errors.cantidad ? errors.cantidad.message : null}
  //           />
  //         </Grid>
  //         <Grid item xs={2}>
  //           <TextField
  //             label='Prioridad'
  //             size='small'
  //             fullWidth
  //             defaultValue=''
  //             type='number'
  //             InputProps={{ inputProps: { min: 1 } }}
  //             {...register('prioridad')}
  //             error={!!errors.prioridad}
  //             helperText={!!errors.prioridad ? errors.prioridad.message : null}

  //           />
  //         </Grid>
  //         <Grid item xs={1}>
  //           <Button
  //             variant='contained'
  //             size='large'
  //             fullWidth
  //             startIcon={<AddRoundedIcon />}
  //             sx={{
  //               borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
  //             }}
  //             type='submit'
  //           ></Button>
  //         </Grid>
  //       </Grid>
  //     </form>
  //     {
  //       (tratamiendosSeleccionados.length === 0)
  //         ?
  //         <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
  //           <DialogContentText justifyContent="center" sx={{ ml: 2, mt: 2 }}>No existe ningún examen aún.</DialogContentText>
  //         </Grid>
  //         :
  //         <>
  //           {
  //             tratamiendosSeleccionados?.map((item, index) => (
  //               <Grid container justifyContent="center" spacing={2} key={index}>
  //                 <Grid item xs={3} className="flex items-center">
  //                   <Typography variant='body1' >{item.tratamiento}</Typography>
  //                 </Grid>
  //                 <Grid item xs={2} className="flex items-center">
  //                   <Typography variant='body1' >{item.cantidad}</Typography>
  //                 </Grid>
  //                 <Grid item xs={2} className="flex items-center">
  //                   <Typography variant='body1' >{item.prioridad}</Typography>
  //                 </Grid>
  //                 <Grid item xs={1}>
  //                   <IconButton
  //                     onClick={() => deleteTratamiento(item.tratamiento)}
  //                   >
  //                     <DeleteRoundedIcon />
  //                   </IconButton>
  //                 </Grid>
  //               </Grid>
  //             ))
  //           }
  //           {/* <Grid container spacing={10} justifyContent="center" rowSpacing={2} sx={{ mt: 1 }}>
  //             <Grid item xs={4} >
  //               <Button
  //                 fullWidth
  //                 variant="outlined"
  //               >
  //                 Cancelar
  //               </Button>
  //             </Grid>

  //             <Grid item xs={4} >
  //               <Button
  //                 // type='submit' 
  //                 fullWidth
  //                 variant="contained"
  //               >
  //                 Concluir
  //               </Button>
  //             </Grid>
  //           </Grid> */}
  //         </>
  //     }
  //   </div>
  // )
}

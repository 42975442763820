import { Stack, Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ModalError = ({openModal, setOpenModal, mensaje}) => {
  return (
    <Dialog 
      open={openModal} 
      fullWidth={true}
      PaperProps={{ sx: { width: "30%", minWidth:'360px'} }}
    >
      <DialogTitle>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar sx={{bgcolor: '#ffeeeb'}}> 
            <CloseRoundedIcon sx={{color:'#e04e2f', fontSize: 29}}/> 
          </Avatar>
          <Typography variant="h6">Operación fallida</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{pb:1}}>
        <DialogContentText sx={{ml:2}}>
          {mensaje}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={3}>
            <Button 
              fullWidth
              onClick={() => setOpenModal(false)}
            >
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

import { Divider, Grid } from '@mui/material'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { ApplyFilters, Filter, FilterButtons, FilterDataByState, FilterState, SearchInput, useFilterByEspecialidad, useFilterByEspecialista, useFilterByState, useFilterDataByState } from '../../../components/Filters'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import CreateEspecialista from './components/CreateEspecialista'
import UpdateEspecialista from './components/UpdateEspecialista'
import UpdateEstado from './components/UpdateEstado'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'dni',
    headerName: 'DNI',
    width: 100,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'nombres',
    headerName: 'Nombre',
    width: 400,
    flex: 0.3,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'especialidad',
    headerName: 'Especialidad',
    width: 200,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'Estado',
    headerName: 'Estado',
    width: 100,
    renderCell: ({ row }) => {
      return (
        <UpdateEstado data={row} />
      )
    },
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'accion',
    type: 'actions',
    width: 100,
    headerName: 'Acción',
    cellClassName: 'accion',
    getActions: ({ row }) => {
      return [
        <UpdateEspecialista data={row} key={row.dni}/>,
        // <EliminarEspecialista data={row} />
      ]
    }
  },
]


export const Especialistas = () => {

  const { data: Especialistas } = useGetAllEspecialista()
  const comboEspecialistas =
    Especialistas ?
      Especialistas.map(({ persona: persona, especialidad, ...esp }) => {
        return {
          ...esp,
          persona,
          id: esp?.idEspecialista,
          especialidad: especialidad?.desEspecialidad,
          nombres: persona?.nombres + ' ' + persona?.apPaterno + ' ' + persona?.apMaterno,
        }
      }) : []
  const { filterByEspecialista, especialista, setEspecialista } = useFilterByEspecialista()
  const { filterByEspecialidad, especialidad, setEspecialidad, especialidades } = useFilterByEspecialidad()
  const { filterByState, estado, setEstado } = useFilterByState()
  const { dataByState: especialidadesByState, stateData: stateEspecialidades,
    setStateData: setStateEspecialidades } = useFilterDataByState(especialidades)

  const filters = [
    {
      name: 'especialista',
      filter: filterByEspecialista,
      filterValue: especialista,
      setFilterValue: setEspecialista
    },
    {
      name: 'especialidad',
      filter: filterByEspecialidad,
      filterValue: especialidad,
      setFilterValue: setEspecialidad
    },
    {
      name: 'estado',
      filter: filterByState,
      filterValue: estado,
      setFilterValue: setEstado
    },
  ]

  const clearFilters = () => {
    setEspecialista('')
    setEspecialidad('')
    setStateEspecialidades(-1)
    setEstado(-1)
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item>Especialistas</Grid>
            <Grid item>
              <CreateEspecialista />
            </Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <ApplyFilters
                data={comboEspecialistas || []}
                filters={filters}
                renderItem={() =>
                  <Loading isLoading={false}>
                    <DataGridStyle
                      height={500}
                      rows={comboEspecialistas || []}
                      columns={columns}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.idEspecialista}
                    />
                  </Loading>
                }
              >
                <Grid item xs={12}>
                  <SearchInput
                    data={comboEspecialistas}
                    value={especialista}
                    setValue={setEspecialista}
                    keyWord='nombres'
                    labelWord='especialista'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FilterDataByState
                    label='Estado de especialidades'
                    data={stateEspecialidades}
                    setData={setStateEspecialidades}
                  />
                </Grid>
                <Grid xs={12} item sm={6} md={5} >
                  <Filter
                    data={especialidadesByState}
                    label="Especialidad"
                    filterKey="desEspecialidad"
                    value={especialidad}
                    setValue={setEspecialidad}
                  />
                </Grid>
                <Grid item md='auto' sx={{
                  display: { xs: 'none', md: 'block' },
                }}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={12} sm={6} md>
                  <FilterState label="Estado de especialistas" value={estado} setValue={setEstado} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FilterButtons clearFilters={clearFilters} />
                </Grid>
              </ApplyFilters>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import { Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useGetPacienteByNroDoc } from "../../hooks/usePacientes";

import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import ChurchIcon from '@mui/icons-material/Church';
import DangerousIcon from '@mui/icons-material/Dangerous';
import EmailIcon from '@mui/icons-material/Email';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ManIcon from '@mui/icons-material/Man';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PinIcon from '@mui/icons-material/Pin';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SickIcon from '@mui/icons-material/Sick';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/userContext';

let personalDataDefault = {
  nombres: {
    nombre: 'Nombre',
    valor: '--',
    unidad: '',
    Icon: PersonRoundedIcon,
    color: '#9871ff'
  },
  // apellidos: {
  //   nombre: 'Apellidos',
  //   valor: '',
  //   unidad: '',
  //   Icon: PersonRoundedIcon,
  //   color: '#adadad'
  // },
  dni: {
    nombre: 'DNI',
    valor: '--',
    unidad: '',
    Icon: FeaturedVideoIcon,
    color: '#009688'
  },
  tipoPaciente: {
    nombre: 'Tipo de paciente',
    valor: '--',
    unidad: '',
    Icon: AccountBoxRoundedIcon,
    color: '#4a9bc4'
  },
  fechaNacimiento: {
    nombre: 'Fecha de nacimiento',
    valor: '--',
    unidad: '',
    Icon: CakeRoundedIcon,
    color: '#dd9787'
  },
  direccion: {
    nombre: 'Dirección',
    valor: '--',
    unidad: '',
    Icon: HomeRoundedIcon,
    color: '#9fae81'
  },
  celular: {
    nombre: 'Celular',
    valor: '--',
    unidad: '',
    Icon: LocalPhoneRoundedIcon,
    color: '#d89d48'
  },
  alergias: {
    nombre: 'Alergias',
    valor: '--',
    unidad: '',
    Icon: SickIcon,
    color: '#ff4081'
  },
  vacunas: {
    nombre: 'Vacunas',
    valor: '--',
    unidad: '',
    Icon: VaccinesIcon,
    color: '#9500ae'
  },
  cirugias: {
    nombre: 'Cirugías',
    valor: '--',
    unidad: '',
    Icon: AirlineSeatFlatIcon,
    color: '#'
  },
  personaResponsable: {
    nombre: 'Persona Responsable',
    valor: '--',
    unidad: '',
    Icon: EscalatorWarningIcon,
    color: '#d89d48'
  },
  celularResponsable: {
    nombre: 'CelularResponsable',
    valor: '--',
    unidad: '',
    Icon: LocalPhoneRoundedIcon,
    color: '#3d5afe'
  },
  // sexo: {
  //   nombre: 'Sexo',
  //   valor: 'Femenino',
  //   unidad: '',
  //   Icon: FemaleRoundedIcon, // || MaleRoundedIcon,
  //   color: '#db5461'
  // },
  edad: {
    nombre: 'Edad',
    valor: '--',
    unidad: 'años',
    Icon: EventRoundedIcon,
    color: '#64a0c2'
  },
  procedencia: {
    nombre: 'Procedencia',
    valor: '--',
    unidad: '',
    Icon: PublicRoundedIcon,
    color: '#c68aac'
  },
  carreraProfesional: {
    nombre: 'Carrera profesional',
    valor: '--',
    unidad: '',
    Icon: SchoolRoundedIcon,
    color: '#424242'
  },
  numeroHermanos: {
    nombre: 'Numero Hermanos',
    valor: '--',
    unidad: '',
    Icon: GroupAddIcon,
    color: '#00695c'
  },
  accidentesGraves: {
    nombre: 'Accidentes Graves',
    valor: '--',
    unidad: '',
    Icon: DangerousIcon,
    color: '#d81b60'
  },
  codigoEstudiante: {
    nombre: 'Codigo Estudiante',
    valor: '--',
    unidad: '',
    Icon: PinIcon,
    color: '#00acc1'
  },
  semestre: {
    nombre: 'Semestre',
    valor: '--',
    unidad: '',
    Icon: SchoolRoundedIcon,
    color: '#37474f'
  },
  email: {
    nombre: 'Email',
    valor: '--',
    unidad: '',
    Icon: EmailIcon,
    color: '#ff8a65'
  },
  tutor: {
    nombre: 'Tutor',
    valor: '--',
    unidad: '',
    Icon: ManIcon,
    color: '#ffd600'
  },
  viveResidenciaUniv: {
    nombre: 'Vive Residencia Univ',
    valor: '--',
    unidad: '',
    Icon: MapsHomeWorkIcon,
    color: '#795548'
  },
  idioma: {
    nombre: 'Idioma',
    valor: '--',
    unidad: '',
    Icon: GTranslateIcon,
    color: '#7a7a7a'
  },
  religion: {
    nombre: 'Religión',
    valor: '-- ',
    unidad: '',
    Icon: ChurchIcon,
    color: '#1de9b6'
  },
  LugarOcupaHermano: {
    nombre: 'Lugar Ocupa Hermano',
    valor: '--',
    unidad: '',
    Icon: FamilyRestroomIcon,
    color: '#00695c'
  },
}

const Inicio = () => {
  const { user } = useContext(UserContext)
  const { data } = useGetPacienteByNroDoc(user.dni)
  const [dataPaciente, setDataPaciente] = useState({})
  useEffect(() => {
    if (data) {
      const newData = {
        ...user,
        ...data,
      }
      const personalData = { ...personalDataDefault }
      Object.keys(newData).map((key) => {
        if (personalData[key])
          personalData[key].valor = newData[key]
      })
      return setDataPaciente(personalData)
    } else {
      setDataPaciente(personalDataDefault)
    }
  }, [data])

  return (
    <Box className="flex h-auto justify-center align-center">
      <Grid container className="xs:flex-column justify-center">
        <Grid item xs={12}>
          <Typography variant="h4" className="text-center">
            Mis Datos
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={6}>
          {
            dataPaciente && Object.keys(dataPaciente).map((key, index) => {
              const { nombre, valor, unidad, Icon, color } = dataPaciente[key]
              return (
                <Grid item xs={12} key={index}>
                  <Divider />
                  <ListItem className='my-[2px]'>
                    <ListItemIcon> <Icon sx={{ color }} /> </ListItemIcon>
                    <ListItemText>
                      <span className='font-bold text-[#404040]'>{nombre}</span>
                    </ListItemText>
                    <span>{valor ?? '--'}</span>
                    <span className={`text-[#7e7f97] ${unidad !== '' ? 'ml-1' : ''}`}>
                      {unidad}
                    </span>
                  </ListItem>
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default Inicio

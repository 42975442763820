import { useUpdateAcceso } from '../../../../hooks/useAccesos'
import FormAccesos from './FormAccesos'

const UpdateAccesos = ({ id }) => {
  const { mutate, isLoading } = useUpdateAcceso(id)
  return (
    <>
      <FormAccesos edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdateAccesos

import { useCreateFormatoDetalle } from '../../../../hooks/useFormatoDetalles'
import FormFormatoDetalles from './FormFormatoDetalles'
const CreateFormatoDetalles = () => {
    const { mutate, isLoading } = useCreateFormatoDetalle()
    return (
        <>
            <FormFormatoDetalles edit={false} id={''} mutate={mutate} isLoading={isLoading} />
        </>
    )
}
export default CreateFormatoDetalles
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material"
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Input from "../../../../components/Input"
import { useForm } from "react-hook-form";

export const ModalDiagnosticos = ({ stateModal : {open, addItem}, setStateModal, data, setData }) => {

  const { control, handleSubmit } = useForm()

  const onSubmit = (data) => console.log(data)

  const onCloseModal = () => {
    setStateModal({open:false, addItem: false})
    if (addItem) {
      data.pop()
      setData(data)
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Diagnóstico</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {
              (data.length === 0)
              ? <DialogContentText sx={{ml:2, mt: 2}}>No existe ningún diagnóstico aún.</DialogContentText>
              : data.map(({ nombre, cie10, tipo }, index) => (
                <Grid item container key={index} spacing={2} alignItems='center'>
                  <Grid item xs={4}>
                    <Typography>{nombre}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{cie10}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      id={nombre}
                      control={control}
                      defaultValue = {tipo}
                      label='Tipo de dx'
                      type = 'select'
                      data = {[
                        {value:'tipo1', denominacion:'Tipo1'},
                        {value:'tipo2', denominacion:'Tipo2'}
                      ]}
                    />
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 1 }}>
          {
            (data.length === 0)
            ? <Button onClick={() => setStateModal({open:false, addItem: false})} sx={{mr:1}}>
                Aceptar
              </Button>

            : <Grid container justifyContent="space-around">
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={onCloseModal}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={() => setStateModal({open:false, addItem: false})}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </form>
    </Dialog>
  )
}

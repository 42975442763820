import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, GetAll, GetById, GetByNroDoc, Update } from '../services/triajes'

const key = 'triajes'

export const useGetTriaje = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled,
    select: data => {
      return data.map(triaje => {
        return {
          ...triaje,
          frecuenciaRespiratoria: triaje?.frecuencia_respiratoria,
          frecuenciaCardiaca: triaje?.frecuencia_cardiaca
        }
      })
    }
  })

export const useGetByIdTriaje = (idTriaje, enabled = true) =>
  useQuery([key, idTriaje], GetById, {
    enabled
  })

export const useGetTriajeByNroDoc = (NroDoc, enabled = true) =>
  useQuery([key, NroDoc], GetByNroDoc, {
    enabled
  })

export const useCreateTriaje = () => {
  const queryClient = useQueryClient()
  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateTriaje = () => {
  const queryClient = useQueryClient()
  return useMutation(Update, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

import { Circle } from '@mui/icons-material'
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import { Controller } from 'react-hook-form'

const colors = [
  {
    value: 0,
    text: 'Verde',
    color: '#4CAF50'
  },
  {
    value: 1,
    text: 'Naraja',
    color: '#FFC107'
  },
  {
    value: 2,
    text: 'Rojo',
    color: '#F44336'
  }
]

export const Semaforo = ({ control, id, label, defaultValue = '' }) => {
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth size='small'>
          <InputLabel>{label}</InputLabel>
          <Select label='Semaforo' value={value} onChange={onChange}>
            {colors.map(item => (
              <MenuItem key={item.value} value={item.value}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <Circle sx={{ color: item.color }} />
                  <ListItemText primary={item.text} />
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material"
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Input from "../../../../components/Input"
import { useForm } from "react-hook-form";

export const ModalExamenes = ({ stateModal : {open, addItem}, setStateModal, data, setData }) => {

  const { control, handleSubmit } = useForm()

  const onSubmit = (datosModal) => {
    //Actualizar resultados de examenes
    for (const key in datosModal) {
      const idExamen = key.split('resultadoIdExamen')[1]
      const resultado = datosModal[key]
      const index = data.findIndex(({examen})=> examen.idExamen === Number(idExamen))
      data[index].resultados = resultado
    }
    setData(data)
    console.log(data)
  }

  const onCloseModal = () => {
    setStateModal({open:false, addItem: false})
    if (addItem) {
      data.pop()
      setData(data)
    }
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Exámenes auxiliares</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {
              (data.length === 0)
              ? <DialogContentText sx={{ml:2, mt: 2}}>No existe ningún examen aún.</DialogContentText>
              : data.map(({ examen, resultados },index) => (
                <Grid item container key={index} spacing={2} alignItems='center'>
                  <Grid item xs={4}>
                    <Typography>{examen.descripcionExamen}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant='contained'
                      size='large'
                      fullWidth
                      startIcon={<UploadFileRoundedIcon />}
                      sx={{
                        borderColor: "primary.main", "& .MuiButton-startIcon": { margin: 0 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      id={`resultadoIdExamen${examen.idExamen}`}
                      control={control}
                      defaultValue = {resultados}
                      label='Resultados'
                    />
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 1}}>
          {
            (data.length === 0)
            ? <Button onClick={() => setStateModal({open:false, addItem:false})} sx={{mr:1}}>
                Aceptar
              </Button>

            : <Grid container justifyContent="space-around">
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={onCloseModal}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={() => setStateModal({open:false, addItem:false})}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          }
        </DialogActions>
      </form>
    </Dialog>
  )
}

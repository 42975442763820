import * as yup from "yup"

const AtencionOdoSchema = yup.object({
  actoMedico: yup.string().required('*Campo obligatorio'),
  fecha: yup.string().required('*Campo obligatorio'),
  motivoConsulta: yup.string().required('*Campo obligatorio'),
  diagnosticos: yup.string().required('*Campo obligatorio'),
  // tratamientos: yup.string().required('*Campo obligatorio'),
  // medicacion: yup.string().required('*Campo obligatorio'),
})

export default AtencionOdoSchema;
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded'
import BloodtypeRoundedIcon from '@mui/icons-material/BloodtypeRounded'
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import FemaleRoundedIcon from '@mui/icons-material/FemaleRounded'
import HeightRoundedIcon from '@mui/icons-material/HeightRounded'
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded'
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded'
import { Grid, TextField } from '@mui/material'
import ContentMainBox from '../../../components/MainBox'
import { useGetPacienteByNroDoc } from '../../../hooks/usePacientes'
import { useGetTriajeByNroDoc } from '../../../hooks/useTriaje'
import { CardPerfilClinico } from './components/CardPerfilClinico'
import CardSignosVitales from './components/CardSignosVitales'
import { useContext } from 'react'
import { UserContext } from '../../../context/userContext'

let dataPerfil = {
  tipoSangre: {
    titulo: 'Tipo de sangre',
    name: 'tipoSangre',
    unidad: '',
    descripcion: '',
    Icon: BloodtypeRoundedIcon
  },
  peso: {
    titulo: 'Peso',
    name: 'peso',
    unidad: 'Kg',
    descripcion: '-',
    Icon: ScaleRoundedIcon
  },
  altura: {
    titulo: 'Estatura',
    name: 'altura',
    unidad: 'cm',
    descripcion: '-',
    Icon: HeightRoundedIcon
  },
  genero: {
    titulo: 'Género',
    name: 'genero',
    unidad: '',
    descripcion: '-',
    Icon: FemaleRoundedIcon
  },
  edad: {
    titulo: 'Edad',
    name: 'edad',
    unidad: 'años',
    descripcion: '-',
    Icon: DateRangeRoundedIcon
  },
  imc: {
    titulo: 'IMC',
    imc: 'imc',
    unidad: '',
    descripcion: '-',
    Icon: AccessibilityNewRoundedIcon
  }
}

let signosVitales = {
  altura: {
    titulo: 'Altura',
    valor: 1.78,
    unidad: 'cm',
    Icon: HeightRoundedIcon,
    color: '#9e8dba'
  },
  peso: {
    titulo: 'Peso',
    valor: 74,
    unidad: 'Kg',
    Icon: ScaleRoundedIcon,
    color: '#64a0c2'
  },
  bmi: {
    titulo: 'BMI',
    valor: 23.4,
    unidad: 'BMI',
    Icon: AccessibilityNewRoundedIcon,
    color: '#b8d466'
  },
  temperatura: {
    titulo: 'Temperatura',
    valor: 36.5,
    unidad: '°C',
    Icon: DeviceThermostatRoundedIcon,
    color: '#66d19d'
  },
  frecuenciaRespiratoria: {
    titulo: 'Frec. Respiratoria',
    valor: 17,
    unidad: 'r/m',
    Icon: MonitorHeartRoundedIcon,
    color: '#67bf97'
  },
  presionArterial: {
    titulo: 'Presión Arterial',
    valor: '128/80',
    unidad: 'mmHg',
    Icon: FavoriteRoundedIcon,
    color: '#df4976'
  },
  frecuenciaCardiaca: {
    titulo: 'Frec. Cardiaca',
    valor: 60,
    unidad: 'Fc',
    Icon: MonitorHeartRoundedIcon,
    color: '#c68aac'
  },
}

export const PerfilClinico = () => {
  const { user } = useContext(UserContext)

  const { data: dataPaciente } = useGetPacienteByNroDoc(user?.dni) //userDNI
  const { data: dataTriaje } = useGetTriajeByNroDoc(user?.dni) //userDNI
  const Paciente = dataPaciente ? dataPaciente : {}
  const Triaje = dataTriaje ? dataTriaje.slice(-1)[0] : [] //obtener ultimo triaje

  const calcularEdad = (fechaNacimiento) => {
    //Fecha: yyyy-mm-dd
    if (!fechaNacimiento) return undefined
    const fecha = new Date(fechaNacimiento)
    const diffMilisegundos = Date.now() - fecha.getTime()
    const añoResultante = new Date(diffMilisegundos);
    return Math.abs(añoResultante.getUTCFullYear() - 1970)
  }

  dataPerfil.tipoSangre.descripcion = Paciente?.tipoSangre
  dataPerfil.peso.descripcion = Triaje?.peso
  dataPerfil.altura.descripcion = Triaje?.talla
  dataPerfil.genero.descripcion = 'Femenino'
  dataPerfil.edad.descripcion = calcularEdad(Paciente?.fechaNacimiento?.slice(0, 10))
  dataPerfil.imc.descripcion = Triaje?.imc

  signosVitales.altura.valor = Triaje?.talla
  signosVitales.peso.valor = Triaje?.peso
  signosVitales.bmi.valor = Triaje?.imc
  signosVitales.temperatura.valor = Triaje?.temperatura
  signosVitales.frecuenciaRespiratoria.valor = Triaje?.frecuenciaRespiratoria
  signosVitales.presionArterial.valor = Triaje?.presionArterial
  signosVitales.frecuenciaCardiaca.valor = Triaje?.frecuenciaCardiaca

  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <div className='flex justify-between'>
          Mi perfil clínico
        </div>
      </ContentMainBox.Title>
      <ContentMainBox.Content>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          {
            Object.values(dataPerfil)?.map(({ titulo, descripcion, Icon }, idx) => {
              return (
                <Grid className='mt-4' item xs={4} key={idx}>
                  <CardPerfilClinico
                    titulo={titulo}
                    descripcion={descripcion}
                    Icon={Icon}
                  />
                </Grid>
              )
            })
          }
        </Grid>
        {/* <div className='sm:h[85%] lg:h-[85vh] lg:w-1/2 sm:w-full'>
          <CardSignosVitales data={signosVitales} />
        </div> */}
      </ContentMainBox.Content>
    </ContentMainBox>
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Input from '../../../../components/Input'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { DatosGenerales } from '../../../../components/DatosGenerales'
import { useLocation, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { useAlertMessage } from '../../../../context/notifications'
import { EspecialidadContext } from '../../../../context/especialidadContext'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import ContentMainBox from '../../../../components/MainBox'
import {
  useAtenderPaciente,
  useGetDatosCita
} from '../../../../hooks/useAtencionPaciente'
import { LoadingButton } from '@mui/lab'
import Loading from '../../../../components/Loading'
import { Semaforo } from '../../../../components/Semaforo'

const CamposPaciente = [
  'nombres',
  'apellidos',
  'fechaNacimiento',
  'direccion',
  'celular',
  'alergias',
  'personaResponsable',
  'edad',
  'procedencia',
  'carreraProfesional',
  'numeroHermanos',
  'accidentesGraves',
  'codigoEstudiante',
  'semestre',
  'email',
  'tutor',
  'viveResidenciaUniv',
  'idioma',
  'religion',
  'LugarOcupaHermano'
]

const schema = yup.object({
  nombrePadre: yup.string('').required('Campo requerido'),
  apellidoPadre: yup.string('').required('Campo requerido'),
  edadPadre: yup.number('').integer().required('Campo requerido'),
  fechaNacimientoPadre: yup.date().required('Campo requerido'),
  dniPadre: yup.number().required('Campo requerido'),
  direccionPadre: yup.string('').required('Campo requerido'),
  celularPadre: yup.string('').required('Campo requerido'),
  viveconestudiantePadre: yup.string('').required('Campo requerido'),
  gradoInstrucionPadre: yup.string('').required('Campo requerido'),
  profesionPadre: yup.string('').required('Campo requerido'),
  centroTrabajoPadre: yup.string('').required('Campo requerido'),
  cargoPadre: yup.string('').required('Campo requerido'),
  estadoCivilPadre: yup.string('').required('Campo requerido'),
  religionPadre: yup.string('').required('Campo requerido'),
  nombreMadre: yup.string('').required('Campo requerido'),
  apellidoMadre: yup.string('').required('Campo requerido'),
  edadMadre: yup.number('').integer().required('Campo requerido'),
  fechaNacimientoMadre: yup.date().required('Campo requerido'),
  dniMadre: yup.number().required('Campo requerido'),
  direccionMadre: yup.string('').required('Campo requerido'),
  celularMadre: yup.string('').required('Campo requerido'),
  viveconestudianteMadre: yup.string('').required('Campo requerido'),
  gradoInstrucionMadre: yup.string('').required('Campo requerido'),
  profesionMadre: yup.string('').required('Campo requerido'),
  centroTrabajoMadre: yup.string('').required('Campo requerido'),
  cargoMadre: yup.string('').required('Campo requerido'),
  estadoCivilMadre: yup.string('').required('Campo requerido'),
  religionMadre: yup.string('').required('Campo requerido'),
  semaforo: yup.string().required('Campo requerido')
})

export const FormFichaPersonalEstudiante = () => {
  const { state: dataCita } = useLocation()
  const { data: dataCitaCampos, isLoading } = useGetDatosCita({
    idFormato: dataCita.idFormato,
    idCita: dataCita.id
  })
  const { mutate, isLoading: isLoadingAtender } = useAtenderPaciente()

  const { especialidad } = useContext(EspecialidadContext)
  const navigate = useNavigate()
  const [showForm1, setShowForm1] = useState(true)
  const { showError, showSuccess } = useAlertMessage()
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      nombrePadre: '',
      apellidoPadre: '',
      edadPadre: '',
      fechaNacimientoPadre: '',
      dniPadre: '',
      direccionPadre: '',
      celularPadre: '',
      viveconestudiantePadre: '',
      gradoInstrucionPadre: '',
      profesionPadre: '',
      centroTrabajoPadre: '',
      cargoPadre: '',
      estadoCivilPadre: '',
      religionPadre: '',
      nombreMadre: '',
      apellidoMadre: '',
      edadMadre: '',
      fechaNacimientoMadre: '',
      dniMadre: '',
      direccionMadre: '',
      celularMadre: '',
      viveconestudianteMadre: '',
      gradoInstrucionMadre: '',
      profesionMadre: '',
      centroTrabajoMadre: '',
      cargoMadre: '',
      estadoCivilMadre: '',
      religionMadre: '',
      semaforo: ''
    }
  })
  function getIdItemByNombreCampo(nombreCampo) {
    const CamposFormato = dataCitaCampos?.formato?.formatoDetalle || []
    const campo = CamposFormato.find(campo => campo.nombreCampo === nombreCampo)
    return campo ? campo.idItem : null
  }
  const onSubmit = data => {
    const llaves = Object.keys(data)
    const Lista = llaves
      .map(ojb => {
        const idItem = getIdItemByNombreCampo(ojb)

        if (idItem) {
          return {
            idItem: idItem,
            idSubItem: 0,
            valor: data[`${ojb}`].toString()
          }
        }

        return null
      })
      .filter(Boolean)
    const DatosEnviar = {
      idCita: dataCitaCampos.idCita,
      semaforo: data.semaforo,
      consultaDatos: Lista
    }
    console.log(DatosEnviar)
    const msg = 'No se pudo guardar datos de la atencion o ya fue creada'
    const msg2 = 'Datos guardados correctamente o ya fue creada'
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }
  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <Box className='flex items-center'>
          <IconButton onClick={() => setShowForm1(!showForm1)} size='small'>
            {showForm1 ? (
              <VisibilityRoundedIcon />
            ) : (
              <VisibilityOffRoundedIcon />
            )}
          </IconButton>
          <Typography className='font-bold'>
            Ficha Personal del Estudiante
          </Typography>
        </Box>
      </ContentMainBox.Title>
      <Collapse in={showForm1}>
        <ContentMainBox.Content>
          <Loading isLoading={isLoading}>
            {dataCitaCampos && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} paddingX={10}>
                  <Grid item xs={12}>
                    <DatosGenerales
                      DNI={dataCita.dni}
                      triaje={especialidad.requiereTriaje}
                      CamposPaciente={CamposPaciente}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider>Datos Familiares</Divider>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5} textAlign={'center'}>
                    <h2>Padre</h2>
                  </Grid>
                  <Grid item xs={5} textAlign={'center'}>
                    <h2>Madre</h2>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Apellidos</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='apellidoPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.apellidoPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='apellidoMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.apellidoMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Nombres</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='nombrePadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.nombrePadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='nombreMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.nombreMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Edad</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='edadPadre'
                      type='number'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.edadPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='edadMadre'
                      multiline
                      type='number'
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.edadMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Fecha de Nacimiento</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='fechaNacimientoPadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.fechaNacimientoPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='fechaNacimientoMadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.fechaNacimientoMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>N° DNI</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='dniPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.dniPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='dniMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.dniMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Dirección</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='direccionPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.direccionPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='direccionMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.direccionMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Celular / Teléfono</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='celularPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.celularPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='celularMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.celularMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Vive con el estudiante</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='viveconestudiantePadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.viveconestudiantePadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='viveconestudianteMadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.viveconestudianteMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Grado de instrucción</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='gradoInstrucionPadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.gradoInstrucionPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='gradoInstrucionMadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.gradoInstrucionMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Profesión u ocupación</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='profesionPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.profesionPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='profesionMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.profesionMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Centro de trabajo</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='centroTrabajoPadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.centroTrabajoPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input
                      control={control}
                      id='centroTrabajoMadre'
                      multiline
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.centroTrabajoMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Cargo que desempeña</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='cargoPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.cargoPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='cargoMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.cargoMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Estado civil</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='estadoCivilPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.estadoCivilPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='estadoCivilMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.estadoCivilMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems={'center'}
                    justifyContent={'end'}
                    display={'flex'}
                  >
                    <div>Religión</div>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='religionPadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.religionPadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Input control={control} id='religionMadre' multiline />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.religionMadre?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Semaforo
                      control={control}
                      id='semaforo'
                      label='Semaforo'
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.semaforo?.message}
                    </Typography>
                  </Grid>

                  <Grid item container justifyContent='space-evenly'>
                    <Grid item xs={5}>
                      <Button fullWidth variant='outlined'
                        onClick={() =>
                          navigate(-1)
                        }
                      >
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item xs={5}>
                      <LoadingButton
                        loading={isLoadingAtender}
                        type='submit'
                        fullWidth
                        variant='contained'
                      >
                        Guardar
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Loading>
        </ContentMainBox.Content>
      </Collapse>
    </ContentMainBox>
  )
}

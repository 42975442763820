import { useUpdateFormato } from '../../../../hooks/useFormatos'
import FormFormatos from './FormFormatos'

const UpdateFormatos = ({ id }) => {
  const { mutate, isLoading } = useUpdateFormato(id)
  return (
    <>
      <FormFormatos edit={true} id={id} mutate={mutate} isLoading={isLoading} />
    </>
  )
}

export default UpdateFormatos

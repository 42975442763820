import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAlertMessage } from '../../../../context/notifications'
import Input from '../../../../components/Input'
import { useLocation, useNavigate } from 'react-router-dom'
import { EspecialidadContext } from '../../../../context/especialidadContext'
import { DatosGenerales } from '../../../../components/DatosGenerales'
import ContentMainBox from '../../../../components/MainBox'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import {
  useAtenderPaciente,
  useGetDatosCita
} from '../../../../hooks/useAtencionPaciente'
import Loading from '../../../../components/Loading'
import { LoadingButton } from '@mui/lab'
import { Semaforo } from '../../../../components/Semaforo'

const CamposPaciente = [
  'nombres',
  'apellidos',
  'fechaNacimiento',
  'direccion',
  'celular',
  'alergias',
  'personaResponsable',
  'edad',
  'procedencia',
  'carreraProfesional',
  'numeroHermanos',
  'accidentesGraves',
  'codigoEstudiante',
  'semestre',
  'email',
  'tutor',
  'viveResidenciaUniv',
  'idioma',
  'religion',
  'LugarOcupaHermano'
]

export const FormEvaluacion = () => {
  const EvaluacionSchema = yup.object({
    motivoConsulta: yup.string().required('Campo requerido'),
    antecedentesPersonales: yup.string().required('Campo requerido'),
    obsFisica: yup.string().required('Campo requerido'),
    obsVerbal: yup.string().required('Campo requerido'),
    obsNoVerbal: yup.string().required('Campo requerido'),
    dinamicaFamiliar: yup.string().required('Campo requerido'),
    maduracion: yup.string().required('Campo requerido'),
    indicadoresEmocionales: yup.string().required('Campo requerido'),
    indicadoresLesionCrebral: yup.string().required('Campo requerido'),
    sugerencias: yup.string().required('Campo requerido'),
    semaforo: yup.string().required('Campo requerido')
  })
  const [showFormEvaluacion, setShowFormEvaluacion] = useState(true)
  const { state: dataCita } = useLocation()
  const { data: dataCitaCampos, isLoading } = useGetDatosCita({
    idFormato: dataCita.idFormato,
    idCita: dataCita.id
  })
  const navigate = useNavigate()
  // const [metEvaluacion, setMetEvaluacion] = React.useState({
  //   entrevistaObservacion: false,
  //   aplicacionPruebas: false,
  //   entrevistaPadres: false
  // })
  const { especialidad } = useContext(EspecialidadContext)
  // const handleChange = event => {
  //   setMetEvaluacion({
  //     ...metEvaluacion,
  //     [event.target.name]: event.target.checked
  //   })
  // }

  const { mutate, isLoading: isLoadingAtender } = useAtenderPaciente()
  const motivoConsulta = [
    {
      value: 'academico',
      label: 'Académico'
    },
    {
      value: 'familiar',
      label: 'Familiar'
    },
    {
      value: 'cognitivo-conductual',
      label: 'Cognitivo-Conductual'
    },
    {
      value: 'personal-social',
      label: 'Personal-Social'
    },
    {
      value: 'adaptacion-universitaria',
      label: 'Adaptación Universitaria'
    },
    {
      value: 'dificultades-aprendizaje',
      label: 'Dificultades de Aprendizaje'
    },
    {
      value: 'socio-emocional',
      label: 'Socio-Emocional'
    },
    {
      value: 'otro',
      label: 'Otros'
    }
  ]
  function getIdItemByNombreCampo(nombreCampo) {
    const CamposFormato = dataCitaCampos?.formato?.formatoDetalle || []
    const campo = CamposFormato.find(campo => campo.nombreCampo === nombreCampo)
    return campo ? campo.idItem : null
  }
  const comboMotivoconsulta = motivoConsulta
    ? motivoConsulta.map(consulta => {
        return { value: consulta.value, denominacion: consulta.label }
      })
    : []
  const { showError, showSuccess } = useAlertMessage()
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EvaluacionSchema),
    defaultValues: {
      motivoConsulta: '',
      antecedentesPersonales: '',
      entrevistaObservacion: false,
      aplicacionPruebas: false,
      entrevistaPadres: false,
      obsFisica: '',
      obsVerbal: '',
      obsNoVerbal: '',
      dinamicaFamiliar: '',
      maduracion: '',
      indicadoresEmocionales: '',
      indicadoresLesionCrebral: '',
      sugerencias: '',
      semaforo: ''
    }
  })

  const onSubmit = data => {
    const llaves = Object.keys(data)
    const Lista = llaves
      .map(ojb => {
        const idItem = getIdItemByNombreCampo(ojb)

        if (idItem) {
          return {
            idItem: idItem,
            idSubItem: 0,
            valor: data[`${ojb}`].toString()
          }
        }

        return null
      })
      .filter(Boolean)
    const DatosEnviar = {
      idCita: dataCitaCampos.idCita,
      motivoConsulta: data.motivoConsulta,
      antecedentes: data.antecedentesPersonales,
      semaforo: data.semaforo,
      // examenFisico: null,
      // diagóstico: null,
      // tratamiento: null,
      // receta: null,
      obsCita: data.sugerencias,
      consultaDatos: Lista
    }
    const msg = 'No se pudo guardar datos de la atencion o ya fue creada'
    const msg2 = 'Datos guardados correctamente o ya fue creada'
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
        reset()
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }

  console.log("LLEga aqui")
  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <Box className='flex items-center'>
          <IconButton
            onClick={() => setShowFormEvaluacion(!showFormEvaluacion)}
            size='small'
          >
            {showFormEvaluacion ? (
              <VisibilityRoundedIcon />
            ) : (
              <VisibilityOffRoundedIcon />
            )}
          </IconButton>
          <Typography className='font-bold'>Evaluación Psicológica</Typography>
        </Box>
      </ContentMainBox.Title>
      <Collapse in={showFormEvaluacion}>
        <ContentMainBox.Content>
          <Loading isLoading={isLoading}>
            {dataCitaCampos && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  spacing={2}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  alignContent='center'
                  wrap='wrap'
                >
                  <Grid item xs={12}>
                    <DatosGenerales
                      DNI={dataCitaCampos.paciente.dni}
                      triaje={especialidad.requiereTriaje}
                      CamposPaciente={CamposPaciente}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {comboMotivoconsulta.length === 1 ? (
                          <Input
                            control={control}
                            id='motivoConsulta'
                            valorChancado={
                              currecomboMotivoconsultancies[0].value
                            }
                            label='Motivo Consulta'
                            disabled
                          />
                        ) : (
                          <Input
                            control={control}
                            id='motivoConsulta'
                            label='Motivo Consulta'
                            type='select'
                            data={comboMotivoconsulta}
                          />
                        )}

                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.motivoConsulta?.message}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Input
                            control={control}
                            id='antecedentesPersonales'
                            label='Antecedentes Personales'
                            placeholder='Ingrese antecedentes personales'
                            multiline
                            rows={4}
                          />
                          <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                          >
                            {errors.antecedentesPersonales?.message}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container>
                          <Box sx={{ px: 2 }}>
                            <FormLabel
                              label={'margin="dense"'}
                              component='legend'
                            >
                              Métodos y Técnicas de Evaluación
                            </FormLabel>
                            <FormGroup row>
                              <Controller
                                name='entrevistaObservacion'
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label='Entrevista y Observación'
                                  />
                                )}
                              />

                              <Controller
                                name='aplicacionPruebas'
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox {...field} color='primary' />
                                    }
                                    label='Aplicacion de Pruebas Psicológicas'
                                  />
                                )}
                              />

                              <Controller
                                name='entrevistaPadres'
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label='Entrevista a los Padres de familia'
                                  />
                                )}
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel label={'margin="dense"'} component='legend'>
                          Observación de la Conducta
                        </FormLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          control={control}
                          id='obsFisica'
                          label='Física'
                          placeholder='Ingrese observación de la conducta física'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.obsFisica?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          control={control}
                          id='obsVerbal'
                          label='Verbal'
                          placeholder='Ingrese observación de la conducta verbal'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.obsVerbal?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          control={control}
                          id='obsNoVerbal'
                          label='No Verbal'
                          placeholder='Ingrese observación de la conducta no verbal '
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.obsNoVerbal?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel label={'margin="dense"'} component='legend'>
                          Resultados de la Evaluación
                        </FormLabel>
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          control={control}
                          id='dinamicaFamiliar'
                          label='Dinámica Familiar'
                          placeholder='Ingrese resultados del área Dinámica Familiar'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.dinamicaFamiliar?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          control={control}
                          id='maduracion'
                          label='Maduración'
                          placeholder='Ingrese resultados del área Maduración'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.maduracion?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          control={control}
                          id='indicadoresEmocionales'
                          label='Indicadores Emocionales'
                          placeholder='Ingrese resultados del área Indicadores Emocionales'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.indicadoresEmocionales?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          control={control}
                          id='indicadoresLesionCrebral'
                          label='Indicadores de lesión cerebral'
                          placeholder='Ingrese resultados del área Indicadores Emocionales'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.indicadoresLesionCrebral?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          control={control}
                          id='sugerencias'
                          label='Sugerencias u observaciones'
                          placeholder='Deja una sugerencia'
                          multiline
                          rows={3}
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.sugerencias?.message}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Semaforo
                          control={control}
                          id='semaforo'
                          label='Semaforo'
                        />
                        <Typography
                          variant='subtitle2'
                          align='left'
                          color='red'
                        >
                          {errors.semaforo?.message}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent='space-evenly'
                        rowSpacing={2}
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={5}>
                          <Button
                            fullWidth
                            variant='outlined'
                            onClick={() =>
                              navigate(-1)
                            }
                          >
                            Cancelar
                          </Button>
                        </Grid>

                        <Grid item xs={5}>
                          <LoadingButton
                            loading={isLoadingAtender}
                            type='submit'
                            fullWidth
                            variant='contained'
                          >
                            Guardar
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Loading>
        </ContentMainBox.Content>
      </Collapse>
    </ContentMainBox>
  )
}

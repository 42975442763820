import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';

export function pacienteNavConfig() {
  return {
    links: [
      {
        path: '.',
        name: 'Inicio',
        icon: HomeRoundedIcon,
        toolTip: 'Inicio'
      },
      {
        path: 'perfilclinico',
        name: 'Perfil Clinico',
        icon: LocalHospitalRoundedIcon,
        toolTip: 'Perfil Clinico'
      },
      {
        path: 'solicitarcita',
        name: 'Solicitar Cita',
        icon: EventRoundedIcon,
        toolTip: 'Solicitar Cita'
      },
      {
        path: 'miscitas',
        name: 'Mis Citas',
        icon: ListAltRoundedIcon,
        toolTip: 'Mis Citas'
      },
    ]
  }
}

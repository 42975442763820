
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Divider
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useAlertMessage } from '../../../../context/notifications'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Input from '../../../../components/Input'
import ButtonAdd from '../../../../components/ButtonAdd'
import { useGetAllEspecialidad } from '../../../../hooks/useEspecialidades'
import md5 from 'md5'

const EspecialistaSchema = yup.object({
  apPaterno: yup
    .string()
    .required('* Campo requerido'),
  apMaterno: yup
    .string()
    .required('* Campo requerido'),
  nombres: yup
    .string()
    .required('* Campo requerido'),
  dni: yup
    .string()
    .length(8, 'El DNI debe tener 8 dígitos')
    .required('* Campo requerido'),
  cmp: yup
    .string()
    .required('* Campo requerido'),
  idEspecialidad: yup
    .string()
    .required('* Campo requerido'),
})

const FormEspecialista = ({ edit, data, mutate, isLoading }) => {
  const { data: especialidades } = useGetAllEspecialidad()

  const comboEspecialidades =
    especialidades ?
      especialidades.map((esp) => {
        return { value: esp.idEspecialidad, denominacion: esp.desEspecialidad }
      }) : []
  const { handleSubmit, reset, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EspecialistaSchema),
    defaultValues: {
      dni: data.dni || '',
      apPaterno: data?.persona?.apPaterno || '',
      apMaterno: data.apMaterno || '',
      nombres: data.nombres || '',
      idEspecialidad: data.idEspecialidad || '',
      cmp: data.cmp || '',
    }
  })

  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')

  const { showError, showSuccess } = useAlertMessage()
  const { showSuccess: showSuccessContraseña } = useAlertMessage()

  const CambiarContraseña = () => {
    // console.log(md5(data.dni))
    // console.log(data.dni)
    showSuccessContraseña('La contraseña fue restablecida')
  }
  const handleOpen = () => {
    if (edit) {
      reset({
        dni: data?.dni,
        apPaterno: data?.persona?.apPaterno,
        apMaterno: data?.persona?.apMaterno,
        nombres: data?.persona?.nombres,
        idEspecialidad: data?.idEspecialidad,
        cmp: data?.cmp,
        estado: data?.estado
      })
    }
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = data => {
    const formData = {
      nombres: data.nombres,
      apPaterno: data.apPaterno,
      apMaterno: data.apMaterno,
      cmp: data.cmp,
      idEspecialidad: parseFloat(data.idEspecialidad),
      dni: data.dni,
    }
    // console.log(formData)
    const msg = edit ? 'No se pudo editar Especialista' : 'No se pudo crear Especialista'
    const msg2 = edit
      ? 'Especialista editado correctamente'
      : 'Especialista creado correctamente'
    mutate(formData, {
      onError: () => {
        handleClose()
        showError(msg)
        reset()
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  return (
    <>
      {edit ? (
        <Tooltip title='Editar Especialista'>
          <IconButton aria-label='edit' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <ButtonAdd openModal={handleOpen} />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? 'EDITAR ESPECIALISTA' : 'AÑADIR ESPECIALISTA'}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: '1em' }}>
              Información básica de especialista
            </DialogContentText>
            <Grid container spacing={3} alignContent='center'>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='dni'
                  label='DNI'
                  placeholder='Ingrese DNI'
                  disabled={edit}
                  helperText={errors.dni?.message}
                  error={errors.dni}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='apPaterno'
                  label='Apellido paterno'
                  placeholder='Ingrese apellido paterno'
                  helperText={errors.apPaterno?.message}
                  error={errors.apPaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='apMaterno'
                  label='Apellido materno'
                  placeholder='Ingrese apellido materno'
                  helperText={errors.apMaterno?.message}
                  error={errors.apMaterno}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='nombres'
                  label='Nombre(s)'
                  placeholder='Ingrese nombre(s)'
                  helperText={errors.nombres?.message}
                  error={errors.nombres}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='cmp'
                  label='CMP'
                  placeholder='CMP'
                  helperText={errors.cmp?.message}
                  error={errors.cmp}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  control={control}
                  id='idEspecialidad'
                  label='Especialidad'
                  placeholder='Seleccione una Especialidad'
                  type='select'
                  data={comboEspecialidades}
                  helperText={errors.idEspecialidad?.message}
                  error={errors.idEspecialidad}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider>Restablecer Contraseña </Divider>
              </Grid>
              <Grid item xs={12} sm={8} alignItems={'center'} justifyContent={'center'} display={'flex'} >
                <p>Si el especialista olvidó su contraseña se restablecerá con el DNI del especialista </p>
              </Grid>
              <Grid item xs={12} sm={4} alignItems={'center'} justifyContent={'center'} display={'flex'} >
                <Button variant="contained" color="success"
                  onClick={CambiarContraseña}
                >
                  Restablecer
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
              handleClose()
              reset()
            }}>
              CANCELAR
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default FormEspecialista

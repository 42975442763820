import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/horario`)
  return data
}

export const getById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialista] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/horario/${idEspecialista}`)
  return data
}

export const Create = async (body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/horario`, body)
  return data
}

export const getHorarioById = async (id) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/horario/${id}`)
  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put (`${fullPath}/bienestar/horario/${id}`, body)
  return data
}

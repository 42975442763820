import { createContext, useState } from "react";

export const CitaContext=createContext();

const Data={
    idPaciente:null,
    motivoConsulta:"",
    idEspecialista:null,
    idHorarioDet:null,
    fechaCita:"",
    estado:0,
}

export const CitaProvider=({children})=>{
    const [data,setData]=useState(Data)
    return (
        <CitaContext.Provider value={
            {
                data,
                setData
            }
        }>
            {children}

        </CitaContext.Provider>
    )
}
import * as yup from 'yup'

const UsuarioSchema = yup.object({
    email: yup
        .string()
        .required('* Campo requerido'),
    contrasena: yup
        .string()
        .required('* Campo requerido'),
    dni: yup
        .string()
        .required('* Campo requerido'),
    preguntaSecreta: yup
        .string()
        .required('* Campo requerido'),
    respuesta: yup
        .string()
        .required('* Campo requerido'),
    perfilCodigo: yup
        .number()
        .required('* Campo requerido'),
})
export default UsuarioSchema

import * as yup from 'yup'

const FormatoSchema = yup.object({
    nombreFormato: yup
        .string()
        .required('* Campo requerido'),
    rutaEspecialidad: yup
        .string()
        .required('* Campo requerido'),
    idEspecialidad: yup
        .number()
        .required('* Campo requerido'),
    estado: yup
        .number()
        .required('* Campo requerido'),
})
export default FormatoSchema

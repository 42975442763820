import { Navigate, Outlet } from 'react-router-dom';
import { isLoggedIn } from '../services/login';


export const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
  const login = isLoggedIn();
  if (!login) {
    return <Navigate to={redirectTo}></Navigate>
  }
  var URLactual = window.location.href;
  const rutas = JSON.parse(localStorage.getItem("_p"))
  let existe = false
  for (let i = 0; i < rutas.length; i++) {
    if (URLactual.includes(rutas[i].acceso.rol + '/' + rutas[i].acceso.ruta)) {
      existe = true
    }
  }
  if (!existe) {
    localStorage.removeItem('token')
    localStorage.removeItem('_p')
    alert('Acceso denegado')
    return <Navigate to={redirectTo}></Navigate>
  }
  return (
    <>
      {
        children ? children : <Outlet />
      }
    </>
  )
}

import { useCreateAcceso } from '../../../../hooks/useAccesos'
import FormAccesos from './FormAccesos'
const CreateAccesos = () => {
    const { mutate, isLoading } = useCreateAcceso()
    return (
        <>
            <FormAccesos edit={false} id={''} mutate={mutate} isLoading={isLoading} />
        </>
    )
}
export default CreateAccesos
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { Button, ButtonGroup, Chip, Grid } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { useFilterByPaciente } from '../../../components/Filters'
import Input from '../../../components/Input'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { UserContext } from '../../../context/userContext'
import { useGetCitasEspecialista } from '../../../hooks/back-clinica/useCitas'
import { COLOR_ESTADO, ESTADOS } from '../../../constants'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecha',
    minWidth: 100,
    flex: 0.1,
    headerName: 'Fecha',
    type: 'date',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    minWidth: 120,
    flex: 0.1,
    headerName: 'Hora',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'dni',
    headerName: 'DNI',
    minWidth: 100,
    flex: 0.1,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'paciente',
    headerName: 'Paciente',
    minWidth: 200,
    flex: 0.5,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estado',
    headerName: 'Estado',
    minWidth: 100,
    flex: 0.1,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => {
      return <Chip size="small" color={COLOR_ESTADO[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={ESTADOS[value]}
      />
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    headerName: 'Atender',
    width: 220,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      const navigate = useNavigate()
      const { especialidad } = useContext(UserContext)
      // Dirigir a especialidad
      let fichaEspecialidad = ''
      if (especialidad.desEspecialidad.toLowerCase() === 'nutrición')
        fichaEspecialidad = 'modulonutricion'
      if (especialidad.desEspecialidad.toLowerCase() === 'psicología')
        fichaEspecialidad = 'modulopsicologia'
      if (especialidad.desEspecialidad.toLowerCase() === 'medicina general')
        fichaEspecialidad = 'medicinageneral'
      if (especialidad.desEspecialidad.toLowerCase() === 'odontología')
        fichaEspecialidad = 'odontologia'

      return (
        <div className='space-x-2'>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => navigate(`/especialista/${fichaEspecialidad}`, { state: row })}
          >
            Atender
          </Button>
        </div>

      )
    }
  }
]

export const NuevasConsultas = () => {
  const { especialidad } = useContext(UserContext)

  const { control, handleSubmit, formState, reset, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      dniPaciente: '',
    }
  })

  const isValid = formState.isValid

  const { data: nuevasConsultas, isLoading, refetch } = useGetCitasEspecialista({
    idEspecialista: especialidad.idEspecialista,
    dniPaciente: watch('dniPaciente'),
    estado: 'pendiente'
  }, false)

  const comboCitas =
    nuevasConsultas ?
      nuevasConsultas.data.map((cita) => {
        const { nombres, apPaterno, apMaterno, dni } = cita?.paciente?.persona
        const paciente = `${nombres} ${apPaterno} ${apMaterno}`.toUpperCase()
        const horario = cita.horarioDetalle
        const hora = horario ? `${horario.horaIni} - ${horario.horaFin}` : 'Desconocido'
        return {
          id: cita.idCita,
          fecha: cita.fechaCita,
          hora,
          paciente,
          dni,
          estado: cita.estado,
        }
      })
      : []

  const handleSearch = () => {
    refetch()
  }
  const clearFilters = () => {
    reset()
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Nuevas Consultas
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} >
                <Input
                  control={control}
                  id='dniPaciente'
                  label='Buscar paciente (DNI)'
                  type='number'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
                  <Button
                    sx={{ width: '80%' }}
                    variant='contained'
                    startIcon={<FilterAltRoundedIcon />}
                    type='submit'
                    disabled={!isValid}
                  >
                    Filtrar
                  </Button>
                  <Button
                    sx={{ width: '20%' }}
                    color='primary'
                    onClick={clearFilters}
                  >
                    <FilterAltOffRoundedIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item className='mt-4' xs={12}>
                <Loading isLoading={isLoading} >
                  <DataGridStyle
                    height={380}
                    rows={comboCitas}
                    columns={columns}
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row) => row.id}
                  />
                </Loading>
              </Grid>
            </Grid>
          </form>
        </ContentMainBox.Content>
      </ContentMainBox >
    </>
  )
}
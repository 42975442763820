import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Divider, Typography } from '@mui/material';

export default function DataGridListMobile({ rows, columns }) {
    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto' }}
            subheader={
                <ListSubheader
                    sx={{
                        backgroundColor: '#99D3D6'
                    }}
                >
                    Listado por {columns[0].headerName}
                </ListSubheader>
            }
        >
            {
                rows?.map((row, i) => (
                    <div key={i}>
                        <ListItemData row={row} columns={columns} />
                        <Divider />
                    </div>
                ))
            }
        </List>
    );
}

export const ListItemData = ({ row, columns }) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton
                onClick={handleClick}
                sx={{
                    backgroundColor: open ? '#ddd' : '#fff'
                }}
            >
                <ListItemText primary={row[columns[0].field]} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {
                        columns.map((column) => (
                            <div key={column.field}>
                                {
                                    row[column.field] &&
                                    <>
                                        <ListItemButton sx={{ pl: 4, display: 'block' }}>
                                            <Typography variant="subtitle2" color="initial">{column.headerName}</Typography>
                                            <Typography variant="body2" color="initial">{row[column.field]}</Typography>
                                        </ListItemButton>
                                    </>
                                }
                            </div>
                        ))
                    }
                </List>
            </Collapse>
        </>
    )
}

import { yupResolver } from "@hookform/resolvers/yup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import { useAlertMessage } from "../../../../context/notifications";
import { useGetAllPerfiles } from "../../../../hooks/usePerfiles";
import {
  useGetByIdFormato,
} from "../../../../hooks/useFormatos";
import Loading from "../../../../components/Loading";
import FormatoSchema from "../schema/formatos.schema";
import { useGetAllEspecialidad } from "../../../../hooks/useEspecialidades";

const DialogForm = ({ edit, id, mutate, isLoading, open, handleClose }) => {
  const { data: Formato, isLoading: LoadingFormato } = useGetByIdFormato(id);
  const { data: especialidades } = useGetAllEspecialidad();

  const comboEspecialidades =
    especialidades ?
      especialidades.map((esp) => {
        return { value: esp?.idEspecialidad, denominacion: esp?.desEspecialidad }
      }) : []


  const { showError, showSuccess } = useAlertMessage();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(FormatoSchema),
    defaultValues: {
      nombreFormato: Formato?.nombreFormato || "",
      rutaEspecialidad: Formato?.rutaEspecialidad || "",
      idEspecialidad: Formato?.idEspecialidad || "",
      estado: Formato?.estado || 1,
    },
  });

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const onSubmit = (data) => {
    let formData = {
      nombreFormato: data?.nombreFormato,
      rutaEspecialidad: data.rutaEspecialidad,
      idEspecialidad: data.idEspecialidad,
      estado: 1,
    };
    const successMessage = edit ? "Formato editado con exito" : "Formato creado con exito";
    const errorMessage = edit ? "No se pudo editar Formato" : "No se pudo crear Formato";

    mutate(formData, {
      onError: () => {
        handleClose();
        showError(errorMessage);
        reset();
      },
      onSuccess: () => {
        handleClose();
        showSuccess(successMessage);
        reset();
      },
    });
  };

  useEffect(() => {
    if (edit)
      reset({
        nombreFormato: Formato?.nombreFormato || "",
        rutaEspecialidad: Formato?.rutaEspecialidad || "",
        idEspecialidad: Formato?.idEspecialidad || "",
        estado: Formato?.estado || true,
      })
  }, [Formato])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? "EDITAR Formato" : "AÑADIR Formato"}</DialogTitle>
        <DialogContent>


          <Grid container >
            <Grid item xs={12} >
              <Divider variant='middle'></Divider>
            </Grid>
          </Grid>
          <Loading isLoading={edit ? LoadingFormato : false}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText sx={{ marginBottom: "1em" }}>
                  Información de Formato
                </DialogContentText>
                <Grid container spacing={2} alignContent="center">
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="nombreFormato"
                      label="nombreFormato"
                      placeholder="Ingrese nombreFormato"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.nombreFormato?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="rutaEspecialidad"
                      label="rutaEspecialidad"
                      placeholder="Ingrese nombre rutaEspecialidad"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.rutaEspecialidad?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="idEspecialidad"
                      label="Especialidad"
                      type="select"
                      data={comboEspecialidades}
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.idEspecialidad?.message}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelRoundedIcon />}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                >
                  CANCELAR
                </Button>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  GUARDAR
                </LoadingButton>
              </DialogActions>
            </form>
          </Loading>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogForm;

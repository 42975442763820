import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import ContentMainBox from '../../../components/MainBox'
import Input from '../../../components/Input'
import { Button, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useCreateTriaje, useGetByIdTriaje, useGetTriaje } from '../../../hooks/useTriaje'
import { CreateTriaje } from './components/CreateTriaje'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { VerTriaje } from './components/VerTriaje'
import { UpdateTriaje } from './components/UpdateTriaje'
import { DeleteTriaje } from './components/DeleteTriaje'
const columns = [
  // { headerClassName: 'super-app-theme--header', field: 'id', headerName: 'ID', width: 150 },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nroDocPaciente',
    headerName: 'DNI',
    width: 100,
    flex: 0.3,
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecha',
    headerName: 'Fecha',
    width: 200,
    flex: 0.3,
    valueFormatter: ({ value }) => {
      return value?.split('T')[0] ?? '--'
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    type: 'actions',
    headerName: 'Acción',
    cellClassName: 'accion',
    width: 200,
    getActions: ({ row }) => {
      return [
        <VerTriaje data={row} />,
        <UpdateTriaje data={row} />
      ]
    }
  }
]

export const AtencionTriaje = () => {
  const navigate = useNavigate();
  const { data: triajes } = useGetTriaje();
  console.log(triajes)
  const comboTriaje =
    triajes ?
      triajes.map((triaje) => {
        return {
          id: triaje.idTriaje,
          nroDocPaciente: triaje.nroDocPaciente,
          presionArterial: triaje.presionArterial,
          temperatura: triaje.temperatura,
          frecuenciaRespiratoria: triaje.frecuenciaRespiratoria,
          frecuenciaCardiaca: triaje.frecuenciaCardiaca,
          saturacion: triaje.saturacion,
          peso: triaje.peso,
          fecha: triaje.fechaHora,
          imc: triaje.imc,
          talla: triaje.talla
        }
      }) : []

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Atención triaje
          </div>
        </ContentMainBox.Title>

        <ContentMainBox.Content>
          <Grid
            container
            spacing={2}
            justifyContent="center" sx={{ mx: 2, mt: 0 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} >
              <CreateTriaje />
            </Grid>
            <Grid item xs={12}>
              <DataGridStyle
                height={400}
                rows={comboTriaje}
                columns={columns}
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={() => Math.random()}
              />
            </Grid>
          </Grid>

        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import { Box, Button, Grid } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export const ModuloNutricion = () => {

  const navigate = useNavigate()
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita

  const buttonStyle = {
    backgroundColor: '#f2f4f4',
    border: '1px solid #9d9d9d',
    color: '#000',
    justifyContent: 'flex-start',
    padding: '15px 30px',
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            sx={buttonStyle}
            onClick={()=>navigate("/especialista/modulonutricion/historia-clinica-nutricional", {state: dataCita})}
          >
            1. Historia Clínica Nutricional
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            sx={buttonStyle}
            onClick={()=>navigate("/especialista/modulonutricion/recordatorio-24horas", {state: dataCita})}
          >
            2. Recordatorio de 24 horas
          </Button>
        </Grid>
      </Grid>

      <Button
        sx={{
          position: 'absolute',
          bottom: 40,
          right: 40,
          padding: '10px 50px 10px 40px'
        }}
        variant="outlined"
        startIcon={<KeyboardBackspaceRoundedIcon />}
        onClick={()=>navigate(-1)}
      >
        Regresar
      </Button>
    </Box>
  )
}

import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { HourComponent } from './HourComponent'

const colors = {
  activo: '#4ade80',
  cita: '#3191ff',
  inactive: '#fca5a5'
}
const USER_ID = 7
export const Schedule = ({
  horaInicio = '07:00',
  horaFin = '20:00',
  intervalos = '0:30',
  horario,
  setHorario,
  citas
}) => {
  intervalos = timeConverter(intervalos)
  // const { mutate: mutateestado } = useUpdateestado()
  // const { mutate: mutateNuevoHorario } = useCreateNuevoHorario()

  const days = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes']
  const intervaloHoras = getTimeRange(horaInicio, horaFin, intervalos)

  // const hashCitas = useMemo(() => {
  //   return citas?.reduce(function (map, obj) {
  //     map[obj.horarioId] = obj
  //     return map
  //   }, {})
  // }, [])

  const hashCitas = citas?.reduce(function (map, obj) {
    map[obj.horarioId] = obj
    return map
  }, {})

  const findHour = (horaInicio, horaFin, diaHorario) => {
    const index = horario.findIndex(
      item =>
        item.horaInicio === horaInicio &&
        item.horaFin === horaFin &&
        item.diaHorario === diaHorario
    )
    return index
  }
  const findIndexHour = (horaInicio, horaFin, diaHorario) => {
    const findHour = horario?.find(
      item =>
        item.horaInicio === horaInicio &&
        item.horaFin === horaFin &&
        item.diaHorario === diaHorario
    )
    // if (hashCitas && hashCitas[findHour?.id]) {
    //   const fecha = hashCitas[findHour?.id].fechaCita
    //   return (
    //     <HourComponent className="bg-sky-500  text-white">
    //       <Typography variant='subtitle'>{fecha}</Typography>
    //     </HourComponent>
    //   )
    // }

    switch (findHour?.estado) {
      case 1:
        return (
          <HourComponent
            style={{ backgroundColor: colors.activo }}
            onClick={() => handleSelect(horaInicio, horaFin, diaHorario)}
          />
        )
      case 0:
        return (
          <HourComponent
            onClick={() => handleSelect(horaInicio, horaFin, diaHorario)}
          />
        )
      default:
        return (
          <HourComponent
            onClick={() => handleSelect(horaInicio, horaFin, diaHorario)}
          />
        )
    }
  }
  const changeestado = (index, nuevoEstado) => {
    const findedHour = horario[index]
    const body = {
      diaHorario: findedHour.diaHorario,
      horaInicio: findedHour.horaInicio,
      horaFin: findedHour.horaFin,
      especialistaId: USER_ID
    }
    const idHorario = findedHour.id
    horario[index] = {
      ...horario[index],
      estado: nuevoEstado
    }
    setHorario([...horario])
    // mutateestado({
    //   id: idHorario,
    //   body: {
    //     ...body,
    //     estado: nuevoEstado
    //   }
    // })
  }

  const handleSelect = (horaInicio, horaFin, diaHorario) => {
    const index = findHour(horaInicio, horaFin, diaHorario)
    const findedHour = horario[index]
    if (index >= 0)
      switch (findedHour.estado) {
        case 1:
          changeestado(index, 0)
          break
        case 0:
          changeestado(index, 1)
          break
        case '':
          // changeestado(index, 'inactivo')
          break
      }
    else {
      const body = {
        diaHorario: diaHorario,
        horaInicio: horaInicio,
        horaFin: horaFin,
        especialistaId: USER_ID,
        estado: 1
      }
      // mutateNuevoHorario(body)
      setHorario([...horario, body])
    }
  }

  // useEffect(() => {
  //   setHorario(horario)
  // }, [horario])
  return (
    <Box
      sx={{
        border: 2,
        borderColor: 'grey.300',
        borderRadius: 1
      }}
    >
      <Box
        sx={{
          backgroundColor: 'grey.100',
          display: 'flex',
          width: '100%',
          height: 40,
          borderBottom: 2,
          borderColor: 'grey.300',
          paddingRight: '1rem'
        }}
      >
        <Box
          sx={{
            width: '8rem',
            display: 'flex',
            flexShrink: 0,
            borderRadius: 1,
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 600,
            textAlign: 'center',
            marginBottom: '0.25rem'
          }}
        >
          <Typography variant='subtitle'>Hora</Typography>
        </Box>
        {days.map(day => {
          return (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                fontWeight: 600,
                textAlign: 'center',
                backgroundColor: 'grey.100'
              }}
              key={day}
            >
              <Typography variant='subtitle'>
                {day.charAt(0).toUpperCase() + day.slice(1)}
              </Typography>
            </Box>
          )
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: window.innerHeight - 460,
          overflow: 'hidden',
          overflowY: 'scroll'
        }}
      >
        <Box
          sx={{
            width: '8rem',
            margin: '0.25rem'
          }}
        >
          {intervaloHoras.map(hora => {
            return (
              <Box
                key={hora.horaInicio}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 40,
                  borderRadius: 1,
                  color: 'grey.500',
                  fontWeight: 600,
                  marginBottom: '0.25rem'
                }}
              >
                <Typography variant='subtitle' align='center'>
                  {hora.horaInicio} - {hora.horaFin}
                </Typography>
              </Box>
            )
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%'
          }}
        >
          {horario &&
            days.map((dia, idx) => (
              <Box sx={{ width: '100%', margin: '0.25rem' }} key={idx}>
                {intervaloHoras.map((hour, index) => {
                  return (
                    <Box key={index} sx={{ marginBottom: '0.25rem' }}>
                      {findIndexHour(hour.horaInicio, hour.horaFin, dia)}
                    </Box>
                  )
                })}
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'grey.100',
          borderTop: 2,
          borderColor: 'grey.300',
          display: 'flex',
          gap: 2,
          paddingX: 1
        }}
      >
        <Box
          className='flex items-center gap-2'
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            paddingX: 1
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.activo,
              width: '1rem',
              height: '1rem',
              borderRadius: '50%'
            }}
          ></Box>
          <Typography variant='body2'>Horarios disponibles</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            paddingX: 1
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.cita,
              width: '1rem',
              height: '1rem',
              borderRadius: '50%'
            }}
          ></Box>
          <Typography variant='body2'>Cita medica</Typography>
        </Box>
      </Box>
    </Box>
  )
}

function formartHour(intervaloHoras, minutes) {
  const formartHour =
    (intervaloHoras < 10 ? '0' : '') +
    intervaloHoras +
    ':' +
    (minutes < 10 ? '0' : '') +
    minutes
  return formartHour
}

function getTimeRange(startTime, endTime, interval) {
  let [startHour, startMinutes] = startTime.split(':').map(Number)
  let [endHour, endMinutes] = endTime.split(':').map(Number)
  let timeRange = []
  let intervalParts = interval.split(':')
  let intervalHour = parseInt(intervalParts[0])
  let intervalMinutes = parseInt(intervalParts[1])

  while (startHour < endHour) {
    timeRange.push({
      horaInicio: formartHour(startHour, startMinutes),
      horaFin: ''
    })
    startHour += intervalHour
    startMinutes += intervalMinutes
    if (startMinutes >= 60) {
      startHour++
      startMinutes = startMinutes - 60
    }
    if (startHour === endHour) {
      if (startMinutes > endMinutes) {
        break
      }
    }
  }

  timeRange = timeRange.map((item, index) => {
    if (index === timeRange.length - 1) {
      item.horaFin = formartHour(endHour, endMinutes)
    } else {
      item.horaFin = timeRange[index + 1].horaInicio
    }
    return item
  })
  return timeRange
}
function timeConverter(min) {
  let intervaloHoras = Math.floor(min / 60)
  min = min % 60
  let output = `${intervaloHoras.toString().padStart(2, '0')}:${min
    .toString()
    .padStart(2, '0')}`
  return output
}

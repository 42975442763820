import { axiosClinicaClient } from "../api/axios";

export const getFormatosCita = async ({ queryKey }) => {
  const [, idEspecialidad] = queryKey
  const { data } = await axiosClinicaClient.get(`/procesos/atencion/formatos/${idEspecialidad}`)
  return data
}

export const getDatosCita = async ({ queryKey }) => {
  const [, idFormato, idCita] = queryKey
  const { data } = await axiosClinicaClient.get(`/procesos/atencion/datos-cita/${idFormato}/${idCita}`)
  return data
}

export const atenderPaciente = async (body) => {
  const { data } = await axiosClinicaClient.post(`/procesos/atencion/datos-cita`, body)
  return data
}

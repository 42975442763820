import { Grid } from '@mui/material'
import CreateFormatoDetalles from './components/CreateFormatoDetalles'
import UpdateFormatoDetalles from './components/UpdateFormatoDetalles'
import DeleteFormatoDetalles from './components/DeleteFormatoDetalles'
import { useGetAllByFormato, useGetAllFormatoDetalles } from '../../../hooks/useFormatoDetalles'
import ContentMainBox from '../../../components/MainBox'
import Input from '../../../components/Input'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import UpdateEstado from './components/UpdateEstado'
import { useLocation } from 'react-router-dom'

const columns = [
    {
        headerClassName: 'super-app-theme--header ',
        field: 'nombreCampo',
        headerName: 'Nombre Campo',
        minWidth: 300,
        flex: 0.5,
        type: 'string',
    },
    {
        headerClassName: 'super-app-theme--header ',
        field: 'tipoValor',
        headerName: 'Tipo Valor',
        minWidth: 300,
        flex: 0.5,
        type: 'string',
    },
    {
        headerClassName: 'super-app-theme--header',
        cellClassName: 'uppercase',
        field: 'Estado',
        headerName: 'Estado',
        minWidth: 100,
        flex: 0.5,
        renderCell: ({ row }) => {
            return (
                <UpdateEstado data={row} />
            )
        },
    },
    {
        headerClassName: 'super-app-theme--header',
        field: 'action',
        type: 'actions',
        headerName: 'Acción',
        cellClassName: 'accions',
        width: 250,
        getActions: ({ row }, i) => {
            return [
                <UpdateFormatoDetalles id={row.idItem} key={i} />,
                <DeleteFormatoDetalles id={row.idItem} key={i} />,
            ]
        },
    }
]

export const FormatoDetalles = () => {
    const { state: formato } = useLocation()
    const { data: FormatoDetalles } = useGetAllByFormato(formato.idFormato)
    return (
        <>
            <ContentMainBox>
                <ContentMainBox.Title>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item >
                            DETALLES DE FORMATO DE {formato.nombreFormato.toUpperCase()}
                        </Grid>
                        <Grid item >
                            <CreateFormatoDetalles />
                        </Grid>
                    </Grid>
                </ContentMainBox.Title>
                <ContentMainBox.Content>
                    <Grid container spacing={2} >

                        <Grid item xs={12}>
                            <DataGridStyle
                                height={380}
                                rows={FormatoDetalles || []}
                                columns={columns}
                                experimentalFeatures={{ newEditingApi: true }}
                                getRowId={(row) => row.idItem}
                            />
                        </Grid>
                    </Grid>
                </ContentMainBox.Content>
            </ContentMainBox>
        </>
    )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, MenuItem, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Fondo from '../../Login/Components/assets/images/Fondo.jpg';
import ContentMainBox from '../../../components/MainBox'
import { useAlertMessage } from '../../../context/notifications'
import { useCreateCita, useGetCitasByIdEspecialista } from '../../../hooks/useCitas'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidadesExternas'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import { useGetAllHorarioDetalle } from '../../../hooks/useHorarioDet'
import { CitaContext } from '../../../context/citasContext'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router'
import { UserContext } from '../../../context/userContext'

const SemestreSchema = yup.object({
  pacienteId: yup
    .number()
    .required('Campo requerido'),
  especialidadId: yup
    .number()
    .required('Campo requerido'),
  especialistaId: yup
    .number()
    .required('Campo requerido'),
  horarioDetId: yup
    .number()
    .required('Campo requerido'),
  fechaCita: yup
    .date('Ingresar una fecha válida')
    .required('Campo requerido'),
})

const PACIENTE_ID = 1

export const SolicitarCitaExterno = () => {
  const { showError, showSuccess } = useAlertMessage()
  const { mutate } = useCreateCita()

  const [motivoConsulta, setMotivoConsulta] = useState('')
  const [especialidad, setEspecialidad] = useState('')
  const [especialista, setEspecialista] = useState('')
  const [horarioDet, setHorarioDet] = useState()
  const [fechaCita, setFechaCita] = useState(new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' }));

  let { data: Especialistas } = useGetAllEspecialista() || []
  let { data: Especialidades } = useGetAllEspecialidad() || []
  let { data: HorarioDetalle } = useGetAllHorarioDetalle() || []
  let { data: CitasByEsp, refetch } = useGetCitasByIdEspecialista(especialista, false) || []
  const Citas = CitasByEsp ? CitasByEsp : []

  useEffect(() => { refetch() }, [especialista])

  const tranformarDia = (fecha) => {
    let diaLetra = new Date(fecha).getDay()
    if (diaLetra === 0) return 'lunes'
    if (diaLetra === 1) return 'martes'
    if (diaLetra === 2) return 'miercoles'
    if (diaLetra === 3) return 'jueves'
    if (diaLetra === 4) return 'viernes'
    else return 'feriado'
  }
  const [dia, setDia] = useState(tranformarDia(fechaCita));

  const comboHorariosDet = HorarioDetalle
    ? HorarioDetalle.flatMap(({ horarioDetalle: horarioDet, idEspecialista }) => {
      return horarioDet.map((item) => ({
        horarioDetId: item.idHorarioDet,
        diaHorario: item.dia,
        hora: item.horaIni,
        especialistaId: idEspecialista
      }))
    })
    : [
      {
        horarioDetId: 1,
        diaHorario: 'lunes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
      {
        horarioDetId: 2,
        diaHorario: 'martes',
        hora: '11:00',
        nombres: 'Gabriel Amaro Agap Peru'
      },
    ]
  const comboEspecialistas =
    Especialistas ?
      Especialistas.map((especialista) => {
        return {
          especialistaId: especialista.idEspecialista,
          denominacion: especialista.persona.nombres + ' ' + especialista.persona.apPaterno + ' ' + especialista.persona.apMaterno,
          especialidadId: especialista.idEspecialidad
        }
      }) : 
      [
        {
          especialistaId: 1,
          denominacion: 'Juan Bautista'
        },
        {
          especialistaId: 2,
          denominacion: 'Jose Parra'
        },
      ]

  const comboEspecialidades =
    Especialidades ?
      Especialidades.map((especialidad) => {
        return { especialidadId: especialidad.idEspecialidad, denominacion: especialidad.desEspecialidad }
      }) : [
        {
          especialidadId: 1,
          denominacion: 'Medicina General'
        },
        {
          especialidadId: 2,
          denominacion: 'Psicología'
        },
      ]

  const { handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(SemestreSchema),
    defaultValues: {
      pacienteId: PACIENTE_ID,
      motivoConsulta: '',
      especialidadId: 1,
      especialistaId: 1,
      horarioDetId: 1,
      fechaCita: new Date(),
    }
  })
  const token=localStorage.getItem("token")
    if(token){
      const tokenDecoded=jwtDecode(token)
      console.log("aqui el token",tokenDecoded)
    }
  else{
    console.log("no hay token")
  }

  const redireccion=useNavigate()
  const {setData}=useContext(CitaContext)//------------
  const { setRol } = useContext(UserContext)

  const onSubmit = () => {
    const formData = {
      idPaciente: PACIENTE_ID,
      motivoConsulta: motivoConsulta,
      idEspecialista: especialista,
      idHorarioDet: horarioDet,
      fechaCita: fechaCita,
      estado: 0
    }
    const msg = 'No se pudo crear Cita'
    const msg2 = 'Cita creada correctamente'
    console.log('SUBMIT')

    //no esta logueado?
    const token=localStorage.getItem("token")
    if(token){
      mutate(formData, {
        onError: () => {
          showError(msg)
        },
        onSuccess: () => {
          showSuccess(msg2)
        }
      })
      
    }
    else{
      setData(formData)
      setRol('paciente')
      redireccion('/login')
    }
  
    

  }

  const newHorasDisponibles = comboHorariosDet.filter((horarioDet) => {
    if (fechaCita !== '' && especialista !== '') {
      if (horarioDet.diaHorario === dia && horarioDet.especialistaId === especialista) {
        const result = Citas.find((cita) =>
          cita.idHorarioDet === horarioDet.horarioDetId && cita.fechaCita.slice(0, 10) === fechaCita);
        if (result === undefined) return horarioDet
      }
    }
    else {
      return horarioDet
    }
  })
  const styles = {
    root: {
      backgroundImage: `url(${Fondo})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      display: 'flex',
      height: '100vh',
      position: 'relative',
      minHeight: '100vh'
  
    },
    overOpacity: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#999999',
      opacity: 0.5,
    },
    content: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  
    }
  
  };
  return (
    <>
    <div className={{ width: '100%', height: '100vh' }}>
      <div style={styles.root}></div>
      <div style={styles.overOpacity}></div>
      <div style={styles.content}  >
      <Typography 
          variant="h3" 
          color={'#000000'} 
          align='start' 
          fontFamily={'BlinkMacSystemFont'} 
          fontSize={50}
          fontWeight= {'bold'}
          padding= {"80px 250px"}>
          
            SOLICITAR CITA
      </Typography> 
          <form onSubmit={handleSubmit(onSubmit)}>
            
            <Grid
              container
              spacing={0.5}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              className='mt-2'
            >
              <Grid item xs={2}></Grid>
              
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={2}
                >
                  
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id='motivoConsulta'
                      label='Motivo Consulta'
                      onChange={(e) => setMotivoConsulta(e.target.value)}
                      size="small">
                    </TextField>
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      id='especialidadId'
                      label='Especialidad'
                      select
                      value={especialidad}
                      onChange={(e) => setEspecialidad(e.target.value)}
                      size="small">
                      {comboEspecialidades.map((option, index) => (
                        <MenuItem key={index} value={option.especialidadId}>
                          {option.denominacion}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='especialistaId'
                      label='Especialista'
                      select
                      defaultValue=''
                      value={especialista}
                      onChange={(e) => setEspecialista(e.target.value)}
                      size="small"
                      disabled={especialidad === '' ? true : false}
                    >
                      {/*comboEspecialistas.map((option, index) => (
                        <MenuItem key={index} value={3028}>
                          {option.denominacion}
                        </MenuItem>
                      ))*/}
                      {comboEspecialistas.filter((unidad) => {
                        if (especialidad !== '') {
                          if (unidad.especialidadId === especialidad) {
                            return unidad
                          }
                        }
                        else {
                          return unidad
                        }
                      }).map((option, index) => (
                        <MenuItem key={index} value={option.especialistaId}>
                          {option.denominacion}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>

                    <TextField
                      fullWidth
                      id='fechaCita'
                      label='Fecha'
                      type='date'
                      size='small'
                      value={fechaCita}
                      onChange={(e) => {
                        setFechaCita(e.target.value)
                        setDia(tranformarDia(e.target.value))
                      }}
                      disabled={especialista === '' ? true : false}
                    />

                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      wrap="wrap"
                    >
                      {(especialista != '') ? (newHorasDisponibles.length === 0
                        ? <Typography variant='body1' className='mx-auto'>No hay horarios disponibles para este día</Typography>
                        : newHorasDisponibles.map((option, i) => (
                          <Grid item xs={2} key={i}>
                            <Button
                              onClick={() => setHorarioDet(option.horarioDetId)}
                              variant={horarioDet === option.horarioDetId ? 'contained' : 'outlined'}
                              color='primary'
                              size='large'
                            >
                              {option.hora}
                            </Button>
                          </Grid>
                        ))) :
                        <></>}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type='submit'
                      color='primary'
                      fullWidth
                      disabled={!especialidad || !especialista || !horarioDet || !fechaCita}
                    >
                      Crear Cita
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
              <Typography 
                  variant="h3" 
                  color={'#000000'} 
                  align='start' 
                  fontFamily={'BlinkMacSystemFont'} 
                  fontSize={50}
                  fontWeight= {'bold'}
                  padding= {"10px 90px"}>
          
                  DECLARACIÓN JURADA DE SALUD dirigida a alumnos, docentes y directores de la UNIQ.
              </Typography> 
              </Grid>
            </Grid>
          </form>
          </div>
          </div>
    </>
  )
}
import { useCreatePerfil } from '../../../../hooks/usePerfiles'
import FormPerfiles from './FormPerfiles'
const CreatePerfiles = () => {
    const { mutate, isLoading } = useCreatePerfil()
    return (
        <>
            <FormPerfiles edit={false} id={''} mutate={mutate} isLoading={isLoading} />
        </>
    )
}
export default CreatePerfiles
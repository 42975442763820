import { yupResolver } from "@hookform/resolvers/yup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import { useAlertMessage } from "../../../../context/notifications";
import { useGetAllPerfiles } from "../../../../hooks/usePerfiles";
import {
  useGetByIdUsuario,
} from "../../../../hooks/useUsuarios";
import Loading from "../../../../components/Loading";
import UsuarioSchema from "../schema/usuarios.schema";

const DialogForm = ({ edit, id, mutate, isLoading, open, handleClose }) => {

  // console.log(id)
  const { data: usuario, isLoading: LoadingUsuario } = useGetByIdUsuario(id);
  const { data: perfiles } = useGetAllPerfiles();

  const comboPerfil = perfiles
    ? perfiles.map((perfil) => {
      return {
        value: perfil.perfilCodigo,
        denominacion: perfil.descripcion,
      };
    })
    : [];


  const { showError, showSuccess } = useAlertMessage();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(UsuarioSchema),
    defaultValues: {
      email: usuario?.email || "",
      contrasena: "",
      dni: usuario?.dni || "",
      preguntaSecreta: usuario?.preguntaSecreta || "",
      respuesta: usuario?.respuesta || "",
      estado: usuario?.estado || 1,
      perfilCodigo: usuario?.perfilCodigo || "",
    },
  });

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const onSubmit = (data) => {
    let formData = {
      contrasena: data.contrasena,
      dni: data.dni,
      preguntaSecreta: data.preguntaSecreta,
      respuesta: data.respuesta,
      estado: 1,
      perfilCodigo: data.perfilCodigo,
    };
    if (!edit) {
      formData = {
        ...formData,
        email: data.email,
      };
    }
    // console.log(formData)
    const successMessage = edit ? "Usuario editado con exito" : "Usuario creado con exito";
    const errorMessage = edit ? "No se pudo editar usuario" : "No se pudo crear usuario";

    mutate(formData, {
      onError: () => {
        handleClose();
        showError(errorMessage);
        reset();
      },
      onSuccess: () => {
        handleClose();
        showSuccess(successMessage);
        reset();
      },
    });
  };

  useEffect(() => {
    if (edit)
      reset({
        email: usuario?.email || "",
        contrasena: "",
        dni: usuario?.dni || "",
        preguntaSecreta: usuario?.preguntaSecreta || "",
        respuesta: usuario?.respuesta || "",
        estado: usuario?.estado || 1,
        perfilCodigo: usuario?.perfilCodigo || "",
      })
  }, [usuario])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? "EDITAR USUARIO" : "AÑADIR USUARIO"}</DialogTitle>
        <DialogContent>


          <Grid container >
            <Grid item xs={12} >
              <Divider variant='middle'></Divider>
            </Grid>
          </Grid>
          <Loading isLoading={edit ? LoadingUsuario : false}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText sx={{ marginBottom: "1em" }}>
                  Información de usuario
                </DialogContentText>
                <Grid container spacing={2} alignContent="center">
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="dni"
                      label="DNI"
                      placeholder="Ingrese nombre DNI"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.dni?.message}
                    </Typography>
                  </Grid>
                  {!edit && (
                    <Grid item xs={12}>
                      <Input
                        control={control}
                        id="email"
                        label="Email"
                        placeholder="Ingrese email"
                      />
                      <Typography variant="subtitle2" align="left" color="red">
                        {errors.email?.message}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="contrasena"
                      label="Contraseña"
                      placeholder="Ingrese contraseña"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.contrasena?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="preguntaSecreta"
                      label="Pregunta secreta"
                      type="select"
                      data={[
                        {
                          value: "PreguntaA",
                          denominacion: "¿Cuál es tu fruta favorita?",
                        },
                        {
                          value: "PreguntaB",
                          denominacion: "¿Cuál es el nombre de tu primera mascota?",
                        },
                      ]}
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.preguntaSecreta?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="respuesta"
                      label="Respuesta"
                      placeholder="Ingrese su respuesta"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.respuesta?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="perfilCodigo"
                      label="Perfil"
                      type="select"
                      defaultValue={usuario?.perfilCodigo || ''}
                      data={comboPerfil}
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.perfilCodigo?.message}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelRoundedIcon />}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                >
                  CANCELAR
                </Button>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  GUARDAR
                </LoadingButton>
              </DialogActions>
            </form>
          </Loading>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogForm;

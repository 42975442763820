import { useCreateHorarioDetalle } from '../../../../hooks/useHorarioDet'
import FormHorario from './FormHorario'

const CreateHorario = ({ }) => {
    const { data, mutate, isLoading, } = useCreateHorarioDetalle()
    return (
        <>
            <FormHorario
                edit={false}
                mutate={mutate}
                data={null}
                isLoading={isLoading}
            />
        </>
    )
}

export default CreateHorario

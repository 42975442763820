import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, GetAll, getById, Update } from '../services/horarios'

const key = 'horario'

export const useGetAllHorario = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetHorarioById = (idEspecialista, enabled = true) =>
  useQuery([key, idEspecialista], getById, {
    enabled
  })

export const useCreateHorario = () => {
  const queryClient = useQueryClient()

  return useMutation(Create, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useUpdateHorario = (id) => {
  const queryClient = useQueryClient()

  return useMutation((body) => Update(id, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}
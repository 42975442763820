import { Routes, Route } from 'react-router-dom'
import Dashboard from '../../components/layout/dashboard'
import { Especialidades } from './Especialidades'
import { Pacientes } from './Pacientes'
import { Especialistas } from './Especialistas'
import { Citas } from './Citas'
import Inicio from './inicio'
import { adminNavConfig } from './layoutAdmin'
import { Usuarios } from './usuarios'
import { Perfiles } from './perfiles'
import { Accesos } from './menuAccesos'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { Formatos } from './Formatos'
import { FormatoDetalles } from './FormatoDetalles'
import { SolicitarCitaAPaciente } from './SolicitarCitaAPaciente'

const AdminDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard navConfig={adminNavConfig} />}>
        <Route path='/inicio' element={<ProtectedRoute />} >
          <Route index element={<Inicio />} />
        </Route>
        <Route path='especialidades' element={<ProtectedRoute />}>
          <Route index element={<Especialidades />} />
        </Route>
        <Route path='pacientes' element={<ProtectedRoute />}>
          <Route index element={<Pacientes />} />
        </Route>
        <Route path='Especialistas' element={<ProtectedRoute />}>
          <Route index element={<Especialistas />} />
        </Route>
        <Route path='citas' element={<ProtectedRoute />}>
          <Route index element={<Citas />} />
        </Route>
        <Route path='usuarios' element={<ProtectedRoute />}>
          <Route index element={<Usuarios />} />
        </Route>
        <Route path='perfiles' element={<ProtectedRoute />}>
          <Route index element={<Perfiles />} />
        </Route>
        <Route path='accesos' element={<ProtectedRoute />}>
          <Route index element={<Accesos />} />
        </Route>
        <Route path='formatos' element={<ProtectedRoute />}>
          <Route index element={<Formatos />} />
        </Route>
        <Route path='formatodetalles' element={<ProtectedRoute />}>
          <Route index element={<FormatoDetalles />} />
        </Route>
        <Route path='solicitar-cita-paciente' element={<ProtectedRoute />}>
          <Route index element={<SolicitarCitaAPaciente />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AdminDashboard

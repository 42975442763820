import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Create, DeleteElement, GetAll, Update, GetById, GetByIdFormato } from '../services/formatoDetalles'

const key = 'FormatoDetalles'

export const useGetAllFormatoDetalles = (enabled = true) =>
    useQuery(key, GetAll, {
        enabled
    })

export const useCreateFormatoDetalle = () => {
    const queryClient = useQueryClient()

    return useMutation(Create, {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useGetByIdFormatoDetalle = (id, enabled = true) =>
    useQuery([key, id], GetById, {
        enabled
    })

export const useGetAllByFormato = (id, enabled = true) =>
    useQuery([key, id], GetByIdFormato, {
        enabled
    })

export const useUpdateFormatoDetalle = id => {
    const queryClient = useQueryClient()

    return useMutation(body => Update(id, body), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}

export const useDeleteFormatoDetalle = id => {
    const queryClient = useQueryClient()

    return useMutation(() => DeleteElement(id), {
        onSuccess: () => queryClient.invalidateQueries(key)
    })
}
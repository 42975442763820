import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { DatosGenerales } from '../../../components/DatosGenerales'
import ContentMainBox from '../../../components/MainBox'
import { useAlertMessage } from '../../../context/notifications'
import {
  useAtenderPaciente,
  useGetDatosCita
} from '../../../hooks/useAtencionPaciente'
import { Odontograma } from './components/Odontograma'
import { PlanTratamiento } from './components/PlanTratamiendo'
import Store from './components/data'
import { useCreatePdf } from '../../../hooks/useCreatePdf'
import {
  formatBodyOndontograma,
  formatToothData
} from './utils/format-odontograma'
import { LoadingButton } from '@mui/lab'
import { Semaforo } from '../../../components/Semaforo'

const defaultTratamientos = [
  {
    id: 0,
    tratamiento: 'Oburaciones',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 1,
    tratamiento: 'Exodoncias',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 2,
    tratamiento: 'Profilaxis destartraje',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 3,
    tratamiento: 'Cirugias',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 4,
    tratamiento: 'Fluorizaciones',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 5,
    tratamiento: 'Sellantes',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 6,
    tratamiento: 'Detección y eliminación de P.B.',
    cantidad: 0,
    prioridad: 0
  },
  {
    id: 7,
    tratamiento: 'Otros',
    cantidad: 0,
    prioridad: 0
  }
]

const TOOTH_FORMAT = [
  'Pieza_Odontograma',
  'Posicion_Pieza',
  'Estado_Pieza',
  'Abreviación'
]

const EvaluacionOdontologica = () => {
  const navigate = useNavigate()
  const { showSuccess, showError } = useAlertMessage()
  const { mutate, isLoading } = useAtenderPaciente()
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita

  const [showOdontograma, setShowOdontograma] = useState(true)
  const [showPlanTratamiento, setShowPlanTratamiento] = useState(true)

  const [toothData, setToothData] = useState(Store.arcada.adulto)
  const { data } = useGetDatosCita({
    idFormato: dataCita.idFormato,
    idCita: dataCita.id
  })

  const formatos = data?.formato?.camposFormato

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues: {
      tratamientos: defaultTratamientos
    }
  })
  const {
    formState: { errors },
    control,
    reset
  } = methods

  const onSubmit = data => {
    const { tratamientos, riesgoEstomatologico } = data
    let diagnostico = Object.entries(tratamientos).reduce(
      (acc, [key, value]) => {
        const { tratamiento, cantidad, prioridad } = value
        if (!!cantidad && !!prioridad)
          return (
            `${tratamiento}: cantidad ${cantidad}, prioridad ${prioridad}\n` +
            acc
          )
        else return acc
      },
      ' '
    )
    diagnostico += `Riegos Estomatologico: ${riesgoEstomatologico} \n}`

    const consultaDatos = formatBodyOndontograma(toothData, formatos)

    const formData = {
      idCita: dataCita.id,
      semaforo: data.semaforo,
      diagnostico,
      consultaDatos
    }

    const msg = 'No se pudo guardar datos de la atencion'
    const msg2 = 'Datos guardados correctamente'

    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        genPdf(true)
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }
  const { refHtml, genPdf } = useCreatePdf()
  useEffect(() => {
    if (data) {
      const formatData = formatToothData(
        data.formatoAnteriorCita.formatoDetalle
      )
      setToothData(formatData)
    }
  }, [data])
  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Box className='flex items-center'>
            <Typography className='font-bold'>
              Evaluación Odontológica
            </Typography>
          </Box>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <DatosGenerales
                DNI={dataCita.dni}
                CamposPaciente={CamposPaciente}
              />
              <>
                <Divider>
                  <Box className='flex items-center'>
                    <Typography className='font-bold'>Odontograma</Typography>
                    <IconButton
                      onClick={() => setShowOdontograma(!showOdontograma)}
                      size='small'
                    >
                      {showOdontograma ? (
                        <VisibilityRoundedIcon />
                      ) : (
                        <VisibilityOffRoundedIcon />
                      )}
                    </IconButton>
                  </Box>
                </Divider>
                <Collapse in={showOdontograma}>
                  <Grid container spacing={2} ref={refHtml}>
                    <Odontograma data={toothData} setData={setToothData}  paciente={dataCita.paciente} />
                  </Grid>

                  {/* <Grid container spacing={2}>
                    <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        label='Observaciones'
                        size='small'
                        fullWidth
                        multiline
                        rows={3}
                        error={!!errors.observaciones}
                        helperText={!!errors.observaciones ? errors.observaciones.message : null}
                      />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        label='Diagnóstico'
                        size='small'
                        fullWidth
                        multiline
                        rows={3}
                        error={!!errors.diagnostico}
                        helperText={!!errors.diagnostico ? errors.diagnostico.message : null}
                      />
                    </Grid>
                  </Grid> */}
                </Collapse>
              </>
              <>
                <Divider>
                  <Box className='flex items-center'>
                    <Typography className='font-bold'>
                      Plan tratamiento
                    </Typography>
                    <IconButton
                      onClick={() =>
                        setShowPlanTratamiento(!showPlanTratamiento)
                      }
                      size='small'
                    >
                      {showPlanTratamiento ? (
                        <VisibilityRoundedIcon />
                      ) : (
                        <VisibilityOffRoundedIcon />
                      )}
                    </IconButton>
                  </Box>
                </Divider>
                <Collapse in={showPlanTratamiento}>
                  <PlanTratamiento />
                </Collapse>

                <Divider sx={{my: "15px"}}/>
                <Grid container>
                  <Grid item xs={4}>
                    <Semaforo
                      control={control}
                      id='semaforo'
                      label='Semaforo'
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.semaforo?.message}
                    </Typography>
                  </Grid>
                </Grid>
              </>
              <Grid
                item
                container
                justifyContent='space-around'
                marginTop='1rem'
              >
                <Grid item xs={3}>
                  <Button
                    variant='outlined'
                    fullWidth
                    onClick={() => navigate(-1)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <LoadingButton
                    loading={isLoading}
                    type='submit'
                    variant='contained'
                    fullWidth
                  >
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

export default EvaluacionOdontologica
const CamposPaciente = [
  'nombres',
  'apellidos',
  'dni',
  'fechaNacimiento',
  'direccion',
  'celular',
  'tipoPaciente',
  'fechaNacimiento',
  'celular',
  'alergias',
  'celularResponsable',
  'edad',
  'procedencia',
  'carreraProfesional',
  'codigoEstudiante'
]

export const ESTADOS = {
    0: "Pendiente",
    1: "Atendido",
    2: "Derivado",
    3: "No asistio",
}

export const COLOR_ESTADO = {
    0: "default",
    1: "warning",
    2: "success",
    3: "info",
}

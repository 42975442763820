import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useGetCitasByIdPaciente } from '../../../../hooks/useCitas'
import { useGetByIdEspecialista } from '../../../../hooks/useEspecialistas'
import { DataGridStyle } from '../../../../components/DataGridStyle'
import Loading from '../../../../components/Loading';

export const VerCita = ({data,handleClose,open,handleOpen}) => {
    
    
    const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')
  return (
    <>
    <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}
    // id={data.idPaciente}
    >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          {'Cita'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
            }}
          />
        </DialogTitle> 
        <DialogContent>
        
            Aquí se mostrará
          
        </DialogContent>
        <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
    </>
  )
}

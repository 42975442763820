import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import SaveIcon from '@mui/icons-material/Save'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid'
import Input from '../../../../components/Input'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import * as yup from 'yup'
import { useAlertMessage } from '../../../../context/notifications'
import { useGetByIdTriaje, useUpdateTriaje } from '../../../../hooks/useTriaje'
const TriajeSchema = yup.object({
  nroDocPaciente: yup
      .string()
      .required('Campo requerido'),
  presionArterial: yup
      .string()
      .required('Campo requerido'),
  temperatura: yup
      .number()
      .required('Campo requerido'),
  frecuenciaRespiratoria: yup
      .number()
      .required('Campo requerido'),
  frecuenciaCardiaca: yup
      .number()
      .required('Campo requerido'),
  saturacion: yup
      .number()
      .required('Campo requerido'),
  peso: yup
      .number()
      .positive()
      .required('Campo requerido'),
  talla: yup
      .number()
      .positive()
      .required('Campo requerido'),
  

})
export const UpdateTriaje = ({data}) => {
  const { mutate } = useUpdateTriaje()
  const [open, setOpen] = useState(false)
     const { showError, showSuccess } = useAlertMessage()
    const { handleSubmit, reset, control, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(TriajeSchema),
        defaultValues:  {...data}
    })
    const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')
    const onSubmit = data => {
        const formData = {
            ...data,
            presionArterial: data.presionArterial,
            temperatura: data.temperatura,
            frecuenciaRespiratoria: data.frecuenciaRespiratoria,
            nroDocPaciente:data.nroDocPaciente,
            frecuenciaCardiaca: data.frecuenciaCardiaca,
            saturacion: data.saturacion,
            peso: data.peso,
            talla: data.talla,
            imc: (data.peso)/((data.talla/100)**2),
        }
        const msg = 'No se pudo guardar datos del triaje'
        const msg2 = 'Datos guardados correctamente'
        mutate(formData, {
            onError: () => {
                showError(msg)
                reset()
            },
            onSuccess: () => {
                showSuccess(msg2)
                reset()
            }
        })
    }
    const handleOpen = () => {
      setOpen(true)
    }
    const handleClose = () => {
      setOpen(false)
    }
  return (
    <>
    <Tooltip title='Editar Triaje'>
        <span>
          <IconButton aria-label='update' onClick={handleOpen} >
            <EditIcon/>
          </IconButton>
        </span>
      </Tooltip>
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
       <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          {'EDITAR TRIAJE'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
              reset()
            }}
          />
        </DialogTitle> 
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                <Grid container spacing={3} alignContent='center'>
                    <Grid item xs={6}>
                        <Input
                            control={control}
                            id='nroDocPaciente'
                            label='NroDocPaciente'
                            placeholder='Ingrese número de documento paciente '
                            
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.nroDocPaciente?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            control={control}
                            id='presionArterial'
                            label='Presión arterial'
                            placeholder='Ingrese presión arterial'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.presionArterial?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            control={control}
                            id='temperatura'
                            label='Temperatura'
                            placeholder='Ingrese temperatura del paciente'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.temperatura?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            control={control}
                            id='frecuenciaRespiratoria'
                            label='Frecuencia Respiratoria x minuto'
                            placeholder='Ingrese la frecuencia respiratoria'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.frecuenciaRespiratoria?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            control={control}
                            id='frecuenciaCardiaca'
                            label='Frecuencia Cardiaca x minuto'
                            placeholder='Ingrese frecuencia cardiaca'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.frecuenciaCardiaca?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            control={control}
                            id='saturacion'
                            label='Saturación O2'
                            placeholder='Ingrese saturación'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.saturacion?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            control={control}
                            id='peso'
                            label='Peso Kg'
                            placeholder='Ingrese peso del paciente'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.peso?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            control={control}
                            id='talla'
                            label='Talla cm'
                            placeholder='Ingrese talla del paciente'
                            type='number'
                        />
                        <Typography
                            variant='subtitle2'
                            align='left'
                            color='red'
                        >
                            {errors.talla?.message}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                        </Grid>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <LoadingButton
                        loading={false}
                        type='submit'
                        loadingPosition='start'
                        startIcon={<SaveIcon />}
                        variant='contained'
                    >
                        GUARDAR
                    </LoadingButton>
                </DialogActions>
            </form>
    </Dialog>
    </>
  )
}

import dayjs from 'dayjs'

export const formatDatetime = (datetime) => {
  const fechaActual = dayjs();
  const tiempo = dayjs().set('hour', parseInt(datetime.split(':')[0])).set('minute', parseInt(datetime.split(':')[1])).set('second', 0);
  const formatoDeseado = 'YYYY-MM-DDTHH:mm:ss';
  const tiempoFormateado = tiempo.format(formatoDeseado);
  return tiempoFormateado
}

export const formatHour = (datetime) => {
  let fecha = dayjs(datetime)
  if (fecha.isValid()) {
    return fecha.format('HH:mm')
  }
  return ''
}
import { Avatar, Box, Grid, Paper, Typography } from "@mui/material"
import { useContext } from "react"
import { UserContext } from "../../context/userContext"
import { useGetByDniEspecialista } from "../../hooks/useEspecialistas"

const Inicio = () => {
  const { user } = useContext(UserContext)
  const {data} = useGetByDniEspecialista(user.dni)
  const especialista = data?.at(0)
  const persona = especialista?.persona
  return (
    <Box
      className="flex h-auto justify-center align-center"
    >
      <Paper
        className="sm:w-1/2"
        elevation={5}
      >
        <Grid container
          className="xs:flex-column justify-center"
        >
          <Grid item xs="auto" sm={4}>
            <Avatar
              variant="square"
              className="h-full w-52 aspect-square"
              sx={{ borderRadius: '10px',}}
            />
          </Grid>
          <Grid item container xs={12} sm={8}
            className='py-7 flex-nowrap'
          >
            <Grid item xs={6}
              className="ml-5 pr-2 flex flex-col justify-between gap-2"
            >
              <Typography className="font-bold text-right" color="primary">
                NOMBRES
              </Typography>
              <Typography className="font-bold text-right" color="primary">
                DNI
              </Typography>
              <Typography className="font-bold text-right" color="primary">
                CMP
              </Typography>
              <Typography className="font-bold text-right" color="primary">
                ESPECIALIDAD
              </Typography>
            </Grid>
            <Grid item xs={6}
              className="w-32 flex flex-col justify-between"
            >
              <Typography className="overflow-hidden whitespace-nowrap truncate ...">
                {persona?.nombres}
              </Typography>
              <Typography>
                {persona?.dni}
              </Typography>
              <Typography>
                {especialista?.cmp}
              </Typography>
              <Typography className="overflow-hidden whitespace-nowrap truncate">
                {especialista?.especialidad?.desEspecialidad || "NO DEFINIDO"}
              </Typography>
            </Grid>
          </Grid>

        </Grid>
      </Paper>
    </Box>
  )
}

export default Inicio

import { axiosClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const GetAll = async () => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.get (`${fullPath}/bienestar/especialidad`)
  return data
}

export const Create = async body => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.post (`${fullPath}/bienestar/especialidad`, body)

  return data
}

export const GetById = async ({ queryKey }) => {
  const { full_path: fullPath } = usePath()
  const [_key, idEspecialidad] = queryKey
  const { data } = await axiosClient.get (`${fullPath}/bienestar/especialidad/${idEspecialidad}`)

  return data
}

export const Update = async (id, body) => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.put(
    ` ${fullPath}/bienestar/especialidad/${id}`, body
  )
  return data
}

export const DeleteElement = async id => {
  const { full_path: fullPath } = usePath()
  const { data } = await axiosClient.delete(
    ` ${fullPath}/bienestar/especialidad/${id}`
  )
  return data.data
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Schedule } from '@mui/icons-material';
import { useGetByIdTriaje } from '../../../../hooks/useTriaje';

export const VerTriaje = ({data}) => {
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
    <Tooltip title='Ver Triaje'>
        <span>
          <IconButton aria-label='edit' onClick={handleOpen} >
            {/* <ScheduleRoundedIcon /> */}
            {/* <VisibilityIcon/> */}
            <AssignmentIcon/>
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>{'Información Triaje'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <Grid container paddingX={4}>
            <Grid item xs={6}>
              <Typography >
                idTriaje
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                {data.id}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Nro Doc Paciente
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                {data.nroDocPaciente}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Presión Arterial
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                {data.presionArterial}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Temperatura
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.temperatura}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Frecuencia Respiratoria
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.frecuenciaRespiratoria}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Frecuencia Cardiaca
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.frecuenciaCardiaca}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Saturación
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.saturacion}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Peso
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.peso}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                Talla
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.talla}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
                IMC
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography >
              {data.imc}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
            handleClose()
          }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

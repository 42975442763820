import {
  Button,
  Dialog, DialogActions,
  // DialogTitle,
  DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Switch, Tooltip
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useAlertMessage } from '../../../../context/notifications'
import { useUpdateEspecialista } from '../../../../hooks/useEspecialistas'

const UpdateEstado = ({ data }) => {
  const { mutate } = useUpdateEspecialista(data.id)
  const active = data?.estado === 1 ? true : false
  const [checked, setChecked] = useState(active)
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('sm')
  const { showError, showSuccess } = useAlertMessage()

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  }

  const confirmState = () => {
    const formData = {
      ...data,
      estado: active ? 1 : 0
    }
    const msg = 'No se pudo cambiar el estado'
    const msg2 = 'Se cambió el estado correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
      }
    })
    setChecked(!checked)
    handleClose()
  }

  return (
    <>
      <Tooltip title='Editar estado'>
        <FormGroup
          onClick={handleClickOpen}
        >
          <FormControlLabel
            control={
              <Switch
                checked={active ?? checked}
                name='checkedB'
                color='primary'
              />
            }
          />
        </FormGroup>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cambiar estado</DialogTitle>
        <DialogContent >
          <DialogContentText sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <b>¿Está seguro que quiere cambiar estado de {data.nombres}?</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleClose}>
            CANCELAR
          </Button>
          <Button variant='contained' onClick={confirmState}>
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpdateEstado

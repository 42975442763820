import { yupResolver } from "@hookform/resolvers/yup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import { useAlertMessage } from "../../../../context/notifications";
import {
  useGetByIdFormatoDetalle,
} from "../../../../hooks/useFormatoDetalles";
import Loading from "../../../../components/Loading";
import FormatoDetalleSchema from "../schema/formatoDetalles.schema";
import { useGetAllFormatos } from "../../../../hooks/useFormatos";

const DialogForm = ({ edit, id, mutate, isLoading, open, handleClose }) => {
  const { data: FormatoDetalle, isLoading: LoadingFormatoDetalle } = useGetByIdFormatoDetalle(id);
  const { data: formatos } = useGetAllFormatos();
  const comboFormatos = formatos
    ? formatos.map((Formato) => {
      return {
        value: Formato.idFormato,
        denominacion: Formato.nombreFormato,
      };
    })
    : [];


  const { showError, showSuccess } = useAlertMessage();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(FormatoDetalleSchema),
    defaultValues: {
      nombreCampo: FormatoDetalle?.nombreCampo || "",
      tipoValor: FormatoDetalle?.tipoValor || "",
      estado: FormatoDetalle?.estado || 1,
      idFormato: FormatoDetalle?.idFormato || 0,
    },
  });

  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");

  const onSubmit = (data) => {
    let formData = {
      nombreCampo: data.nombreCampo,
      tipoValor: data.tipoValor,
      estado: 1,
      idFormato: data.idFormato,
    };
    const successMessage = edit ? "Formato detalle editado con exito" : "Formato detalle creado con exito";
    const errorMessage = edit ? "No se pudo editar Formato detalle" : "No se pudo crear Formato detalle";

    mutate(formData, {
      onError: () => {
        handleClose();
        showError(errorMessage);
        reset();
      },
      onSuccess: () => {
        handleClose();
        showSuccess(successMessage);
        reset();
      },
    });
  };

  useEffect(() => {
    if (edit)
      reset({
        nombreCampo: FormatoDetalle?.nombreCampo || "",
        tipoValor: FormatoDetalle?.tipoValor || "",
        estado: FormatoDetalle?.estado || 1,
        idFormato: FormatoDetalle?.idFormato || "",
      })
  }, [FormatoDetalle])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{edit ? "EDITAR Formato Detalle" : "AÑADIR Formato Detalle"}</DialogTitle>
        <DialogContent>


          <Grid container >
            <Grid item xs={12} >
              <Divider variant='middle'></Divider>
            </Grid>
          </Grid>
          <Loading isLoading={edit ? LoadingFormatoDetalle : false}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText sx={{ marginBottom: "1em" }}>
                  Información de Formato Detalle
                </DialogContentText>
                <Grid container spacing={2} alignContent="center">
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="nombreCampo"
                      label="nombreCampo"
                      placeholder="Ingrese nombre nombreCampo"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.nombreCampo?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="tipoValor"
                      label="Tipo Valor"
                      placeholder="Ingrese tipo valor"
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.tipoValor?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id="idFormato"
                      label="Formato"
                      type="select"
                      data={comboFormatos}
                    />
                    <Typography variant="subtitle2" align="left" color="red">
                      {errors.idFormato?.message}
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelRoundedIcon />}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                >
                  CANCELAR
                </Button>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  GUARDAR
                </LoadingButton>
              </DialogActions>
            </form>
          </Loading>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogForm;

import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import SickRoundedIcon from '@mui/icons-material/SickRounded';
export function adminNavConfig() {
  return {
    links: [
      {
        path: '.', // Ruta
        name: 'Inicio', // NombreEtiqueta
        icon: HomeRoundedIcon, // IconoAcceso
        toolTip: 'Inicio' // Descripcion
      },
      {
        path: 'especialidades',
        name: 'Especialidades',
        icon: ClassRoundedIcon,
        toolTip: 'Especialidades',
      },
      {
        path: 'especialistas',
        name: 'Especialistas',
        icon: RecentActorsRoundedIcon,
        toolTip: 'Especialistas'
      },
      {
        path: 'pacientes',
        name: 'Pacientes',
        icon: SickRoundedIcon,
        toolTip: 'Pacientes'
      },
      {
        path: 'citas',
        name: 'Citas',
        icon: DateRangeRoundedIcon,
        toolTip: 'Citas'
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        icon: DateRangeRoundedIcon,
        toolTip: 'Usuarios'
      },
      {
        path: 'perfiles',
        name: 'Perfiles',
        icon: DateRangeRoundedIcon,
        toolTip: 'Perfiles'
      },
      {
        path: 'menuaccesos',
        name: 'Accesos',
        icon: DateRangeRoundedIcon,
        toolTip: 'Accesos'
      }
    ]
  }
}

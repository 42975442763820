import React, { useContext, useEffect, useState } from 'react'
import ContentMainBox from '../../../components/MainBox'
import { Grid, Button } from '@mui/material'
import { DataGridStyle } from '../../../components/DataGridStyle'
import { useGetAllCitas, useGetCitas } from '../../../hooks/useCitas'
import { useNavigate } from 'react-router-dom'
import { FormTriaje } from '../AtencionTriaje/components/FormTriaje'
import { useCreateTriaje } from '../../../hooks/useTriaje'
import Loading from '../../../components/Loading'
import { UserContext } from '../../../context/userContext'
import { Filter, ApplyFilters, FilterDates, SearchInput, useFilterByEspecialidad, useFilterByMaxDate, useFilterByMinDate, useFilterByPaciente, FilterButtons, AutoCompletePaciente } from '../../../components/Filters'
// const ESPECIALIDAD = 'odontologia'
// const ESPECIALIDAD = 'modulopsicologia'
// const ESPECIALIDAD = 'modulonutricion'

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'idCita',
    headerName: 'ID Cita',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fecha',
    headerName: 'Fecha',
    type: 'date',
    width: 220,
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'especialidad',
    headerName: 'Especialidad',
    width: 220,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'paciente',
    headerName: 'Paciente',
    width: 300,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'estado',
    headerName: 'Estado',
    width: 150,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell({ row }) {
      return (
        <span>En espera</span>
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    headerName: 'Atender',
    width: 220,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      const navigate = useNavigate()
      // Dirigir a especialidad
      const { mutate } = useCreateTriaje()
      const [open, setOpen] = useState(false)
      const handleOpen = () => {
        setOpen(true)
      }
      const handleClose = () => {
        setOpen(false)
      }
      const { especialidad } = row
      let fichaEspecialidad = ''
      if (especialidad?.toLowerCase() === 'odontología')
        fichaEspecialidad = 'odontologia'
      if (especialidad?.toLowerCase() === 'nutrición')
        fichaEspecialidad = 'modulonutricion'
      if (especialidad?.toLowerCase() === 'psicología')
        fichaEspecialidad = 'modulopsicologia'

      return (
        <div className='space-x-2'>
          {fichaEspecialidad === 'modulonutricion'
            &&
            <>
              <FormTriaje
                mutate={mutate}
                isLoading={false}
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}

              />
              <Button variant="outlined" color="primary" onClick={handleOpen}>
                Triaje
              </Button>
            </>

          }
        </div>

      )
    }
  }
]

export const NuevosTriajes = () => {
  const { user, especialidad: espe } = useContext(UserContext)
  const estado = 0 // Citas pendientes
  const { data: nuevasConsultas, isLoading } = useGetCitas(espe.idEspecialista, estado)
  const comboCitas =
    nuevasConsultas ?
      nuevasConsultas.map((cita, index) => (
        {
          id: index,
          idCita: cita.idCita,
          fecha: cita.fechaCita,
          hora: cita.horaCitaIni,
          especialidad: user.descEspecialidad,
          paciente: cita.nombre,
          estado: cita.estado
        }
      ))
      : []

  const { filterByPaciente, paciente, setPaciente } = useFilterByPaciente()
  const { filterByEspecialidad, especialidad, setEspecialidad, especialidades } = useFilterByEspecialidad()
  const filters = [
    {
      name: 'paciente',
      filter: filterByPaciente,
      filterValue: paciente,
      setFilterValue: setPaciente
    },
    {
      name: 'especialidad',
      filter: filterByEspecialidad,
      filterValue: especialidad,
      setFilterValue: setEspecialidad
    }
  ]
  const clearFilters = () => {
    setPaciente('')
    setEspecialidad('')
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Nuevos Triajes
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <ApplyFilters
                filters={filters}
                data={comboCitas}
                renderItem={() =>
                  <Loading isLoading={isLoading}>
                    <DataGridStyle
                      height={380}
                      rows={comboCitas}
                      columns={columns}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.id}
                    />
                  </Loading>
                }
              >
                <Grid item xs={12} md={10}>
                  <Filter label='Especialidades' data={[{ desEspecialidad: 'medicina general' }, { desEspecialidad: 'odontologia' }, { desEspecialidad: 'nutricion' }]} setValue={setEspecialidad} value={especialidad} filterKey="desEspecialidad" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FilterButtons clearFilters={clearFilters} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <AutoCompletePaciente value={paciente} setValue={setPaciente} />
                </Grid>
              </ApplyFilters>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
import { useCreateFormato } from '../../../../hooks/useFormatos'
import FormFormatos from './FormFormatos'
const CreateFormatos = () => {
    const { mutate, isLoading } = useCreateFormato()
    return (
        <>
            <FormFormatos edit={false} id={''} mutate={mutate} isLoading={isLoading} />
        </>
    )
}
export default CreateFormatos
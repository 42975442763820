import React from 'react'
import ConfirmationModal from './ConfirmationModal'

export const UpdateEstado = ({ data }) => {
  return (
    <ConfirmationModal
      data={data}
      property='estado'
      title='Cambiar estado'
      description={`¿Está seguro que quiere cambiar el estado de ${data.desEspecialidad}?`}
      tooltip='Editar estado'
    />
  )
}

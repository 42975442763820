import { useMutation, useQueryClient } from 'react-query'
import { createTriaje } from '../../services/back-clinica/triaje'

const key = 'triajes'

export const useCreateTriaje = () => {
  const queryClient = useQueryClient()

  return useMutation(createTriaje, {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Input from '../../../../components/Input'
import {
  Box,
  Button,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import ContentMainBox from '../../../../components/MainBox'
import { useContext, useState } from 'react'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'

import { useLocation, useNavigate } from 'react-router-dom'
import {
  useAtenderPaciente,
  useGetDatosCita
} from '../../../../hooks/useAtencionPaciente'
import { EspecialidadContext } from '../../../../context/especialidadContext'
import Loading from '../../../../components/Loading'
import { DatosGenerales } from '../../../../components/DatosGenerales'
import { LoadingButton } from '@mui/lab'
import { useAlertMessage } from '../../../../context/notifications'
import { Semaforo } from '../../../../components/Semaforo'

const CamposPaciente = [
  'nombres',
  'apellidos',
  'fechaNacimiento',
  'direccion',
  'celular',
  'alergias',
  'personaResponsable',
  'edad',
  'procedencia',
  'carreraProfesional',
  'numeroHermanos',
  'accidentesGraves',
  'codigoEstudiante',
  'semestre',
  'email',
  'tutor',
  'viveResidenciaUniv',
  'idioma',
  'religion',
  'LugarOcupaHermano'
]
const schema = yup.object({
  aparienciaFisica: yup.string().required('Campo requerido'),
  conductaVerbal: yup.string().required('Campo requerido'),
  conductaNoVerbal: yup.string().required('Campo requerido'),
  sintomasPrincipales: yup.string().required('Campo requerido'),
  sintomasSecundarios: yup.string().required('Campo requerido'),
  desarrolloPsicomotriz: yup.string().required('Campo requerido'),
  lenguaje: yup.string().required('Campo requerido'),
  controlEsfinteres: yup.string().required('Campo requerido'),
  conductasInadecuadasInfancia: yup.string().required('Campo requerido'),
  estilosCrianza: yup.string().required('Campo requerido'),
  escolaridadInicial: yup.string().required('Campo requerido'),
  escolaridadPrimaria: yup.string().required('Campo requerido'),
  escolaridadSecundaria: yup.string().required('Campo requerido'),
  sugerencias: yup.string().required('Campo requerido'),
  semaforo: yup.string().required('Campo requerido')
})

export const FormHistoriaClinicaPsicologia = () => {
  const { state: dataCita } = useLocation()
  const { mutate, isLoading: isLoadingAtender } = useAtenderPaciente()
  const { data: dataCitaCampos, isLoading } = useGetDatosCita({
    idFormato: dataCita.idFormato,
    idCita: dataCita.id
  })
  const navigate = useNavigate()
  const { showError, showSuccess } = useAlertMessage()
  const { especialidad } = useContext(EspecialidadContext)
  const [showForm2, setShowForm2] = useState(true)
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })
  function getIdItemByNombreCampo(nombreCampo) {
    const CamposFormato = dataCitaCampos?.formato?.formatoDetalle || []
    const campo = CamposFormato.find(campo => campo.nombreCampo === nombreCampo)
    return campo ? campo.idItem : null
  }
  const onSubmit = data => {
    const llaves = Object.keys(data)
    const Lista = llaves
      .map(ojb => {
        const idItem = getIdItemByNombreCampo(ojb)

        if (idItem) {
          return {
            idItem: idItem,
            idSubItem: 0,
            valor: data[`${ojb}`].toString()
          }
        }

        return null
      })
      .filter(Boolean)
    const DatosEnviar = {
      idCita: dataCitaCampos.idCita,
      obsCita: data.sugerencias,
      semaforo: data.semaforo,
      consultaDatos: Lista
    }
    console.log(DatosEnviar)
    const msg = 'No se pudo guardar datos de la atencion o ya fue creada'
    const msg2 = 'Datos guardados correctamente o ya fue creada'
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }
  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <Box className='flex items-center'>
          <IconButton onClick={() => setShowForm2(!showForm2)} size='small'>
            {showForm2 ? (
              <VisibilityRoundedIcon />
            ) : (
              <VisibilityOffRoundedIcon />
            )}
          </IconButton>
          <Typography className='font-bold'>HC Psicológica</Typography>
        </Box>
      </ContentMainBox.Title>
      <Collapse in={showForm2}>
        <ContentMainBox.Content>
          <Loading isLoading={isLoading}>
            {dataCitaCampos && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DatosGenerales
                      DNI={dataCita.dni}
                      triaje={especialidad.requiereTriaje}
                      CamposPaciente={CamposPaciente}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel label={'margin="dense"'} component='legend'>
                      Observación de la Entrevista
                    </FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='aparienciaFisica'
                      label='Apariencia Física'
                      placeholder='Ingrese observación de la conducta física'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.aparienciaFisica?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='conductaVerbal'
                      label='Conducta Verbal'
                      placeholder='Ingrese observación de la conducta verbal'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.conductaVerbal?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='conductaNoVerbal'
                      label='Conducta No Verbal'
                      placeholder='Ingrese observación de la conducta no verbal '
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.conductaNoVerbal?.message}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabel label={'margin="dense"'} component='legend'>
                      Motivo Consulta
                    </FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      control={control}
                      id='sintomasPrincipales'
                      label='Síntomas Principales o dificultades'
                      placeholder='Ingrese observación'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.sintomasPrincipales?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      control={control}
                      id='sintomasSecundarios'
                      label='Síntomas Secundarios'
                      placeholder='Ingrese observación'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.sintomasSecundarios?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel label={'margin="dense"'} component='legend'>
                      Desarrolo Psicomotriz, Lenguaje y control de Esfínteres{' '}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='desarrolloPsicomotriz'
                      label='Desarrollo Psicomotriz'
                      placeholder='Ingrese observación'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.desarrolloPsicomotriz?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='lenguaje'
                      label='Lenguaje'
                      placeholder='Ingrese observación'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.lenguaje?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='controlEsfinteres'
                      label='Control de Esfínteres'
                      placeholder='Ingrese observación '
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.controlEsfinteres?.message}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='conductasInadecuadasInfancia'
                      label='Conductas Inadecuadas en la Infancia'
                      placeholder='Ingrese observación'
                      multiline
                      rows={4}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.conductasInadecuadasInfancia?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='estilosCrianza'
                      label='Estilos de Crianza '
                      placeholder='Ingrese observación'
                      multiline
                      rows={4}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.estilosCrianza?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel label={'margin="dense"'} component='legend'>
                      Escolaridad
                    </FormLabel>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='escolaridadInicial'
                      label='Inicial'
                      placeholder='Ingrese'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.escolaridadInicial?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='escolaridadPrimaria'
                      label='Primaria'
                      placeholder='Ingrese'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.escolaridadPrimaria?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Input
                      control={control}
                      id='escolaridadSecundaria'
                      label='Secundaria'
                      placeholder='Ingrese'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.escolaridadSecundaria?.message}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Input
                      control={control}
                      id='sugerencias'
                      label='Sugerencias u observaciones'
                      placeholder='Deja una sugerencia'
                      multiline
                      rows={3}
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.sugerencias?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Semaforo
                      control={control}
                      id='semaforo'
                      label='Semaforo'
                    />
                    <Typography variant='subtitle2' align='left' color='red'>
                      {errors.semaforo?.message}
                    </Typography>
                  </Grid>

                  <Grid item container justifyContent='space-evenly'>
                    <Grid item xs={5}>
                      <Button fullWidth variant='outlined' onClick={() =>
                        navigate(-1)
                      }>
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item xs={5}>
                      <LoadingButton
                        loading={isLoadingAtender}
                        type='submit'
                        fullWidth
                        variant='contained'
                      >
                        Concluir
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Loading>
        </ContentMainBox.Content>
      </Collapse>
    </ContentMainBox>
  )
}

import { Grid } from '@mui/material'
import React from 'react'
import { DataGridStyle } from '../../../components/DataGridStyle'
import ContentMainBox from '../../../components/MainBox'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'
import CreateEspecialidad from './components/CreateEspecialidad'
import UpdateEspecialidad from './components/UpdateEspecialidad'
import { UpdateRequiereTriaje } from './components/UpdateRequiereTriaje'
import { UpdateEstado } from './components/UpdateEstado'
import Loading from '../../../components/Loading'
import { ApplyFilters, FilterButtons, FilterState, SearchInput, useFilterByEspecialidad, useFilterByState } from '../../../components/Filters'
import { ButtonCreateFormato } from './components/ButtonCreateFormato'

const rows = [
  {
    idEspecialidad: 3,
    desEspecialidad: 'Medicina General',
    abrevEspecialidad: 'MG',
    periodoIteracion: '20',
    estado: 0
  },
  {
    idEspecialidad: 8,
    desEspecialidad: 'Odontología',
    abrevEspecialidad: 'OD',
    periodoIteracion: '30',
    estado: 0
  },
  {
    idEspecialidad: 7,
    desEspecialidad: 'Nutrición',
    abrevEspecialidad: 'NU',
    periodoIteracion: '15',
    estado: 0
  },
  {
    idEspecialidad: 9,
    desEspecialidad: 'Medicina General',
    abrevEspecialidad: 'MG',
    periodoIteracion: '30',
    estado: 0
  },
  {
    idEspecialidad: 2,
    desEspecialidad: 'Odontología',
    abrevEspecialidad: 'OD',
    periodoIteracion: '15',
    estado: 0
  },
  {
    idEspecialidad: 1,
    desEspecialidad: 'Nutrición',
    abrevEspecialidad: 'NU',
    periodoIteracion: '20',
    estado: 0
  },
]

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'desEspecialidad',
    headerName: 'Especialidad',
    width: 350,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'abrevEspecialidad',
    headerName: 'Abreviación',
    width: 200,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'periodoIteracion',
    headerName: 'Periodo de atención',
    align: 'center',
    width: 200,
    renderCell: ({ row }) => {
      return (
        <div>
          {row.periodoIteracion} min
        </div>
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'requiereTriaje',
    headerName: 'Requiere triaje',
    align: 'center',
    width: 120,
    renderCell: ({ row }) => {
      return (
        <UpdateRequiereTriaje data={row} />
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'estado',
    headerName: 'Estado',
    align: 'center',
    width: 100,
    renderCell: ({ row }) => {
      return (
        <UpdateEstado data={row} />
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'accion',
    type: 'actions',
    width: 180,
    headerName: 'Acción',
    cellClassName: 'accion',
    getActions: ({ row }) => {
      return [
        <UpdateEspecialidad id={row.idEspecialidad} />,
        <ButtonCreateFormato especialidad={row} />
      ]
    }
  },
]

export const Especialidades = () => {

  const { filterByEspecialidad, especialidad, setEspecialidad, especialidades } = useFilterByEspecialidad('desEspecialidad')
  const { filterByState, estado, setEstado } = useFilterByState()

  const filters = [
    {
      name: 'especialidad',
      filter: filterByEspecialidad,
      filterValue: especialidad,
      setFilterValue: setEspecialidad
    },
    {
      name: 'estado',
      filter: filterByState,
      filterValue: estado,
      setFilterValue: setEstado
    },
  ]

  const clearFilters = () => {
    setEstado(-1)
    setEspecialidad('')
  }

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
            <Grid item>Especialidades</Grid>
            <Grid item>
              <CreateEspecialidad />
            </Grid>
          </Grid>            
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <ApplyFilters
                data={especialidades}
                filters={filters}
                renderItem={() =>
                  <Loading isLoading={false}>
                    <DataGridStyle
                      height={500}
                      rows={especialidades}
                      columns={columns}
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.idEspecialidad}
                    />
                  </Loading>
                }
              >
                <Grid item xs={12}>
                  <SearchInput
                    data={especialidades}
                    value={especialidad}
                    setValue={setEspecialidad}
                    keyWord='desEspecialidad'
                    labelWord='especialidad'
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <FilterState label="Estado" value={estado} setValue={setEstado} />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FilterButtons clearFilters={clearFilters} />
                </Grid>
              </ApplyFilters>
            </Grid>
          </Grid>

        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

import { yupResolver } from '@hookform/resolvers/yup';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Input from '../../../../components/Input';
import { useAlertMessage } from '../../../../context/notifications';
import { useUpdateHorario } from '../../../../hooks/useHorario';
import { useGetHorarioDetalleById, useUpdateHorarioDetalle } from '../../../../hooks/useHorarioDet';
import { getValidDays } from '../utils/horario';
const EspecialidadSchema = yup.object({
  fecIni: yup
    .date()
    .required('* Campo requerido')
    .max(yup.ref("fecFin"), 'La fecha de inicial debe ser menor a la fecha final')
    .typeError('* Campo requerido'),
  fecFin: yup
    .date()
    .required('* Campo requerido')
    .min(yup.ref("fecIni"), 'La fecha de final debe ser mayor a la fecha inicial')
    .typeError('* Campo requerido')
  // estado: yup
  //   .number(),
})

export const UpdateFecha = ({ defaultData }) => {
  const { mutate } = useUpdateHorario(defaultData.idHorario)
  // const { mutate: mutateHorarioDetalle } = useUpdateHorarioDetalle(defaultData.idHorario)
  // const { data: horario } = useGetHorarioDetalleById(defaultData.idHorario)
  const [open, setOpen] = useState(false)
  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')
  const { showError, showSuccess } = useAlertMessage()

  const { handleSubmit, reset, control, formState: { errors }, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(EspecialidadSchema),
    defaultValues: {
      fecIni: new Date(defaultData.fecIni).toISOString().slice(0, 10),
      fecFin: new Date(defaultData.fecFin).toISOString().slice(0, 10),
    }
  })

  const handleOpen = () => {
    setOpen(true)
    reset()
  }
  const handleClose = () => {
    setOpen(false)
    reset()
  }
  const verificarDias = () => {
    getValidDays()
  }
  const onSubmit = data => {
    const formData = {
      ...defaultData,
      fecIni: new Date(data.fecIni).toISOString().slice(0, 10),
      fecFin: new Date(data.fecFin).toISOString().slice(0, 10),
    }
    const msg = 'No se pudo editar la Fecha'
    const msg2 = 'Fecha editado correctamente'
    // const days = getValidDays(getValues('fecIni'), getValues('fecFin'))
    // const { horarioDetalle_ } = horario[0]
    // let newHorario = horarioDetalle_
    // if (days.lenght < 4) {
    //   newHorario = horarioDetalle_.map((item) => {
    //     if (days.includes(item.dia)) {
    //       return {
    //         ...item,
    //         estado: 1
    //       }
    //     } else {
    //       return {
    //         ...item,
    //         estado: 0
    //       }
    //     }
    //   })
    // }
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        // mutateHorarioDetalle({
        //   ...defaultData,
        //   horarioDetalle: newHorario
        // })
        reset()
      }
    })
  }

  return (
    <>
      <Tooltip title='Editar fecha' >
        <span>
          <IconButton onClick={handleOpen} disabled={defaultData.estado === 0 ? true : false}>
            <CalendarMonthRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}>{'EDITAR FECHA'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
              reset()
            }}
          /></DialogTitle>
        {
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText sx={{ marginBottom: '1em' }}>
                Información básica de la Horario
              </DialogContentText>
              <Grid container spacing={3} alignContent='center'>
                <Grid item xs={12}>
                  <Input
                    control={control}
                    id='fecIni'
                    label='Fecha Inicio'
                    type='date'
                    placeholder='Ingrese Fecha de inicio'
                    value='h'
                  />
                  <Typography
                    variant='subtitle2'
                    align='left'
                    color='red'
                  >
                    {errors.fecIni?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    control={control}
                    id='fecFin'
                    label='Fecha Final'
                    type='date'
                    placeholder='Ingrese Fecha de fin'
                  />
                  <Typography
                    variant='subtitle2'
                    align='left'
                    color='red'
                  >
                    {errors.fecFin?.message}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => {
                handleClose()
                reset()
              }}>
                CANCELAR
              </Button>
              <LoadingButton
                // loading={isLoading}
                type='submit'
                loadingPosition='start'
                startIcon={<SaveIcon />}
                variant='contained'
              >
                GUARDAR
              </LoadingButton>
            </DialogActions>
          </form>
        }
      </Dialog>
    </>
  )
}

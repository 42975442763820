import { Box } from '@mui/material'
import React from 'react'

export const HourComponent = ({ style, onClick, children }) => {
    const handleClick = () => {
        if (onClick) onClick()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 40,
                borderRadius: 1,
                cursor: 'pointer',
                backgroundColor: 'grey.100',
                ...style
            }}
            onClick={handleClick}
        >
            {children}
        </Box>
    )
}
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Input from "../../../../components/Input";
import { Button, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ContentMainBox from "../../../../components/MainBox";

const schema = yup.object({
  nombre: yup
    .string('')
    .required('Campo requerido'),
  genero: yup
    .string('')
    .required('Campo requerido')
})

export const FormRecordatorio = () => {

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()
  const { state: dataCita } = useLocation() // Obteniendo datos de la cita

  console.log(dataCita)

  const onSubmit = data => console.log(data)

  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        Recordatorio 24 horas
      </ContentMainBox.Title>
      <ContentMainBox.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                control={control}
                id='nombre'
                label="Nombre"
                error={!!errors.nombre}
                helperText={!!errors.nombre ? errors.nombre.message : null}
              />
            </Grid>

            <Grid item xs={6}>
              <Input
                control={control}
                label="Genero"
                id='genero'
                error={!!errors.genero}
                helperText={!!errors.genero ? errors.genero.message : null}
              />
            </Grid>

            <Grid item container justifyContent="space-evenly">
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button type='submit' fullWidth variant="contained" >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </form>
      </ContentMainBox.Content>
    </ContentMainBox>
  )
}
import { Box, Button, Divider, FormControl, Grid, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlertMessage } from '../../../../context/notifications'
import { useCreatePdf } from '../../../../hooks/useCreatePdf'
import './odontogram.css'
import Store from './data'

const FacesEstados = {
  'Normal': 'white',
  'Caries': 'red',
  'Ausente': 'black',
  'Obturación': 'blue',
  'Corona': 'green',
  'Resto Radicular': 'yellow'
  // Implante: 'purple',
  // Sarro: 'pink',
  // Restaurado: 'yellow',
  // Endodoncia: 'blue',
}
const colors = {
  'white': {
    fill: 'fill-white',
    bg: 'bg-white'
  },
  'red': {
    fill: 'fill-red-500',
    bg: 'bg-red-500'
  },
  'black': {
    fill: 'fill-zinc-600',
    bg: 'bg-zinc-600'
  },
  'blue': {
    fill: 'fill-blue-500',
    bg: 'bg-blue-500'
  },
  'green': {
    fill: 'fill-green-500',
    bg: 'bg-green-500'
  },
  'yellow': {
    fill: 'fill-yellow-500',
    bg: 'bg-yellow-500'
  }
}

const FacesTratamientos = {
  Ninguno: 'Ninguno',
  Extraccion: 'Extraccion',
  Endodoncia: 'Endodoncia',
  Corona: 'Corona',
  Pulpotomia: 'Pulpotomia',
  Atendiendo: 'Atendiendo',
}

export const Odontograma = ({ data, setData, paciente = 'Desconocido' }) => {
  const { refHtml, DownloadButton, PrintButton } = useCreatePdf()

  // const [data, setData] = useState(Store.arcada.adulto)
  const navigate = useNavigate()
  const { showError, showSuccess } = useAlertMessage()

  const filterToohtsById = (minId, maxId, data) => (
    data?.filter(({ id }) => (minId <= id && id <= maxId))
  )

  const changeEstado = (id, face, estado, observationValue = '') => {
    const newData = data.map((tooth) => {
      if (tooth.id === id) {
        tooth.faces[face].estado = estado
        tooth.faces[face].color = FacesEstados[estado]
        return {
          ...tooth,
          observation: observationValue
        }
      }
      return tooth
    })
    setData(newData)
  }

  const changeAnnotation = (id, annotationValue) => {
    const newData = data.map((tooth) => {
      if (tooth.id === id) {
        return {
          ...tooth,
          annotation: annotationValue
        }
      }
      return tooth
    })
    setData(newData)
  }

  useEffect(() => {
    const odontograma = Store.arcada.adulto.map(tooth => {
      const faces = tooth.faces.map(face => {
        return {
          ...face,
          estado: 'Normal',
          color: 'white'
        }
      })

      return {
        ...tooth,
        faces
      }
    })

    setData(odontograma)
  }, [])

  return (
    <>
      <Grid item xs={12} >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <DownloadButton />
          <PrintButton />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <div ref={refHtml}>
          <p className='my-2'><b>Paciente:</b> {paciente}</p>
          <div className="odontograma">
            <div className="face-up face-name">Vestibular</div>
            <div className="container">
              <div className="face-left face-name vertical-orientation">Distal</div>
              <div className="subcontainer">
                <div className="subcontainer-left">
                  <div className="subcontainer-left-up tooths-container">
                    {
                      filterToohtsById(11, 18, data)?.map(({ id, faces, annotation }) => (
                        <ToothComponent
                          key={id}
                          id={id}
                          faces={faces}
                          changeEstado={changeEstado}
                          changeAnnotation={changeAnnotation}
                          defaultAnnotation={annotation}
                        />
                      ))
                    }
                  </div>
                  <div className="face-middle-left face-name">
                    <Divider>Lingual</Divider>
                  </div>
                  <div className="subcontainer-left-bottom tooths-container">
                    {
                      filterToohtsById(41, 48, data)?.map(({ id, faces, annotation }) => (
                        <ToothComponent
                          key={id}
                          id={id}
                          faces={faces}
                          changeEstado={changeEstado}
                          changeAnnotation={changeAnnotation}
                          defaultAnnotation={annotation}
                        />
                      ))
                    }
                  </div>
                </div>
                <div className="face-middle face-name vertical-orientation">
                  <Divider>Mesial</Divider>
                </div>
                <div className="subcontainer-right">
                  <div className="subcontainer-right-up tooths-container">
                    {
                      filterToohtsById(21, 28, data)?.map(({ id, faces, annotation }) => (
                        <ToothComponent
                          key={id}
                          id={id}
                          faces={faces}
                          changeEstado={changeEstado}
                          changeAnnotation={changeAnnotation}
                          defaultAnnotation={annotation}
                        />
                      ))
                    }
                  </div>
                  <div className="face-middle-right face-name">
                    <Divider>Lingual</Divider>
                  </div>
                  <div className="subcontainer-right-bottom tooths-container">
                    {
                      filterToohtsById(31, 38, data)?.map(({ id, faces, annotation }) => (
                        <ToothComponent
                          key={id}
                          id={id}
                          faces={faces}
                          changeEstado={changeEstado}
                          changeAnnotation={changeAnnotation}
                          defaultAnnotation={annotation}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="face-right face-name vertical-orientation ">Distal</div>
            </div>
            <div className="face-down face-name">Vestibular</div>
          </div>
          <div> {/*Colores*/}
            {
              Object.keys(FacesEstados).map((key, i) => {
                const color = FacesEstados[key]
                const bgColor = colors[color].bg
                return <div key={i} className='flex items-center gap-2'>
                  <div className={`w-4 h-4 border rounded ${bgColor}`}></div>
                  <Typography variant='body2'>{key}</Typography>
                </div>
              })
            }
          </div>
        </div>
        <div>
          {/* <Grid container spacing={10} justifyContent="center" rowSpacing={2} sx={{ mt: 1 }}>
            <Grid item xs={5} >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/especialista/nuevasconsultas")}>
                Cancelar
              </Button>
            </Grid>

            <Grid item xs={5} >
              <Button
                // type='submit' 
                fullWidth
                variant="contained"
                onClick={onConcluirCita}>
                Concluir
              </Button>
            </Grid>

          </Grid> */}
        </div>
      </Grid>
    </>
  )
}



const ToothComponent = ({ id, faces, changeEstado, changeAnnotation, defaultAnnotation = '' }) => {
  const [open, setOpen] = useState(false)
  const [face, setFace] = useState(0)
  const [annotation, setAnnotation] = useState('')

  const handleChange = (e) => {
    changeAnnotation(id, e.target.value)
  }
  const handleOpen = (id) => {
    setFace({ ...faces[id], idx: id })
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const fillFace0 = colors[faces[0].color].fill
  const fillFace1 = colors[faces[1].color].fill
  const fillFace2 = colors[faces[2].color].fill
  const fillFace3 = colors[faces[3].color].fill
  const fillFace4 = colors[faces[4].color].fill

  return (
    <div className={`w-12 text-center`}>
      <input defaultValue={defaultAnnotation} onChange={handleChange} className='w-full h-10 border rounded text-center' />
      <small className=''>{id}</small>
      <div className='relative'>
        <svg width="50" height="50" id={1}>
          <polygon
            className={`cursor-pointer fill-white stroke-black hover:fill-zinc-300 ${fillFace0}`}
            strokeWidth={2}
            points="2,1 13,13 36,13 48,1"
            // fill={faces[0].color}
            onClick={() => handleOpen(0)}
          />
          <polygon
            className={`cursor-pointer fill-white stroke-black hover:fill-zinc-300 ${fillFace1}`}
            strokeWidth={2}
            points="36,13 36,36 49,49 49,0"
            // fill={faces[1].color}
            onClick={() => handleOpen(1)}
          />
          <polygon
            className={`cursor-pointer fill-white stroke-black hover:fill-zinc-300 ${fillFace2}`}
            strokeWidth={2}
            points="36,36 49,49 2,49 13,36 "
            // fill={faces[2].color}
            onClick={() => handleOpen(2)}
          />
          <polygon
            className={`cursor-pointer fill-white stroke-black hover:fill-zinc-300 ${fillFace3}`}
            strokeWidth={2}
            points="1,1 1,49 13,36 13,13"
            // fill={faces[3].color}
            onClick={() => handleOpen(3)}
          />
          <polygon
            className={`cursor-pointer fill-white stroke-black hover:fill-zinc-300 ${fillFace4}`}
            strokeWidth={2}
            points="13,13 36,13 36,36 13,36"
            // fill={faces[4].color}
            onClick={() => handleOpen(4)}
          />
        </svg>
        {/* <RingsComponent /> */}
      </div>
      <OdontoModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        face={face}
        id={id}
        changeEstado={changeEstado}
      />
    </div>
  )
}

const OdontoModal = ({ open, handleOpen, handleClose, face, id, changeEstado }) => {
  const [estado, setEstado] = useState(face.estado)
  const [observation, setObservation] = useState(face?.observation)
  const style = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 1,
    boxShadow: 50,
    pt: 2,
    px: 4,
    pb: 3,
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setEstado(value)
  }

  const onSubmit = () => {
    const generalStateTooth = ["Ausente", "Corona", "Resto Radicular"]

    if (generalStateTooth.includes(face.estado)) {
      for (let faceidx = 0; faceidx < 5; faceidx++) {
        changeEstado(id, faceidx, 'Normal')
      }
      changeEstado(id, face.idx, estado)
      handleClose()
      return
    }

    if (generalStateTooth.includes(estado)) {
      for (let faceidx = 0; faceidx < 5; faceidx++) {
        changeEstado(id, faceidx, estado)
      }
      handleClose()
      return
    }

    changeEstado(id, face.idx, estado)
    handleClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`Diente ${id} - ${face?.name}`}
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <label>Estado</label>
            <Select
              defaultValue={face?.estado}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  <span>{selected}</span>
                </Box>
              )}
            >
              {
                Object.keys(FacesEstados).map((key, i) => {
                  return (
                    <MenuItem key={i} value={key} style={{
                      display: 'flex',
                      gap: 10,
                    }}>
                      <span>{key}</span>
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
          {/* <FormControl sx={{ m: 1, width: 300 }}>
            <label>Tratamiento</label>
            <Select
              // defaultValue={face?.procedimiento}
              defaultValue=''
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  <span>{selected}</span>
                </Box>
              )}
            >
              {
                Object.keys(FacesTratamientos).map((key, i) => {
                  return (
                    <MenuItem key={i} value={key} style={{
                      display: 'flex',
                      gap: 10,
                    }}>
                      <span>{key}</span>
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl> */}
          <FormControl sx={{ m: 1, width: 300 }}>
            <label>Observaciones</label>
            <TextField
              placeholder='Observaciones'
              multiline
              rows={4}
              defaultValue={observation}
              onChange={(e) => setObservation(e.target.value)}
            />
          </FormControl>

          <FormControl sx={{ display: 'flex', gap: 1, m: 1, width: 300 }}>
            <Button variant='contained' onClick={onSubmit}>Guardar</Button>
            <Button onClick={handleClose}>Cerrar</Button>
          </FormControl>
        </Box>
      </Modal>
    </>
  )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useGetCitasByIdPaciente } from '../../../../hooks/useCitas'
import { useGetByIdEspecialista } from '../../../../hooks/useEspecialistas'
import { DataGridStyle } from '../../../../components/DataGridStyle'
import Loading from '../../../../components/Loading';
import GetAppIcon from '@mui/icons-material/GetApp';
const columns = [
    {
      headerClassName: 'super-app-theme--header',
      field: 'fecha',
      headerName: 'Fecha',
      type: 'string',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'especialidad',
      headerName: 'Especialidad',
      width: 300,
      // renderCell: ({row}) => {
      //   const { data: especialidad } = useGetByIdEspecialista(row.especialidad); 
      //   const NombreEspecialidad= especialidad? especialidad[0].descEspecialidad:""
      //   return (
      //     <>
      //     {NombreEspecialidad}
      //     </>
          
      //   )
      // },
      headerAlign: 'center',
      align: 'center'
    },
    {
      headerClassName: 'super-app-theme--header',
      field: 'descargar',
      headerName: 'Descargar',
      width: 200,
      renderCell: ()=> {
        const [open, setOpen] = useState(false)
        const handleOpen = () => {
          setOpen(true)
        }
        const handleClose = () => {
          setOpen(false)
        }
        return (
          <>
          
          <Tooltip title='descargar'>
            <IconButton
              aria-label='descargar'
              onClick={console.log('va a descargar')}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          </>
          
        )
      },
      headerAlign: 'center',
      align: 'center'
    }
  ]
  const rows = [
    {
      idCita:1,
      fecha: 'viernes 17/02/2023',
      especialidad: 'Choque Bueno Fiorella Silvia',
      especialista: 'Ing. Informática',
      
    },
    {
      idCita:2,
      fecha: 'viernes 17/02/2023',
      especialidad: 'Choque Bueno Fiorella Silvia',
      especialista: 'Ing. Informática',
      
    }
  ]
export const VerCitas = ({data,handleClose,open,handleOpen}) => {
  
    const { data: citas,isLoading} = useGetCitasByIdPaciente(data.idPaciente);
    // console.log(data)
    const comboCitas =
    citas ?
        citas.map((cita,index) => {
        return { fecha: new Date(cita.fechaCita).toLocaleString(undefined, {year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long"}), especialidad:cita.idEspecialista,especialista: cita.idEspecialista, idCita:index }
      }) : []
    const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')
  return (
    <>
    <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    open={open}
    onClose={handleClose}
    id={data.idPaciente}
    >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          {'Historial Citas Pasadas'}
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
            }}
          />
        </DialogTitle> 
        <DialogContent>
        <Grid container spacing={2}>
            <Grid item className='mt-4' xs={12}>
              <Loading isLoading={false}>
                <DataGridStyle
                  height={380}
                  rows={rows}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.idCita}
                />
              </Loading>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
    </>
  )
}
